
import React from 'react';
import DepartmentStateRightTop from './departmentStateRightTop/departmentStateRightTop';
import ByitemRightBottom from './departmentStateRightBottom/departmentStateRightBottom';
const DepartmentStateRight = () => (
    <div className='departmentStateRight'>
        <DepartmentStateRightTop />
        <ByitemRightBottom />
    </div>
)
export default DepartmentStateRight
