import React, { useContext } from 'react';
import { of } from 'rxjs';
import { Link } from 'react-router-dom';
import { Tree, Spin, Icon } from 'antd';
import * as r from 'ramda';
import { context, loadDepartmentState } from '../departmentState';

const { TreeNode } = Tree;

const DepartmentStateTree = () => {
    const { state: { tree, node, }, dispatch } = useContext(context);
    const onSelect = (node) => (event) => (nodeId) => {
        return (r.isEmpty(event) || r.head(event) === node) ? (state) => of(state) :
            (state) => loadDepartmentState({ ...state, loading: true, node: r.head(event), nodeId })
    }
    const toChild = r.map(
        ({ title, key, children, id }) => (<TreeNode title={title} key={key} id={id}>{r.isEmpty(children) ? '' : toChild(children)}</TreeNode>)
    );

    return (
        <div className='departmentStateLeft'>
            <div className='departmentStateLeftTreeHead'>
                <span>部门分析</span>
                <div className='departmentStateLeftTreeHeadButtom'>
                    <Link to='/departmentStateAddNode'>
                        编辑部门 <Icon type="plus" />
                    </Link>
                </div>
            </div>
            <div className="departmentStateLeftTreeBottom">
                {
                    r.isEmpty(tree) ?
                        <Spin />
                        :
                        <Tree
                            showLine
                            defaultExpandedKeys={['0']}
                            defaultSelectedKeys={['0']}
                            onSelect={(selectedKeys, e) => {
                                dispatch(state => of(r.assoc('loading', true)(state)))
                                dispatch(onSelect(node)(selectedKeys)(e.node.props.id))
                            }}
                        >
                            <TreeNode title={tree[0].title} key={tree[0].key}>
                                {toChild(tree[0].children)}
                            </TreeNode>
                        </Tree>
                }
            </div>
        </div>
    )
}


export default DepartmentStateTree
