import * as r from 'ramda'
// 对应各种类型
const typeMapping = {
  ELECTRICITYMETER: [
    'ELECTRICITYMETER',
    'MELECTRICITYMETER',
    'NBELECTRICITYMETER'],
  WATER: [
    'COLDWATERMETER',
    'HOTWATERMETER',
    'DDWATERMETER',
    'ULTRACOLDWATERMETER',
    'NBCOLDWATERMETER'],
  GAS: [
    'GASMETER',
  ],
  AC: [
    'TIMERMETER',
    'HEATENERGYMETER',
    'TEMPERATURECONTROL',
    'TTYPETEMPCONTROL',
    'ZTYPETEMPCONTROL',
    'ENERGYMETER',
  ],
    PV: [
        'PHOTOVOLTAIC',
    ],
}

export const typesInCategory = category => r.propOr([])(category)(typeMapping)

//'00101', //电表 正向有功
//   '00215', //水表 冷水刻度
//   '00316', //热水表 热水刻度
//   '00409', //热能表 总冷量
//   '00410', //热能表 总热量
//   '00508', //温控器 能量系数
//   '00612', //时间采集器 冷量系数
//   '00613', //时间采集器 热量系数
//   '00811', //超声波水表 累计流量
//   '01009', //能量表 总冷量
//   '01010', //能量表 总热量
//   '01212', //氧气表 氧气
//   '01301', //多功能电表 正向有功
//   '01611', //燃气表 累积流量

export const channelsMap = {
  '00101': "正向有功",
  '00215': "冷水刻度",
  '00316': "热水刻度",
  '00409': "总冷量",
  '00410': "总热量",
  '00508': "能量系数",
  '00612': "冷量系数",
  '00613': "热量系数",
  '00811': "累计流量",
  '01009': "总冷量",
  '01010': "总热量",
  '01212': "氧气",
  '01301': "正向有功",
  '01611': "累计流量",
  '01701':"NB冷水刻度" //NB水表 冷水刻度
    // '01751', //NB水表 电池电压
    // '01752', //NB水表 信号值
    // '01753', //NB水表 累积工作时间
}
