import React from 'react';
import {Subject} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';


const withStates = (
  WrappedComponent,
  initialState,
  setUpAction
) => {
  const context = React.createContext({
    state: initialState,
    dispatch: () => {}
  })
  class Component extends React.Component {
    constructor(props) {
      super(props)
      this.state = initialState
      this.actions$ = new Subject()
      const setState = this.setState.bind(this)
      this.subscription = this.actions$
        .pipe(
          tap(next => console.debug('Action:', next)),
          tap(_ => console.debug('CurrentState:', this.state)),
          switchMap(next => next(this.state, props)),
          tap(next => {
            console.log('setting', next)
            setState(next)
          })
        )
        .subscribe(
          next => {
            console.debug('Reduced:', next)
          },
          error => console.error('ERROR: ', error)
        )
    }

    componentDidMount() {
      if (setUpAction) {
        this.actions$.next(setUpAction)
      }
    }

    componentWillUnmount() {
      this.subscription.unsubscribe();
    }

    render() {
      return (
        <context.Provider
          value={{
            state: this.state,
            dispatch: this.actions$.next.bind(this.actions$)
          }}
        >
          <WrappedComponent {...this.state} />
        </context.Provider>
      );
    }
  }
  return {context, Component}
}

export default withStates