import React, { useContext, useState } from 'react';
import { context, loadDepartmentState } from '../../departmentState'
import { Table, Button, Icon, message, Modal, Tooltip } from 'antd';
import { of } from 'rxjs';
import Api from 'api';
import Delete from '../../departmentStateDelete/departmentStateDelete';
import ByitemModal from '../../departmentStateModal/departmentStateModal';
import EditableCell, { EditableFormRow } from 'src/components/table/tableEdit/editbleCell';
import {handleAreaChange, handleHeadCountChange, handleTitleChange} from "src/context/formula";
import {ROOT_NODE} from "src/context/treeView";

const components = {
    body: {
        row: EditableFormRow,
        cell: EditableCell,
    },
};

const { confirm } = Modal;
const columnsA = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: '90px'
    },
    {
        title: '设备名称',
        dataIndex: 'title',
        key: 'title',
        width: '270px',
        render: (text) => {
            return text.length > 16 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
                width: '200px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip > : <span>{text}</span>
        }
    },
    {
        title: '设备ID',
        dataIndex: 'id',
        key: 'id',
        width: '240px'
    },
    {
        title: '设备类型',
        dataIndex: 'type',
        key: 'type',
        width: '200px'
    },
    {
        title: '能耗类型',
        dataIndex: 'category',
        key: 'category',
        width: '200px'
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
            return <div><Delete record={record.information} /></div>
        }
    }, {
        title: '信息',
        dataIndex: 'information',
        key: 'information',
        className: 'hide'
    }
];

const columnsB = dispatch => [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: '90px'
    },
    {
        title: '设备名称',
        dataIndex: 'title',
        key: 'title',
        width: '270px',
        render: (text) => {
            return text.length > 16 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
                width: '200px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip > : <span>{text}</span>
        },
        onCell: record => ({
            record,
            editable: true,
            dataIndex: 'title',
            title: '设备名称',
            handleSave: handleTitleChange(dispatch, loadDepartmentState),
        }),
    },
    {
        title: '面积',
        dataIndex: 'area',
        key: 'area',
        width: '270px',
        onCell: record => ({
            record,
            editable: true,
            dataIndex: 'area',
            title: '面积',
            handleSave: handleAreaChange(dispatch, loadDepartmentState),
        }),
    }, {
        title: '人数',
        dataIndex: 'person',
        key: 'person',
        width: '270px',
        onCell: record => ({
            record,
            editable: true,
            dataIndex: 'person',
            title: '人数',
            handleSave: handleHeadCountChange(dispatch, loadDepartmentState),
        }),
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
            return <div><Delete record={record.information} /></div>
        }
    }, {
        title: '信息',
        dataIndex: 'information',
        key: 'information',
        className: 'hide'
    }
];

const DepartmentStateRightBottom = () => {
    const { state: { dataSource, loading, selectTableType, node }, dispatch } = useContext(context)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows)
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const allDelect = () => {
        confirm({
            title: '您确定要删除该仪表吗！',
            onOk() {
                return (
                    selectedRows.forEach((k, i) => {
                        Api.postV3(`project/${Api.projectId}/node/${k.removeid}`).then(res => {
                            res.data.message === '' ? message.info('删除成功') : message.info(res.data.message)
                            setSelectedRows([])
                            setSelectedRowKeys([])
                            dispatch(state => of({ ...state, loading: true }))
                            return dispatch(state => loadDepartmentState({ ...state, loading: true }))
                        })
                    })

                )
            },
            onCancel() { },
        });


    }
    const selectNode = () => {
        dispatch(state => of({ ...state, loading: true, selectTableType: 'node' }))
        return dispatch(state => loadDepartmentState({ ...state, loading: true, selectTableType: 'node' }))
    }
    const selectDevice = () => {
        dispatch(state => of({ ...state, loading: true, selectTableType: 'device' }))
        return dispatch(state => loadDepartmentState({ ...state, loading: true, selectTableType: 'device' }))
    }


    return (
        <div className="departmentStateRightBottom">
            <div className='departmentStateRightBottomHead'>
                <div className="departmentStateRightBottomHeadLeft">
                    { node === ROOT_NODE ? '' : <ByitemModal />}
                    <Button onClick={selectNode}>面积/人数</Button>
                    <Button onClick={selectDevice}>设备/仪表</Button>
                    {selectedRowKeys.length > 0 ? <Button type="danger" onClick={allDelect}><Icon type="delete" />删除</Button> : ''}
                </div>
                <div className="departmentStateRightBottomHeadRight">
                    {dataSource.length} 个结果
                </div>
            </div>
            <Table
                dataSource={dataSource}
                components={components}
                columns={selectTableType === 'device' ? columnsA : columnsB(dispatch)}
                // pagination={false}
                scroll={{ y: 600 }}
                // rowSelection={rowSelection}
                loading={loading}
                childrenColumnName={''}
            />
        </div>

    )
}

export default DepartmentStateRightBottom