import React from 'react';

// import './index.scss'
// import ReactEcharts from 'echarts-for-react'

// import IndexTop from './indexTop/indexTop';
// import IndexMid from './indexMid/indexMid'
// import IndexBottom from './indexBottom/indexBottom'

class System extends React.Component {

  render() {
    return (
      <div className="system">
        System
      </div>
    )
  }

};


export default System;