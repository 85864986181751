import React from 'react';
import * as r from 'ramda';
import { from } from 'rxjs';
import './shops.scss';
import withStates from 'src/context/withStates';
import Api from 'api';
import ShopsTree from './shopsTree/shopsTree';
import ShopsRight from './shopsRight/shopsRight';
import { extractDevices, extractNodes, searchFilter } from "src/context/society";
import { deviceTypeFormat } from "src/components/typeCast/typeCast";

const ROOT_NODE = '0';

const Shops = () => (
    <div className="Shops">
        <ShopsTree />
        <ShopsRight />
    </div>
)

const ShopsStates = async ({ apiStatus }) => {
    const { data: { result: { detail } } } = await Api.post('/department/info', {
        pageindex: 1,
        pagesize: 10,
        project: Api.projectId,
        status: apiStatus
    });
    return {
        loading: false,
        tree: [
            {
                title: Api.projectName,
                key: ROOT_NODE,
                children: []//formatTree(ROOT_NODE)(data),
            },
        ],
        dataSource: [{
            index: '01',
            title: '商铺A-10086',
            account: 'test10000',
            usertitle: '商户10000',
            sensors: [{
                id: '043000101493',
                title: '插座A',
                type: deviceTypeFormat('COLDWATERMETER').substring(0, 2),
                openOrclose: true,
            }, {
                id: '043000101493',
                title: '插座B',
                type: deviceTypeFormat('COLDWATERMETER').substring(0, 2),
                openOrclose: false,
            }],
            mobile: '13888888888',
            cash: '20',
            alerthreshold: '50',
            remindercount: '1',
        }],// searchFilter(dataSource)([search]),
    }
}

export const loadShopsState = (state) => {
    return from(ShopsStates(state)
        .then(res => ({ ...state, ...res })))
}

const initState = {
    loading: true,
    apiStatus: 0,
    dataSource: [],
    tree: [
        {
            title: Api.projectName,
            key: ROOT_NODE,
            children: []
        },
    ],
}

export const { context, Component } = withStates(
    Shops,
    initState,
    loadShopsState,
)

export default Component
