import React from 'react';
import {Button, Checkbox, Col, DatePicker, Form, Input, message, Radio, Row, Select} from 'antd';
import {Link} from "react-router-dom";
import * as r from 'ramda';
import moment from 'moment';
import './newReport.scss'
import Api from 'api';

import Docxtemplater from'docxtemplater';
import ImageModule  from'docxtemplater-image-module-free';
import PizZip from'pizzip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';
import wenzhoushiDoc from './wenzhoushi_projects.docx';
import Util2  from 'src/util/util2';
import {
    formItemLayout,
    groupFormItemLayout, joinAllModules,
    projectDefaultModules,
    projectModules,
    projectSource,
    projectType,
    rightFormItemLayout,
    rightRadioFormItemLayout,
    tailFormItemLayout
} from "src/context/project";

const {Option} = Select;
const {RangePicker} = DatePicker
const regionJsonConverter = Util2.regionJsonConverter;
// console.log(regionJsonConverter['330301']);
class NewReportFrom extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
    };



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            console.log(err)
            console.log(values)

            if (!err) {
                console.log('Received values of form: ', values);
                const {name: title, website: description, from: source,
                    state: sector, account: user, pw: passwd, phone: mobile,region:region,
                    longitude2:longitude2,latitude2:latitude2,building:building,regionBelong,
                    category: type, time, timeRange, web, admin, analysis=[], extra=[]} = values;
                let regionName = regionJsonConverter[region];
                let regionBelongName = regionJsonConverter[regionBelong];
                let effectiveDays = time;
                if (time === "自定义") {
                    effectiveDays = timeRange[1].diff(timeRange[0], 'days')
                }
                const modules = joinAllModules(web, admin, analysis, extra)

                const res = await Api.post('/project/add', {
                    title,
                    description,
                    sector,
                    source,
                    user,
                    passwd,
                    mobile,
                    region,
                    regionName,
                    regionBelong,
                    regionBelongName,
                    longitude2,
                    latitude2,
                    building,
                    type,
                    effectiveDays,
                    modules
                })
                console.log(res.data.message)
                const msg = res.data.message === '' ? '创建成功' : res.data.message;
                message.info(msg);
                if (msg === '创建成功') {
                    this.props.history.push('/Report')
                }
            }
        });
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    }

    handleWebsiteChange = (value) => {
        let autoCompleteResult;
        if (!value) {
            autoCompleteResult = [];
        } else {
            autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
        }
        this.setState({autoCompleteResult});
    }
    // 导出文档
    exportWord = async ()=>{

        //获取项目的信息
        console.log('1');
        const {data:allProjectInfo1} = await Api.get(`/api/v3/allProjectInfo1`);
        console.log('allProjectInfo1 -------------- ');
        console.log(allProjectInfo1);


        var _this = this;

        var dataJson2 = {
            // table: _this.tableData,
            allProjectInfo:_this.state.allProjectInfo,
            project_name:Api.projectName,
            compile_date:moment().format('YYYY年MM月DD日'),
            building_type:allProjectInfo1[Api.projectId].sector,
            locate:allProjectInfo1[Api.projectId].locate,
            headCount:allProjectInfo1[Api.projectId].headCount?allProjectInfo1[Api.projectId].headCount:1,
            totalArea:allProjectInfo1[Api.projectId].totalArea?allProjectInfo1[Api.projectId].totalArea:1,
            floors:0,

            //-- test
            first_name: 'John',
            last_name: 'Doe',
            phone: '0652455478',
            description: 'New Website',
            // type_builds:_this.state.echart_type.getDataURL(), // 获取echarts图片
            // region_builds:_this.state.echart_region.getDataURL(), // 获取echarts图片
            // energy_map:_this.state.mychart.getDataURL(), // 获取echarts图片
            clients: [
                {
                    "first_name": "John",
                    "last_name": "Doe",
                    "phone": "+44546546454"
                },
                {
                    "first_name": "Jane",
                    "last_name": "Doe",
                    "phone": "+445476454"
                }
            ],
            // tb:tb,
            rawXml:'<w:p><w:pPr><w:rPr><w:color w:val="FF0000"/></w:rPr></w:pPr><w:r><w:rPr><w:color w:val="FF0000"/></w:rPr><w:t>My custom</w:t></w:r><w:r><w:rPr><w:color w:val="00FF00"/></w:rPr><w:t>XML</w:t></w:r></w:p>',
            // loop:true,
            "loop": [
                {inner:'one'},
                {inner:'two'},
                {inner:'three'},
            ]
        };
        console.log(dataJson2);
        return;


        console.log('click the export word button : ------ ');
        // 点击导出word
        function replaceErrors(key, value) {
            if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function(error, key) {
                    error[key] = value[key];
                    return error;
                }, {});
            }
            return value;
        }

        function errorHandler(error) {
            console.log(JSON.stringify({error: error}, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
                const errorMessages = error.properties.errors.map(function (error) {
                    return error.properties.explanation;
                }).join("\n");
                console.log('errorMessages', errorMessages);
                // errorMessages is a humanly readable message looking like this :
                // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
        }

// image start ---
        var opts = {}
        opts.centered = false; //Set to true to always center images
        opts.fileType = "docx"; //Or pptx


// 导出echarts图片，格式转换
        const base64DataURLToArrayBuffer =(dataURL) => {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        }

//Pass your image loader
// opts.getImage = function(tagValue, tagName) {
//     //tagValue is 'examples/image.png'
//     //tagName is 'image'
//     return fs.readFileSync(tagValue);
// }
        opts.getImage = function(chartId){
            return base64DataURLToArrayBuffer(chartId);
        }


//Pass the function that return image size
        opts.getSize = function(img, tagValue, tagName,widX,widY) {
            //img is the image returned by opts.getImage()
            //tagValue is 'examples/image.png'
            //tagName is 'image'
            //tip: you can use node module 'image-size' here
            return [widX?widX:150, widY?widY:150];
        }

        var imageModule = new ImageModule(opts);

// image end -----




//Load the docx file as a binary
//         var content = fs
//             .readFileSync('src/reportTemplates/wenzhoushi_projects.docx', 'binary');
        //Load the docx file as a binary
        // var content =fs
        //     .readFile(path.resolve(__dirname, 'wenzhoushi_projectsdocx'), 'binary');
        // var content = JSZipUtils.getBinaryContent("../../../../static/docTemplate/exportTemplate.docx",function(error, content) {
        //
        // })
        // var content = Fse.read(path.resolve(__dirname, 'tag-example.docx'), 'binary');
        JSZipUtils.getBinaryContent(wenzhoushiDoc,function(error, content) {
            // exportTemplate.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
            // 抛出异常
            if (error) {
                throw error;
            }
            // 图片处理
            let opts = {}
            opts.centered = true;  // 图片居中，在word模板中定义方式为{%%image}
            opts.fileType = "docx";
            opts.getImage = function(chartId){
                return base64DataURLToArrayBuffer(chartId);
            }
            opts.getSize = function(){
                return [200,200]
            }
            let imageModule = new ImageModule(opts);
            // 创建一个PizZip实例，内容为模板的内容
            let zip = new PizZip(content);
            // 创建并加载docxtemplater实例对象
            let doc = new Docxtemplater();
            doc.attachModule(imageModule);
            doc.loadZip(zip);

            // 设置模板变量的值
            var i = 1;
            // var tb = r.map(y=>{return {'nm':y.name,'tp':y.type,'ar':y.area,'cn':y.count,
            //     'm1':y.month_1,'m2':y.month_2,'m3':y.month_3,'m4':y.month_4,'m5':y.month_5,
            //     'm6':y.month_6,'m7':y.month_7,'m8':y.month_8,'m9':y.month_9,'m10':y.month_10,
            //     'm11':y.month_11,'m12':y.month_12,}})(storeData);
            console.log('tb');
            // console.log(tb);
            var dataJson = {
                // table: _this.tableData,
                project_name:Api.projectName,
                first_name: 'John',
                last_name: 'Doe',
                phone: '0652455478',
                description: 'New Website',
                // type_builds:_this.state.echart_type.getDataURL(), // 获取echarts图片
                // region_builds:_this.state.echart_region.getDataURL(), // 获取echarts图片
                // energy_map:_this.state.mychart.getDataURL(), // 获取echarts图片
                clients: [
                    {
                        "first_name": "John",
                        "last_name": "Doe",
                        "phone": "+44546546454"
                    },
                    {
                        "first_name": "Jane",
                        "last_name": "Doe",
                        "phone": "+445476454"
                    }
                ],
                // tb:tb,
                rawXml:'<w:p><w:pPr><w:rPr><w:color w:val="FF0000"/></w:rPr></w:pPr><w:r><w:rPr><w:color w:val="FF0000"/></w:rPr><w:t>My custom</w:t></w:r><w:r><w:rPr><w:color w:val="00FF00"/></w:rPr><w:t>XML</w:t></w:r></w:p>',
                // loop:true,
                "loop": [
                    {inner:'one'},
                    {inner:'two'},
                    {inner:'three'},
                ]
            };
            console.log(dataJson);
            doc.setData(dataJson);

            try {
                // 用模板变量的值替换所有模板变量
                doc.render();
            } catch (error) {
                // 抛出异常
                let e = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    properties: error.properties
                };
                throw error;
            }
            // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
            let out = doc.getZip().generate({
                type: "blob",
                mimeType:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            // 将目标文件对象保存为目标类型的文件，并命名
            saveAs(out, "exportDocx.docx");
        });


    }

    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '86',
        })(
            <Select style={{width: 70}}>
                <Option value="86">+86123123</Option>
                <Option value="87">+87</Option>
            </Select>
        );
        const disabledDate = (current) => {
            return current && current < moment().subtract(1, 'days');
        }
        console.log('modules', r.toPairs(r.propOr({})('web')(projectModules)))


        const informWay = [
            {contactName:'',contactWay:''},
            {contactName:'',contactWay:''},
        ];
        return (
            <div className="newReport">
                <button type="button" onClick={this.exportWord}>导出word文档</button>

                <Form onSubmit={this.handleSubmit} className='newReportForm'>
                    <Row>
                        <Col span={10}>
                            <div className="">
                                <h4>创建单体报告</h4>
                                <br/><h3> ---------- 1.2 项目名称 ---------- </h3><br/>
                                <Form.Item {...formItemLayout}
                                           label="project_name"
                                >
                                    {/*{getFieldDecorator('name', {*/}
                                        {/*rules: [{*/}
                                            {/*required: true, message: '项目名称不能为空',*/}
                                        {/*}],*/}
                                    {/*})(*/}
                                        {/*<Input placeholder="请输入项目名称"/>*/}
                                    {/*)}*/}
                                    <Input placeholder="项目名称"/>
                                </Form.Item>
                                <br/><h3> ---------- 1.3 项目性质 ---------- </h3><br/>
                                <Form.Item {...formItemLayout}
                                           label="building_type"
                                >
                                    {getFieldDecorator('state', {
                                        rules: [{
                                            required: true, message: '项目属性不能为空',
                                        }],
                                    })(
                                        <Select placeholder="请选择项目属性">
                                            {
                                                r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(projectType))
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                                <br/><h3> ---------- 1.4 地理位置 ---------- </h3><br/>
                                <Form.Item {...formItemLayout}
                                           label="location"
                                >
                                    <Input placeholder="地理位置"/>
                                </Form.Item>
                                <br/><h3> ---------- 1.2 项目规模及使用情况 ---------- </h3><br/>
                                <br/><h4> ---------- 表 规模及使用人数 ---------- </h4><br/>
                                <Form.Item {...formItemLayout}
                                           label="head_count"
                                >
                                    <Input placeholder="使用人数"/>
                                </Form.Item>

                                <Form.Item {...formItemLayout}
                                           label="total_area"
                                >
                                    <Input placeholder="占地面积"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="floors"
                                >
                                    <Input placeholder="总层数/栋数"/>
                                </Form.Item>
                                <br/><h4> ---------- 表 建筑功能分区 ---------- </h4><br/>




                                {/*<Form.Item >*/}
                                    {/*<div className="informWay" >*/}
                                        {/*{ informWay && informWay.map((element, index) => (*/}
                                            {/*<span key={index}>*/}
                                      {/*<Form.Item>*/}
                                        {/*{getFieldDecorator(`man${index}`, {*/}
                                            {/*initialValue: element.name,*/}
                                            {/*rules: [{*/}
                                                {/*required: true,*/}
                                                {/*message: '请输入联系人称呼!',*/}
                                            {/*}],*/}
                                        {/*})(*/}
                                            {/*<Input placeholder="请输入联系人称呼" />,*/}
                                        {/*)}*/}
                                      {/*</Form.Item>*/}
                                      {/*<Form.Item>*/}
                                        {/*{getFieldDecorator(`link${index}`, {*/}
                                            {/*initialValue: element.link,*/}
                                            {/*rules: [{*/}
                                                {/*required: true,*/}
                                                {/*message: '请输入联系方式!',*/}
                                            {/*}],*/}
                                        {/*})(*/}
                                            {/*<Input placeholder="请输入联系方式" />,*/}
                                        {/*)}*/}
                                      {/*</Form.Item>*/}
                                    {/*{index < 2 && <Button shape="circle" size="small" icon="plus" type="primary" onClick={() => onLinkWay(index, 'add')} />}*/}
                                    {/*{index > 0 && <Button shape="circle" size="small" icon="minus" type="default" onClick={() => onLinkWay(index, 'minus')} />}*/}
                                    {/*</span>*/}
                                                            {/*))}*/}
                                    {/*</div>*/}
                                {/*</Form.Item>*/}
                                <Form.Item {...formItemLayout}
                                           label="func_locate"
                                >
                                    <Input placeholder="楼层(楼栋)"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="func_name"
                                >
                                    <Input placeholder="公司(功能区)"/>
                                </Form.Item>
                                <br/><h3> ---------- 2.1 能耗监测种类 ---------- </h3><br/>
                                <Form.Item {...formItemLayout}
                                           label="energy_types"
                                >
                                    <Input placeholder="电能类型"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="water_types"
                                >
                                    <Input placeholder="水能类型"/>
                                </Form.Item>

                                <br/><h3> ---------- 2.2 能耗监测设备 ---------- </h3><br/>
                                <Form.Item {...formItemLayout}
                                           label="dev_summary"
                                >
                                    <Input placeholder="能耗监测设备总"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dev_ele"
                                >
                                    <Input placeholder="设备_电"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dev_water"
                                >
                                    <Input placeholder="设备_水"/>
                                </Form.Item>

                                <br/><h4> ---------- 表 能耗监测设备 ---------- </h4><br/>
                                <Form.Item {...formItemLayout}
                                           label="dev_tp"
                                >
                                    <Input placeholder="设备能耗类型"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dev_name"
                                >
                                    <Input placeholder="设备名称"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dev_model"
                                >
                                    <Input placeholder="型号"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dev_num"
                                >
                                    <Input placeholder="数量"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dev_nodes"
                                >
                                    <Input placeholder="设备节点"/>
                                </Form.Item>

                                {/*<br/> 3.2 设备状态<br/>*/}
                                <br/><h3> ---------- 3.2 设备状态 ---------- </h3><br/>

                                <Form.Item {...formItemLayout}
                                           label="dev_states"
                                >

                                    <Input placeholder="设备状态"/>
                                </Form.Item>

                                <br/><h4> ---------- 表3-1 电表设备 ---------- </h4><br/>
                                <Form.Item {...formItemLayout}
                                           label="ele_ord"
                                >
                                    <Input placeholder="电表id"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ele_id"
                                >
                                    <Input placeholder="仪表ID"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="branch"
                                >
                                    <Input placeholder="回路名称"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="func"
                                >
                                    <Input placeholder="所属功能区"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ele_type"
                                >
                                    <Input placeholder="分项能耗类型"/>
                                </Form.Item>

                                <Form.Item {...formItemLayout}
                                           label="ele_state"
                                >
                                    <Input placeholder="状态"/>
                                </Form.Item>

                            <br/><h4> ---------- 表3-2 水表设备 ---------- </h4><br/>
                                <Form.Item {...formItemLayout}
                                           label="wat_ord"
                                >
                                    <Input placeholder="电表id"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="wat_id"
                                >
                                    <Input placeholder="仪表ID"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="wat_model"
                                >
                                    <Input placeholder="设备类型"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="wat_name"
                                >
                                    <Input placeholder="设备名称"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="wat_state"
                                >
                                    <Input placeholder="状态"/>
                                </Form.Item>

                            <br/><h4> ---------- 表3-3 采集器设备 ---------- </h4><br/>
                                <Form.Item {...formItemLayout}
                                           label="coll_ord"
                                >
                                    <Input placeholder="序号"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="coll_id"
                                >
                                    <Input placeholder="建筑标识"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="coll_model"
                                >
                                    <Input placeholder="设备类型"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="coll_IP"
                                >
                                    <Input placeholder="IP地址"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="coll_state"
                                >
                                    <Input placeholder="状态"/>
                                </Form.Item>

                            <br/><h3> ---------- 3.3 用电量及能耗 ---------- </h3><br/>

                            <br/><h4> ---------- 表3-4 month月耗能数据统计 ---------- </h4><br/>
                                <Form.Item {...formItemLayout}
                                           label="sta_ord"
                                >
                                    <Input placeholder="序号"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="sta_devName"
                                >
                                    <Input placeholder="设备名称"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="sta_devId"
                                >
                                    <Input placeholder="设备ID"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="sta_devType"
                                >
                                    <Input placeholder="设备类型"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="sta_s"
                                >
                                    <Input placeholder="起始刻度"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="sta_e"
                                >
                                    <Input placeholder="最终刻度"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="sta_all"
                                >
                                    <Input placeholder="能耗总值"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="sta_fee"
                                >
                                    <Input placeholder="费用"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="sta_ele"
                                >
                                    <Input placeholder="电耗总值"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="sta_water"
                                >
                                    <Input placeholder="水耗总值"/>
                                </Form.Item>



                                <br/><h3> ---------- 3.4 各功能区能耗及排名 ---------- </h3><br/>
                                {/* sta_ele 上面已有*/}
                                <br/><h4> ---------- 表格 各功能区用电总能耗 ---------- </h4><br/>

                                <Form.Item {...formItemLayout}
                                           label="head_count"
                                >
                                    <Input placeholder="总人数"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="total_area"
                                >
                                    <Input placeholder="总面积"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="unit_head"
                                >
                                    <Input placeholder="人均"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="unit_area"
                                >
                                    <Input placeholder="单位面积"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="fc_name"
                                >
                                    <Input placeholder="所属功能区"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="func_branch"
                                >
                                    <Input placeholder="回路名称"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="func_ele"
                                >
                                    <Input placeholder="总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="func_head"
                                >
                                    <Input placeholder="人数"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="func_avg"
                                >
                                    <Input placeholder="人均能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="func_area"
                                >
                                    <Input placeholder="面积"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="func_avg2"
                                >
                                    <Input placeholder="单位面积能耗"/>
                                </Form.Item>

                                <br/><h4> ---------- 表格 各功能区 照明插座 ---------- </h4><br/>

                                <Form.Item {...formItemLayout}
                                           label="lt_fc"
                                >
                                    <Input placeholder="所属功能区"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="lt_br"
                                >
                                    <Input placeholder="回路名称"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="lt_ele"
                                >
                                    <Input placeholder="总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="lt_p"
                                >
                                    <Input placeholder="人数"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="lt_ag"
                                >
                                    <Input placeholder="人均能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="lt_ar"
                                >
                                    <Input placeholder="面积"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="lt_ag2"
                                >
                                    <Input placeholder="单位面积能耗"/>
                                </Form.Item>
                                <br/><h4> ---------- 表格 各功能区  空调用电 ---------- </h4><br/>

                                <Form.Item {...formItemLayout}
                                           label="kt_fc"
                                >
                                    <Input placeholder="所属功能区"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="kt_br"
                                >
                                    <Input placeholder="回路名称"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="kt_ele"
                                >
                                    <Input placeholder="总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="kt_p"
                                >
                                    <Input placeholder="人数"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="kt_ag"
                                >
                                    <Input placeholder="人均能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="kt_ar"
                                >
                                    <Input placeholder="面积"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="kt_ag2"
                                >
                                    <Input placeholder="单位面积能耗"/>
                                </Form.Item>
                                <br/><h4> ---------- 表格 各功能区   动力用电 ---------- </h4><br/>

                                <Form.Item {...formItemLayout}
                                           label="dl_fc"
                                >
                                    <Input placeholder="所属功能区"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dl_br"
                                >
                                    <Input placeholder="回路名称"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dl_ele"
                                >
                                    <Input placeholder="总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dl_p"
                                >
                                    <Input placeholder="人数"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dl_ag"
                                >
                                    <Input placeholder="人均能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dl_ar"
                                >
                                    <Input placeholder="面积"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dl_ag2"
                                >
                                    <Input placeholder="单位面积能耗"/>
                                </Form.Item>

                                <br/><h4> ---------- 表格 各功能区   特殊用电 ---------- </h4><br/>

                                <Form.Item {...formItemLayout}
                                           label="ts_fc"
                                >
                                    <Input placeholder="所属功能区"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ts_br"
                                >
                                    <Input placeholder="回路名称"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ts_ele"
                                >
                                    <Input placeholder="总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ts_p"
                                >
                                    <Input placeholder="人数"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ts_ag"
                                >
                                    <Input placeholder="人均能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ts_ar"
                                >
                                    <Input placeholder="面积"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ts_ag2"
                                >
                                    <Input placeholder="单位面积能耗"/>
                                </Form.Item>

                                <br/><h3> ---------- 3.5 能耗统计 ---------- </h3><br/>

                                <br/><h4> ---------- 总能耗 分析  ---------- </h4><br/>

                                <Form.Item {...formItemLayout}
                                           label="ag_nm"
                                >
                                    <Input placeholder="平均值排名"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ag_p"
                                >
                                    <Input placeholder="人均总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ag_ar"
                                >
                                    <Input placeholder="单位面积总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ag_ov_ag"
                                >
                                    <Input placeholder="超出平均值"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ag_ov_2"
                                >
                                    <Input placeholder="超出第二名"/>
                                </Form.Item>
                                <br/><h4> ---------- 照明插座 分析  ---------- </h4><br/>
                                <Form.Item {...formItemLayout}
                                           label="zm_ag_nm"
                                >
                                    <Input placeholder="平均值排名"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="zm_ag_p"
                                >
                                    <Input placeholder="人均总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="zm_ag_ar"
                                >
                                    <Input placeholder="单位面积总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="zm_ag_ov_ag"
                                >
                                    <Input placeholder="超出平均值"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="zm_ag_ov_2"
                                >
                                    <Input placeholder="超出第二名"/>
                                </Form.Item>
                                <br/><h4> ---------- 空调用电 分析  ---------- </h4><br/>
                                <Form.Item {...formItemLayout}
                                           label="kt_ag_nm"
                                >
                                    <Input placeholder="平均值排名"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="kt_ag_p"
                                >
                                    <Input placeholder="人均总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="kt_ag_ar"
                                >
                                    <Input placeholder="单位面积总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="kt_ag_ov_ag"
                                >
                                    <Input placeholder="超出平均值"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="kt_ag_ov_2"
                                >
                                    <Input placeholder="超出第二名"/>
                                </Form.Item>
                                <br/><h4> ---------- 动力用电 分析  ---------- </h4><br/>
                                <Form.Item {...formItemLayout}
                                           label="dl_ag_nm"
                                >
                                    <Input placeholder="平均值排名"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dl_ag_p"
                                >
                                    <Input placeholder="人均总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dl_ag_ar"
                                >
                                    <Input placeholder="单位面积总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dl_ag_ov_ag"
                                >
                                    <Input placeholder="超出平均值"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="dl_ag_ov_2"
                                >
                                    <Input placeholder="超出第二名"/>
                                </Form.Item>
                                <br/><h4> ---------- 特殊用电 分析  ---------- </h4><br/>
                                <Form.Item {...formItemLayout}
                                           label="ts_ag_nm"
                                >
                                    <Input placeholder="平均值排名"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ts_ag_p"
                                >
                                    <Input placeholder="人均总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ts_ag_ar"
                                >
                                    <Input placeholder="单位面积总能耗"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ts_ag_ov_ag"
                                >
                                    <Input placeholder="超出平均值"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="ts_ag_ov_2"
                                >
                                    <Input placeholder="超出第二名"/>
                                </Form.Item>


                            </div>
                        </Col>
                        <Col span={14}>
                            <div className="">
                                <h4>模块管理</h4>
                                <Form.Item {...rightRadioFormItemLayout}
                                           label="有效日期"
                                >
                                    {getFieldDecorator('time', {
                                        initialValue: 31,
                                    })(
                                        <Radio.Group>
                                            <Radio value={31}>一个月</Radio>
                                            <Radio value={90}>三个月</Radio>
                                            <Radio value={36500}>永久</Radio>
                                            <Radio value="自定义">自定义</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item {...rightRadioFormItemLayout}
                                >
                                    {getFieldDecorator('timeRange', {
                                        initialValue: [moment(), getFieldValue('time') === '自定义' ? moment() : moment().add(getFieldValue('time'), 'day')],
                                        // rules: [{ type: 'array', required: true, message: '请选择时间!' }]
                                    })(
                                        <RangePicker
                                            disabled={getFieldValue('time') !== '自定义'}
                                            disabledDate={disabledDate}
                                            format={'YYYY-MM-DD'}
                                            // value={() => console.log(getFieldValue('time'))}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item {...rightRadioFormItemLayout}
                                           label="项目类别"
                                >
                                    {getFieldDecorator('category', {
                                        initialValue: "BASIC",
                                    })(
                                        <Radio.Group>
                                            <Radio value="BASIC">计量基础版</Radio>
                                            <Radio value="ANALYSIS">能源计量分析版本</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item {...rightFormItemLayout}
                                           label="网页前台模块："
                                >
                                    {getFieldDecorator('web', {
                                        initialValue: r.map(([v, k]) => (
                                            v
                                        ))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'web'])(projectDefaultModules)))
                                    })(
                                        <Checkbox.Group style={{width: "100%"}}>
                                            <Row>
                                                {
                                                    r.map(([v, k]) => (
                                                        <Col {...groupFormItemLayout} key={v}>
                                                            <Checkbox value={v}>{k}</Checkbox>
                                                        </Col>
                                                    ))(r.toPairs(r.propOr({})('web')(projectModules)))
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                                <Form.Item {...rightFormItemLayout}
                                           label="综合分析二级："
                                >
                                    {getFieldDecorator('analysis', {
                                        initialValue: r.map(([v, k]) => (
                                            v
                                        ))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'analysis'])(projectDefaultModules)))
                                    })(
                                        <Checkbox.Group style={{width: "100%"}}>
                                            <Row>
                                                {
                                                    r.map(([v, k]) => (
                                                        <Col {...groupFormItemLayout} key={v}>
                                                            <Checkbox disabled={r.includes('analysis', getFieldValue('web')) ? null : 'disabled'} value={v}>{k}</Checkbox>
                                                        </Col>
                                                    ))(r.toPairs(r.propOr({})('analysis')(projectModules)))
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>

                                <Form.Item {...rightFormItemLayout}
                                           label="网页后台模块："
                                >
                                    {getFieldDecorator('admin', {
                                        initialValue: r.map(([v, k]) => (
                                            v
                                        ))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'admin'])(projectDefaultModules)))
                                    })(
                                        <Checkbox.Group style={{width: "100%"}}>
                                            <Row>
                                                {
                                                    r.map(([v, k]) => (
                                                        <Col {...groupFormItemLayout} key={v}>
                                                            <Checkbox value={v}>{k}</Checkbox>
                                                        </Col>
                                                    ))(r.toPairs(r.propOr({})('admin')(projectModules)))
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>

                                <Form.Item {...rightFormItemLayout}
                                           label="定制功能："
                                >
                                    {getFieldDecorator('extra', {
                                        initialValue: r.map(([v, k]) => (
                                            v
                                        ))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'extra'])(projectDefaultModules)))
                                    })(
                                        <Checkbox.Group style={{width: "100%"}}>
                                            <Row>
                                                {
                                                    r.map(([v, k]) => (
                                                        <Col {...groupFormItemLayout} key={v}>
                                                            <Checkbox value={v}>{k}</Checkbox>
                                                        </Col>
                                                    ))(r.toPairs(r.propOr({})('extra')(projectModules)))
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>

                            </div>
                        </Col>
                    </Row>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" className='newReportFormButton'>确认</Button>
                        <Button className='newReportFormButton'><Link to='/Report'>取消</Link></Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const NewReport = Form.create({name: 'registerReport'})(NewReportFrom);


export default NewReport;
