import React, { useContext, useState } from 'react';
import {
    Form, Input, message, Icon, Modal,
} from 'antd';
import { of } from "rxjs";
import { context, loadTableData } from '../building';
import Api from 'api';
import './billingEdit.scss';


const BillingEdit = (props) => {
    const { dispatch } = useContext(context);
    const { detail } = props.record;
    const [visible, setVisible] = useState(false);
    const [forRef, setForRef] = useState({ from: '' });
    const showModal = () => {
        setVisible(true)
    };

    const handleCancel = () => {
        setVisible(false)
    };

    const handleCreate = () => {
        const { form } = forRef.props;
        form.validateFieldsAndScroll(async (err, values) => {
            console.log(values)
            if (!err) {
                const { acreage,
                    headCount,
                    description,
                    id,
                    title, } = values;

                Api.post('/building/update', {
                    acreage,
                    headCount,
                    description,
                    bid: detail.bid,
                    id,
                    title,
                    project: Api.projectId,
                }).then(res => {
                    const msg = res.data.message === '' ? '修改成功' : res.data.message;
                    message.info(msg);
                    if (msg === '修改成功') {
                        setVisible(false)
                        return dispatch((state) => loadTableData({ ...state, loading: true }))
                    }
                })


            }
        });

    };

    const saveFormRef = formRef => {
        setForRef(formRef)
    };

    return (
        <div style={{ float: 'left' }}>
            <span onClick={showModal} style={{ color: '#3E8FF8', marginRight: '10px' }}>
                编辑
            </span>
            <BillingEditFromContent
                wrappedComponentRef={saveFormRef}
                detail={detail}
                visible={visible}
                onCancel={handleCancel}
                onCreate={handleCreate}
            />
        </div>
    )
}


class BillingEditFrom extends React.Component {

    render() {
        const { visible, onCancel, onCreate, form, detail } = this.props;
        const { getFieldDecorator } = form;
        const { id, title, acreage, headCount, description } = detail
        const formItemLayout = {
            labelCol: {
                span: 5,
            },
            wrapperCol: {
                span: 12,
            },
        };


        return (
            <Modal
                title="编辑建筑"
                visible={visible}
                onCancel={onCancel}
                onOk={onCreate}
                destroyOnClose
            >
                <Form className='newProjectForm'>
                    <Form.Item {...formItemLayout}
                        label="建筑编号"
                    >
                        {getFieldDecorator('id', {
                            initialValue: id,
                            rules: [{
                                required: true, message: '建筑编号不能为空',
                            }],
                        })(
                            <Input placeholder="请输入建筑编号" disabled />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="建筑名称"
                    >
                        {getFieldDecorator('title', {
                            initialValue: title,
                            rules: [{
                                required: true, message: '建筑名称不能为空',
                            }],
                        })(
                            <Input placeholder="请输入建筑名称" />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="建筑面积"
                    >
                        {getFieldDecorator('acreage', {
                            initialValue: acreage,
                            rules: [{
                                required: true, message: '建筑面积不能为空',
                            }],
                        })(
                            <Input placeholder="请输入建筑面积" type='number' />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="建筑人数"
                    >
                        {getFieldDecorator('headCount', {
                            initialValue: headCount,
                            rules: [{
                                required: true, message: '人数不能为空',
                            }],
                        })(
                            <Input placeholder="请输入人数" type='number' />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="信息描述"
                    >
                        {getFieldDecorator('description', {
                            initialValue: description
                        })(
                            <Input.TextArea />
                        )}
                    </Form.Item>

                </Form>
            </Modal>
        );
    }
}
const BillingEditFromContent = Form.create({ name: 'register' })(BillingEditFrom);



export default BillingEdit;