import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { of } from 'rxjs';
import { context, loadNodeUsage } from '../byitem';

import Api from 'src/api';

const confirm = Modal.confirm;

const Delete = (props) => {
    const { dispatch } = useContext(context);
    const { removeid, did } = props.record

    const showConfirm = () => {

        confirm({
            title: '您确定要删除该仪表吗！',
            onOk() {
                return (
                    Api.delete(`/project/${Api.projectId}/node/${removeid}`).then(res => {
                        res.data.message ? message.info(res.data.message) : message.info('删除成功')
                        dispatch(state => of({ ...state, loading: true }))
                        return dispatch(state => loadNodeUsage({ ...state, loading: true }))
                    }))
            },
            onCancel() { },
        });
    }
    return (
        <span onClick={showConfirm} style={{ color: '#FF0927', cursor: 'pointer' }}>
            删除
    </span>
    );
}


export default Delete;