import React , { useState, useEffect } from 'react';
import {Checkbox, Col, DatePicker, Form, Radio, Row,Select,Input,Button, Upload} from 'antd';
import * as r from 'ramda';
import moment from 'moment';
import './newReport.scss'
import {
	groupFormItemLayout,
	projectDefaultModules,
	projectModules,
	rightFormItemLayout,
	updateProjectFormLayout
} from "src/context/project";
import {formItemLayout} from "../../../context/project";
import Api from "../../../api";
import {message} from "antd/lib/index";
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';


import Docxtemplater from'docxtemplater';
import ImageModule  from'docxtemplater-image-module-free';
import PizZip from'pizzip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';
import wenzhoushiDoc from './wenzhoushi_projects.docx';
import energyReport_single from './wznhjc_report0813.docx';


const lg=(x)=>console.log(x);
const {RangePicker} = DatePicker
export const projectType = {
    SPORT: '体育建筑',
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}
const {Option} = Select;
const filterModulesByType = type => r.filter(r.flip(r.includes)(r.keys(r.propOr({}, type, projectModules))))

var ehartsNodes = [];
const dateArr = [moment().subtract(1,'month').startOf('month').format('YYYYMMDD'),
    moment().subtract(1,'month').endOf('month').format('YYYYMMDD')];
//全局数据，给后面echarts引用
const eleType = [ 'lightrcptcl','powerele','airele','specialele'];
const rankTypeValueLink={'rankAll':'value',
    'rankUnitHeadE':'unitHeadE',
    'rankUnitAreaE':'unitAreaE',
}
const optionName = {
    'rankAll':'总能耗',
    'rankUnitHeadE':'人均能耗',
    'rankUnitAreaE':'单位面积能耗',
    'unitHeadE':'人均能耗',
    'unitAreaE':'单位面积能耗',
    'lightrcptcl':'照明插座',
    'powerele':'动力用电',
    'airele':'空调用电',
    'specialele':'特殊用电'
}
const resEleTypeValue = {},resEleTypeTj={},analyzeEleType={},analyzeAll={};// 1.排名 2.分类数据整理成一层数据给doc
var dataJson2 ;
var nodesEcharts,
    funcAll,funcAllUnitHeadE,funcAllUnitAreaE,
    funcLightAll,funcLightUnitHeadE,funcLightUnitAreaE,
    funcAirAll,funcAirUnitHeadE,funcAirUnitAreaE,
    funcPowerAll,funcPowerUnitHeadE,funcPowerUnitAreaE,
    funcSpecialAll,funcSpecialUnitHeadE,funcSpecialUnitAreaE;

const UpdateReportForm = props => {
	const {getFieldDecorator, getFieldValue} = props.form;
	const {project} = props;
    const [init, setInit] = useState( false);
    const [nodes, setNodes] = useState([{name:'nodes'}]);
    const [style1, setStyle1] = useState({ width: "400px", height: "260px" });
    // const [style2, setStyle2] = useState({ width: "400px", height: "260px" ,marginLeft:'50px'});
    const [style2, setStyle2] = useState({ width: "400px", height: "460px" ,marginLeft:'50px'});
    const [style3, setStyle3] = useState({ width: "400px", height: "460px" ,marginLeft:'50px'});
    const [widSpan, setWidSpan] = useState(5);
    const [informWay, setInformWay] = useState( (project.funcLocateDomain && project.funcLocateDomain.length)>0?project.funcLocateDomain:[
        {funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''},//功能区位置 功能区
    ]);

    useEffect(()=>{
        console.log('useEffect run --- ');
        nodesEcharts = echarts.init(document.getElementById('nodesEcharts'));
        funcAll = echarts.init(document.getElementById('funcAll'));
        funcAllUnitHeadE = echarts.init(document.getElementById('funcAllUnitHeadE'));
        funcAllUnitAreaE = echarts.init(document.getElementById('funcAllUnitAreaE'));
        funcLightAll = echarts.init(document.getElementById('funcLightAll'));
        funcLightUnitHeadE = echarts.init(document.getElementById('funcLightUnitHeadE'));
        funcLightUnitAreaE = echarts.init(document.getElementById('funcLightUnitAreaE'));
        funcAirAll = echarts.init(document.getElementById('funcAirAll'));
        funcAirUnitHeadE = echarts.init(document.getElementById('funcAirUnitHeadE'));
        funcAirUnitAreaE = echarts.init(document.getElementById('funcAirUnitAreaE'));
        funcPowerAll = echarts.init(document.getElementById('funcPowerAll'));
        funcPowerUnitHeadE = echarts.init(document.getElementById('funcPowerUnitHeadE'));
        funcPowerUnitAreaE = echarts.init(document.getElementById('funcPowerUnitAreaE'));
        funcSpecialAll = echarts.init(document.getElementById('funcSpecialAll'));
        funcSpecialUnitHeadE = echarts.init(document.getElementById('funcSpecialUnitHeadE'));
        funcSpecialUnitAreaE = echarts.init(document.getElementById('funcSpecialUnitAreaE'));
    })
    // reactEcharts = echarts.init(document.getElementById('ReactEcharts'));

	if(project.funcLocate&&project.funcDomain&&project.funcHeads&&project.funcArea&&!init){
        console.log('init locate --- ');
        var funcLocateDomain = [];
        var funcLocate = project.funcLocate;
        var funcDomain = project.funcDomain;
        var funcHeads = project.funcHeads;
        var funcArea = project.funcArea;
        console.log(funcLocate);
        console.log(funcDomain);
        for(var key in funcLocate){
            var index = key.lastIndexOf('_');
            var num = key.substr(index+1,key.length);
            funcLocateDomain.push({funcLocate:funcLocate[key], funcDomain:funcDomain['funcDomain_'+num],
                funcHeads:funcHeads['funcHeads_'+num],funcArea:funcArea['funcArea_'+num]});
        }
        console.log('init func ---');
        console.log(funcLocateDomain);
        setInformWay(funcLocateDomain);
        setInit(true);
	}
	// if(project.funcLocateDomain&&!init){
     //    console.log('init locate --- ');
     //    lg(project.funcLocateDomain);
     //    setInit(true);
	// }


	const disabledDate = (current) => {
		return current && current > moment().subtract(1, 'days');
	}
	const getTimeValue = (start, end) => {
		const span = Math.floor(moment.duration(moment(end).diff(moment(start))).asDays())
		return r.includes(span, [31, 90, 36500]) ? span : '自定义'
	}
    const onLinkWay = (index, cmd)=>{
    	console.log(index + '|' + cmd);
        // var newInformWay = project.informWay;
		// if('add' == cmd){
         //    project.informWay.push({funcLocate:'',funcDomain:''});
		// }
		// if('remove' == cmd){
		// 	if(project.informWay.length>1){
         //        project.informWay.splice(index, 1);
		// 	}
		// }
		if('add' == cmd){
            informWay.push({funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''});
		}
		if('remove' == cmd){
			if(informWay.length>=1){
                informWay.splice(index, 1);

			}
		}
        // newInformWay = r.map(y=>({funcLocate:3,funcDomain:5}))(informWay);

		console.log('informWay ---- ');
        console.log(informWay);
        // console.log(newInformWay);
        // setInformWay(project.informWay);
        var i = 0;
        r.map(y=>{
            props.form.setFieldsValue({['funcLocate_'+i]:informWay[i].funcLocate});
            props.form.setFieldsValue({['funcDomain_'+i]:informWay[i].funcDomain});
            props.form.setFieldsValue({['funcHeads_'+i]:informWay[i].funcHeads});
            props.form.setFieldsValue({['funcArea_'+i]:informWay[i].funcArea});
            i++;
        })(informWay);

        setInformWay([...informWay]);
    }


    // 导出文档
    const loadData = async ()=>{

        //获取项目的信息
        const [{data:allProjectInfo1},
            {data:projectNodes},
            { data: { result: { detail:collectorList } } },
            {data:{resultMap:deviceList,info:funcDomainList,structured:typeEleList,root:funcAllEngList,funcJson:funcJson,funcValueJson:funcValueJson}},
            {data: dayReport},
            {data:deviceStatus}] = await Promise.all([Api.get(`/api/v3/allProjectInfo1`),
            Api.get(`/api/v3/projectNodeInfo/${project.id}/nodes`),
            Api.post('/collector/info', {
                pagesize: 1000000,
                project: project.id
            }),
            Api.postV3(`/energyNew/deviceList`,{
                from:dateArr[0],
                to:dateArr[1],
                project:project.id

            }),
            Api.get(`/api/v3/project/${project.id}/report/0?from=${dateArr[0]}&to=${dateArr[1]}`),
            Api.postV3(`/device/status`,{
                project:project.id

            }),
        ]);

        // setNodes(projectNodes);

        var _this = this;
        var projectInfo = allProjectInfo1[project.id];
        if(!projectInfo){
            console.log('error --- ,no projectInfo ');
            return;
        }

        //-s 计算 总的 各功能区 人均能耗 单位面积能耗
        //-- 建筑总面积 人均能耗 单位面积能耗
        var rankAll,rankUnitHeadE,rankUnitAreaE,
            rankEleType,rankEleTypeUnitHeadE,rankEleTypeUnitAreaE;
        //     rankLight,rankLightUnitHeadE,rankLightUnitAreaE,
        //     rankAir,rankAirUnitHeadE,rankAirUnitAreaE,
        //     rankPower,rankPowerUnitHeadE,rankPowerUnitAreaE,
        //     rankSpecial,rankSpecialUnitHeadE,rankSpecialUnitAreaE,
        //     rankWater,rankWaterUnitHeadE,rankWaterUnitAreaE;
        var unitHeadE,
            unitAreaE,
            EnergyAll=(dayReport.summary&&dayReport.summary.usage.ELECTRICITYMETER)?(dayReport.summary.usage.ELECTRICITYMETER):0,
            headCount=projectInfo.headCount?parseInt(projectInfo.headCount):1,
            totalArea=projectInfo.totalArea?parseInt(projectInfo.totalArea):1;

            unitHeadE = (EnergyAll/headCount).toFixed(2);
            unitAreaE = (EnergyAll/totalArea).toFixed(2);
        // 获取各个功能区的对应地址，面积，人数
        var funcLocateAreaHeadJson={};
        if(projectInfo.funcLocate && projectInfo.funcDomain && projectInfo.funcHeads && projectInfo.funcArea){
            var funcLocate = projectInfo.funcLocate;
            var funcDomain = projectInfo.funcDomain;
            var funcHeads = projectInfo.funcHeads;
            var funcArea = projectInfo.funcArea;
            for(var key in projectInfo.funcLocate){
                var index = key.lastIndexOf('_');
                var num = key.substr(index+1,key.length);
                funcLocateAreaHeadJson[funcDomain['funcDomain_'+num]]={funcLocate:funcLocate['funcLocate_'+num],
                    funcHeads:funcHeads['funcHeads_'+num],
                    funcArea:funcArea['funcArea_'+num],
                }
            }
        }
            // r.pipe(r.map(({funcLocate,funcDomain,funcHeads,funcArea})=>({
            //     [funcDomain]:{funcLocate,funcHeads,funcArea}
            // })),r.mergeAll)(projectInfo.funcLocateDomain);
            console.log('funcLocateAreaHeadJson');
            console.log(funcLocateAreaHeadJson);



        //    各功能区总能耗统计
        var funcAllEngValue=
        r.map(({title,value})=>({
                title,
                value:{devicesName:(value.devices&&value.devices.length>0)?r.map(y=>deviceList[y]?deviceList[y].title:'-')(value.devices):'-',...value},
                // 把value的子层数据放到父层，上面那行保留吧，因为后面用到了
                valueContent:Number(value.value).toFixed(2),
                devices:value.devices,
                devicesName:(value.devices&&value.devices.length>0)?r.map(y=>deviceList[y]?deviceList[y].title:'-')(value.devices):'-',
                funcHeads:(funcLocateAreaHeadJson[title]&&funcLocateAreaHeadJson[title].funcHeads)?funcLocateAreaHeadJson[title].funcHeads:'-',
                funcArea:(funcLocateAreaHeadJson[title]&&funcLocateAreaHeadJson[title].funcArea)?funcLocateAreaHeadJson[title].funcArea:'-',
                unitHeadE:(funcLocateAreaHeadJson[title]&&funcLocateAreaHeadJson[title].funcHeads&&value.value)?(value.value/parseInt(funcLocateAreaHeadJson[title].funcHeads)).toFixed(2):'-',
                unitAreaE:(funcLocateAreaHeadJson[title]&&funcLocateAreaHeadJson[title].funcArea&&value.value)?(value.value/parseInt(funcLocateAreaHeadJson[title].funcArea)).toFixed(2):'-',
                // deviceNameList:
            }))(funcAllEngList);
        console.log('funcAllEngValue');
        console.log(funcAllEngValue);
        // 整理格式，需要的数据放在一层中来
        var funcAllEngValueLevel1 = r.reduce(r.concat,[],r.map(({title,valueContent,funcHeads,funcArea,unitHeadE,unitAreaE,devices,devicesName})=>{
            var arr = [];
            if(devices && devices.length>0){
                for(var i =0;i<devices.length;i++){
                    arr.push({title,valueContent,funcHeads,funcArea,unitHeadE,unitAreaE,sid:devices[i],name:devicesName[i]});
                }
            }
            console.log('funcAllEngValue arr ');
            console.log(arr);
            return arr;

        })(funcAllEngValue) );



        // --- 总能耗的 各种能耗数值的排名
        rankUnitHeadE=r.compose(r.take(5),r.reverse,r.sortBy(r.prop('unitHeadE')))(r.filter(y=>y.unitHeadE!=='-')(funcAllEngValue));
        rankUnitAreaE=r.compose(r.take(5),r.reverse,r.sortBy(r.prop('unitAreaE')))(r.filter(y=>y.unitAreaE!=='-')(funcAllEngValue));
        rankAll=r.compose(r.take(5),r.reverse,r.sortBy(({value})=>(Number(value.value))))(r.filter(({value})=>value.value!==undefined)(funcAllEngValue));

        // 排名，对 总能耗的 单位面积能耗，人均能耗 分析和计算
        var avgUnitHeadE = Number(r.sum(r.map(r.prop('unitHeadE'))(rankUnitHeadE))/((rankUnitHeadE&&rankUnitHeadE.length>0)?rankUnitHeadE.length:1)).toFixed(2);
        var avgUnitAreaE = Number(r.sum(r.map(r.prop('unitAreaE'))(rankUnitAreaE))/((rankUnitAreaE&&rankUnitAreaE.length>0)?rankUnitAreaE.length:1)).toFixed(2);
        var secUnitHeadE = ((rankUnitHeadE&&rankUnitHeadE.length>1)?rankUnitHeadE[1].unitHeadE:'-');
        var secUnitAreaE = ((rankUnitAreaE&&rankUnitAreaE.length>1)?rankUnitAreaE[1].unitAreaE:'-');

        analyzeAll.unitHeadE=r.map(({title,valueContent,unitHeadE,funcHeads,funcArea})=>({
            title,valueContent,unitHeadE,funcHeads,funcArea,avg:avgUnitHeadE,sec:secUnitHeadE,ovAvg:((unitHeadE-avgUnitHeadE)/avgUnitHeadE).toFixed(2),
            ovSec:(secUnitHeadE&&secUnitHeadE!=='-')?((100*(unitHeadE-secUnitHeadE)/secUnitHeadE).toFixed(2)+'%'):'-'
        }))(rankUnitHeadE);
        analyzeAll.unitAreaE=r.map(({title,valueContent,unitAreaE,funcHeads,funcArea})=>({
            title,valueContent,unitAreaE,funcHeads,funcArea,avg:avgUnitAreaE,sec:secUnitAreaE,ovAvg:((unitAreaE-avgUnitAreaE)/avgUnitAreaE).toFixed(2),
            ovSec:(secUnitAreaE&&secUnitAreaE!=='-')?((100*(unitAreaE-secUnitAreaE)/secUnitAreaE).toFixed(2)+'%'):'-'
        }))(rankUnitHeadE);


        console.log('rankUnitHead ---- ');
        console.log(rankUnitHeadE);
        console.log(rankUnitAreaE);
        console.log(rankAll);
        console.log('analyzeAll ---- ');
        console.log(analyzeAll);

        // 各功能区 照明插座,空调等等用电
        var funcEleTypeValueJson = r.map(([title,info])=>{
            var funcHeads=(funcLocateAreaHeadJson[title]&&funcLocateAreaHeadJson[title].funcHeads)?funcLocateAreaHeadJson[title].funcHeads:'-',
             funcArea=(funcLocateAreaHeadJson[title]&&funcLocateAreaHeadJson[title].funcArea)?funcLocateAreaHeadJson[title].funcArea:'-';
            var isDomainExist = (funcHeads!=='-'&&funcArea!=='-');
            return {
            title,
            funcHeads:funcHeads,
            funcArea:funcArea,
            info:r.map(({devices,value})=>({
                devices,
                value:Number(value).toFixed(2),
                devicesName:r.map(y=>deviceList[y]?deviceList[y].title:'-')(devices),
                unitHeadE:isDomainExist?((value/parseInt(funcHeads)).toFixed(2)):'-',
                unitAreaE:isDomainExist?((value/parseInt(funcArea)).toFixed(2)):'-'
            }))(info)
        }})(r.toPairs(funcValueJson));
        console.log('shape1 -- ');
        console.log(funcEleTypeValueJson);





        // 各功能区 各种能耗数据排名
        // const eleType = [ 'lightrcptcl','powerele','airele','specialele'];
        // const resEleTypeValue = {};
        const filterValueJson =(type) => (r.filter(({title,info})=>(title !== 'noDomain' && (info[type]!== undefined)))(funcEleTypeValueJson));
        const getunitHeadE =(type)=> ({info})=>(Number(info[type].unitHeadE));
        const getunitAreaE =(type)=>  ({info})=>(Number(info[type].unitAreaE));
        const getAllE = (type)=> ({info})=>(Number(info[type].value));
        r.map((type)=>{
            // 排名
            rankEleTypeUnitHeadE=r.compose(r.take(5),r.reverse,r.sortBy(getunitHeadE(type)))(filterValueJson(type));
            rankEleTypeUnitAreaE=r.compose(r.take(5),r.reverse,r.sortBy(getunitAreaE(type)))(filterValueJson(type));
            rankEleType=r.compose(r.take(5),r.reverse,r.sortBy(getAllE(type)))(filterValueJson(type));

            if(type == eleType[0]){
                console.log('light data');
                console.log(filterValueJson(type));
            }

            console.log('rank EleType  ---- ' + type);
            console.log(rankEleTypeUnitHeadE);
            console.log(rankEleTypeUnitAreaE);
            console.log(rankEleType);

            resEleTypeValue[type]={'rankAll':rankEleType,'rankUnitHeadE':rankEleTypeUnitHeadE,'rankUnitAreaE':rankEleTypeUnitAreaE};

            // 排名之后 ： 统计数据 平均数值 第一 第二名等等数据，只用人均能耗，单位面积能耗进行分析，四个分项共 5*2=10
            var avgUnitHeadE = Number(r.sum(r.map(({info})=>(info[type].unitHeadE))(rankEleTypeUnitHeadE))/((rankEleTypeUnitHeadE&&rankEleTypeUnitHeadE.length>0)?rankEleTypeUnitHeadE.length:1)).toFixed(2);
            var avgUnitAreaE = Number(r.sum(r.map(({info})=>(info[type].unitAreaE))(rankEleTypeUnitAreaE))/((rankEleTypeUnitAreaE&&rankEleTypeUnitAreaE.length>0)?rankEleTypeUnitAreaE.length:1)).toFixed(2);
            var secUnitHeadE = ((rankEleTypeUnitHeadE&&rankEleTypeUnitHeadE.length>1)?rankEleTypeUnitHeadE[1].info[type].unitHeadE:'-');
            var secUnitAreaE = ((rankEleTypeUnitAreaE&&rankEleTypeUnitAreaE.length>1)?rankEleTypeUnitAreaE[1].info[type].unitAreaE:'-');

            console.log('rankEleTypeUnitHeadE' +type);
            console.log(rankEleTypeUnitHeadE);
            analyzeEleType[type]={
                unitHeadE:r.map(({title,funcHeads,funcArea,info})=>{
                    var value = info[type].value,unitHeadE=info[type].unitHeadE;
                    return {
                title,value:value,unitHeadE:unitHeadE,funcHeads,funcArea,avg:avgUnitHeadE,sec:secUnitHeadE,ovAvg:((100*(unitHeadE-avgUnitHeadE)/avgUnitHeadE).toFixed(2))+'%',
                ovSec:(secUnitHeadE&&secUnitHeadE!=='-')?((100*(unitHeadE-secUnitHeadE)/secUnitHeadE).toFixed(2)+'%'):'-'
            }})(rankEleTypeUnitHeadE),
                unitAreaE:r.map(({title,funcHeads,funcArea,info})=>{
                    var value = info[type].value,unitAreaE=info[type].unitAreaE;
                    return{
                    title,value:value,unitAreaE:unitAreaE,funcHeads,funcArea,avg:avgUnitAreaE,sec:secUnitAreaE,ovAvg:((100*(unitAreaE-avgUnitAreaE)/avgUnitAreaE).toFixed(2))+'%',
                    ovSec:(secUnitAreaE&&secUnitAreaE!=='-')?((100*(unitAreaE-secUnitAreaE)/secUnitAreaE).toFixed(2)+'%'):'-'
                }})(rankEleTypeUnitAreaE)
            };






            // 各功能区 分类能耗的 列表信息 归成一层数据
            resEleTypeTj[type] = r.map(({title,funcHeads,funcArea,info})=>{
                var isDomainExist = (funcHeads!=='-'&&funcArea!=='-');
                var arr = [],devices=info[type].devices,devicesName=info[type].devicesName,value = info[type].value,unitHeadE=info[type].unitHeadE,unitAreaE=info[type].unitAreaE;
                if(devices && devices.length>0){
                    for(var i =0;i<devices.length;i++){
                        arr.push({title,value,funcHeads,funcArea,unitHeadE,unitAreaE,sid:devices[i],name:devicesName[i]});
                    }
                }
                return arr;
            })(filterValueJson(type));



        })(eleType);
        console.log('resEleTypeValue --- ');
        console.log(resEleTypeValue);
        console.log(resEleTypeTj);
        console.log(analyzeEleType);





        //-e
        //-s
        nodesEcharts.setOption(getOption(projectNodes));
        funcAll.setOption(getOption3(rankAll,'rankAll'));
        funcAllUnitHeadE.setOption(getOption3(rankUnitHeadE,'unitHeadE'));
        funcAllUnitAreaE.setOption(getOption3(rankUnitAreaE,'unitAreaE'));

        funcLightAll.setOption(getOption2(resEleTypeValue,'lightrcptcl','rankAll'));
        funcLightUnitHeadE.setOption(getOption2(resEleTypeValue,'lightrcptcl','rankUnitHeadE'));
        funcLightUnitAreaE.setOption(getOption2(resEleTypeValue,'lightrcptcl','rankUnitAreaE'));
        //
        funcAirAll.setOption(getOption2(resEleTypeValue,'airele','rankAll'));
        funcAirUnitHeadE.setOption(getOption2(resEleTypeValue,'airele','rankUnitHeadE'));
        funcAirUnitAreaE.setOption(getOption2(resEleTypeValue,'airele','rankUnitAreaE'));
        //
        funcPowerAll.setOption(getOption2(resEleTypeValue,'powerele','rankAll'));
        funcPowerUnitHeadE.setOption(getOption2(resEleTypeValue,'powerele','rankUnitHeadE'));
        funcPowerUnitAreaE.setOption(getOption2(resEleTypeValue,'powerele','rankUnitAreaE'));
        //
        funcSpecialAll.setOption(getOption2(resEleTypeValue,'specialele','rankAll'));
        funcSpecialUnitHeadE.setOption(getOption2(resEleTypeValue,'specialele','rankUnitHeadE'));
        funcSpecialUnitAreaE.setOption(getOption2(resEleTypeValue,'specialele','rankUnitAreaE'));
        //-e

        var ordI= 1,ordColl=1,ordReportEle=1,ordReportWat=1;

        dataJson2 = {
            // table: _this.tableData,
            allProjectInfo:allProjectInfo1,
            project_name:allProjectInfo1[project.id].title,
            compile_date:moment().format('YYYY年MM月DD日'),
            month:moment().subtract(1,'month').format('MM'),
            building_type:projectType[projectInfo.sector],
            locate:projectInfo.locate,
            // headCount:projectInfo.headCount?parseInt(projectInfo.headCount):1,
            // totalArea:projectInfo.totalArea?parseInt(projectInfo.totalArea):1,
            floors:projectInfo.floors,
            funcLocateDomain:projectInfo.funcLocateDomain,
            devSummary:projectInfo.devSummary,
            devEle:projectInfo.devEle,
            devWat:projectInfo.devWat,
            // deviceList:r.sortBy(r.prop('funcDomain'))(r.map(([a,b])=>(r.merge({sid:a},b)))(r.toPairs()(deviceList))),//这行是没有增加order的
            deviceList:r.map(({...values})=>({ord:r.add(0)(ordI++),...values}))(r.sortBy(r.prop('funcDomain'))(r.map(([a,b])=>(r.merge({sid:a},b)))(r.toPairs()(deviceList)))),
            funcDomainList:funcDomainList,
            typeEleList:typeEleList,
            funcAllEngList:funcAllEngList,
            funcJson:funcJson,
            funcValueJson:funcValueJson,
            funcAllEngValue:funcAllEngValue,//功能区总能耗，人均，单位面积
            funcAllDoc:funcAllEngValueLevel1,//
            funcltDoc:resEleTypeTj[eleType[0]],//功能区 照明用电 统计表
            funcprDoc:resEleTypeTj[eleType[1]],//功能区 动力用电 统计表
            funcarDoc:resEleTypeTj[eleType[2]],//功能区 空调用电 统计表
            funcspDoc:resEleTypeTj[eleType[3]],//功能区 特殊用电 统计表

            anlyAllHead:analyzeAll.unitHeadE,
            anlyAllHeadN1:(analyzeAll.unitHeadE[0])?[analyzeAll.unitHeadE[0]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyAllHeadN2:(analyzeAll.unitHeadE[1])?[analyzeAll.unitHeadE[1]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyAllArea:analyzeAll.unitAreaE,
            anlyAllAreaN1:(analyzeAll.unitAreaE[0])?[analyzeAll.unitAreaE[0]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyAllAreaN2:(analyzeAll.unitAreaE[1])?[analyzeAll.unitAreaE[1]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],

            anlyLtHead:analyzeEleType[eleType[0]].unitHeadE,//功能区 照明用电 统计表 人均
            anlyLtHeadN1:(analyzeEleType[eleType[0]].unitHeadE[0])?[analyzeEleType[eleType[0]].unitHeadE[0]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyLtHeadN2:(analyzeEleType[eleType[0]].unitHeadE[1])?[analyzeEleType[eleType[0]].unitHeadE[1]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyPrHead:analyzeEleType[eleType[1]].unitHeadE,//功能区 动力用电 统计表 人均
            anlyPrHeadN1:(analyzeEleType[eleType[1]].unitHeadE[0])?[analyzeEleType[eleType[1]].unitHeadE[0]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyPrHeadN2:(analyzeEleType[eleType[1]].unitHeadE[1])?[analyzeEleType[eleType[1]].unitHeadE[1]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyArHead:analyzeEleType[eleType[2]].unitHeadE,//功能区 空调用电 统计表 人均
            anlyArHeadN1:(analyzeEleType[eleType[2]].unitHeadE[0])?[analyzeEleType[eleType[2]].unitHeadE[0]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyArHeadN2:(analyzeEleType[eleType[2]].unitHeadE[1])?[analyzeEleType[eleType[2]].unitHeadE[1]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlySpHead:analyzeEleType[eleType[3]].unitHeadE,//功能区 特殊用电 统计表 人均
            anlySpHeadN1:(analyzeEleType[eleType[3]].unitHeadE[0])?[analyzeEleType[eleType[3]].unitHeadE[0]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlySpHeadN2:(analyzeEleType[eleType[3]].unitHeadE[1])?[analyzeEleType[eleType[3]].unitHeadE[1]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],

            anlyLtArea:analyzeEleType[eleType[0]].unitAreaE,//功能区 照明用电 统计表 单位面积
            anlyLtAreaN1:(analyzeEleType[eleType[0]].unitAreaE[0])?[analyzeEleType[eleType[0]].unitAreaE[0]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyLtAreaN2:(analyzeEleType[eleType[0]].unitAreaE[1])?[analyzeEleType[eleType[0]].unitAreaE[1]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyPrArea:analyzeEleType[eleType[1]].unitAreaE,//功能区 动力用电 统计表 单位面积
            anlyPrAreaN1:(analyzeEleType[eleType[1]].unitAreaE[0])?[analyzeEleType[eleType[1]].unitAreaE[0]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyPrAreaN2:(analyzeEleType[eleType[1]].unitAreaE[1])?[analyzeEleType[eleType[1]].unitAreaE[1]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyArArea:analyzeEleType[eleType[2]].unitAreaE,//功能区 空调用电 统计表 单位面积
            anlyArAreaN1:(analyzeEleType[eleType[2]].unitAreaE[0])?[analyzeEleType[eleType[2]].unitAreaE[0]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlyArAreaN2:(analyzeEleType[eleType[2]].unitAreaE[1])?[analyzeEleType[eleType[2]].unitAreaE[1]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlySpArea:analyzeEleType[eleType[3]].unitAreaE,//功能区 特殊用电 统计表 单位面积
            anlySpAreaN1:(analyzeEleType[eleType[3]].unitAreaE[0])?[analyzeEleType[eleType[3]].unitAreaE[0]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],
            anlySpAreaN2:(analyzeEleType[eleType[3]].unitAreaE[1])?[analyzeEleType[eleType[3]].unitAreaE[1]]:[{title:'-',unitHeadE:'-',unitAreaE:'-'}],

            collectorList:r.map(({...values})=>({ord:r.add(0)(ordColl++),...values}))(collectorList),
            dayReport:dayReport,
            dayReportEle:(dayReport.ELECTRICITYMETER)?(r.map(({...values})=>({ord:r.add(0)(ordReportEle++),...values}))(r.map(({startScale,endScale,...values})=>({startScale:Number(startScale).toFixed(2),endScale:Number(endScale).toFixed(2),...values}))(dayReport.ELECTRICITYMETER))):[],
            dayReportWat:(dayReport.COLDWATERMETER)?(r.map(({...values})=>({ord:r.add(0)(ordReportWat++),...values}))(r.map(({startScale,endScale,...values})=>({startScale:Number(startScale).toFixed(2),endScale:Number(endScale).toFixed(2),...values}))(dayReport.COLDWATERMETER))):[],
            summaryEle:dayReport.summary.usage.ELECTRICITYMETER,
            summaryWat:dayReport.summary.usage.COLDWATERMETER,
            deviceStatus:deviceStatus,
            eleTypes:'-',
            watTypes:'-',
            EnergyAll:EnergyAll,
            headCount:headCount,
            totalArea:totalArea,
            unitHeadE:unitHeadE,
            unitAreaE:unitAreaE,

            nodesEcharts:nodesEcharts.getDataURL(),

            funcAll:funcAll.getDataURL(),
            funcAllUnitHeadE:funcAllUnitHeadE.getDataURL(),
            funcAllUnitAreaE:funcAllUnitAreaE.getDataURL(),

            funcLightAll:funcLightAll.getDataURL(),
            funcLightUnitHeadE:funcLightUnitHeadE.getDataURL(),
            funcLightUnitAreaE:funcLightUnitAreaE.getDataURL(),

            funcAirAll:funcAirAll.getDataURL(),
            funcAirUnitHeadE:funcAirUnitHeadE.getDataURL(),
            funcAirUnitAreaE:funcAirUnitAreaE.getDataURL(),

            funcPowerAll:funcPowerAll.getDataURL(),
            funcPowerUnitHeadE:funcPowerUnitHeadE.getDataURL(),
            funcPowerUnitAreaE:funcPowerUnitAreaE.getDataURL(),

            funcSpecialAll:funcSpecialAll.getDataURL(),
            funcSpecialUnitHeadE:funcSpecialUnitHeadE.getDataURL(),
            funcSpecialUnitAreaE:funcSpecialUnitAreaE.getDataURL(),

            //-- test
            first_name: 'John',
            last_name: 'Doe',
            phone: '0652455478',
            description: 'New Website',

            clients: [
                {
                    "first_name": "John",
                    "last_name": "Doe",
                    "phone": "+44546546454"
                },
                {
                    "first_name": "Jane",
                    "last_name": "Doe",
                    "phone": "+445476454"
                }
            ],
            // tb:tb,
            rawXml:'<w:p><w:pPr><w:rPr><w:color w:val="FF0000"/></w:rPr></w:pPr><w:r><w:rPr><w:color w:val="FF0000"/></w:rPr><w:t>My custom</w:t></w:r><w:r><w:rPr><w:color w:val="00FF00"/></w:rPr><w:t>XML</w:t></w:r></w:p>',
            // loop:true,
            "loop": [
                {inner:'one'},
                {inner:'two'},
                {inner:'three'},
            ]
        };
        console.log(dataJson2);

    }


    const exportDoc = ()=>{


        console.log('click the export word button : ------ ');
        // 点击导出word
        function replaceErrors(key, value) {
            if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function(error, key) {
                    error[key] = value[key];
                    return error;
                }, {});
            }
            return value;
        }

        function errorHandler(error) {
            console.log(JSON.stringify({error: error}, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
                const errorMessages = error.properties.errors.map(function (error) {
                    return error.properties.explanation;
                }).join("\n");
                console.log('errorMessages', errorMessages);
                // errorMessages is a humanly readable message looking like this :
                // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
        }

// image start ---
        var opts = {}
        opts.centered = false; //Set to true to always center images
        opts.fileType = "docx"; //Or pptx


// 导出echarts图片，格式转换
        const base64DataURLToArrayBuffer =(dataURL) => {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        }

        opts.getImage = function(chartId){
            return base64DataURLToArrayBuffer(chartId);
        }

//Pass the function that return image size
        opts.getSize = function(img, tagValue, tagName,widX,widY) {
            //img is the image returned by opts.getImage()
            //tagValue is 'examples/image.png'
            //tagName is 'image'
            //tip: you can use node module 'image-size' here
            return [widX?widX:150, widY?widY:150];
        }

        var imageModule = new ImageModule(opts);

// image end -----

        JSZipUtils.getBinaryContent(energyReport_single,function(error, content) {
            // exportTemplate.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
            // 抛出异常
            if (error) {
                throw error;
            }
            // 图片处理
            let opts = {}
            opts.centered = true;  // 图片居中，在word模板中定义方式为{%%image}
            opts.fileType = "docx";
            opts.getImage = function(chartId){
                return base64DataURLToArrayBuffer(chartId);
            }
            opts.getSize = function(img, tagValue, tagName,widX,widY) {
                //img is the image returned by opts.getImage()
                //tagValue is 'examples/image.png'
                //tagName is 'image'
                //tip: you can use node module 'image-size' here

                return [widX?widX:450, widY?widY:450];
            }
            let imageModule = new ImageModule(opts);
            // 创建一个PizZip实例，内容为模板的内容
            let zip = new PizZip(content);
            // 创建并加载docxtemplater实例对象
            let doc = new Docxtemplater();
            doc.attachModule(imageModule);
            doc.loadZip(zip);

            // 设置模板变量的值
            var i = 1;
            console.log('tb');
            //

            doc.setData(dataJson2);

            try {
                // 用模板变量的值替换所有模板变量
                doc.render();
            } catch (error) {
                // 抛出异常
                let e = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    properties: error.properties
                };
                throw error;
            }
            // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
            let out = doc.getZip().generate({
                type: "blob",
                mimeType:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            // 将目标文件对象保存为目标类型的文件，并命名
            saveAs(out, "exportDocx.docx");
        });


    }

    const dealInformWay = (type,index,value)=>{
		console.log('dealINformWay---');
		console.log(type + '|' + index + '|' + value);
        informWay[index][type]=value;
        console.log(informWay);
        setInformWay([...informWay]);
	}

    const uploadProps = {
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        showUploadList: false,
        customRequest: (info, a, b) => {
            let fileList = info.file;
            let formdata = new FormData()
            formdata.append('projectid', project.id)
            formdata.append('sensors', fileList)

            Api.post('/import/report',
                formdata
            ).then(({ data }) => {
                console.log(data)
                if (data.code === 0) {
                    message.success(`文件上传成功.`);
                } else {
                    message.error(`文件上传失败.`);
                    message.error(data.message);
                }
            })
        },
    }


    const getOption =(nodes) =>({
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        backgroundColor: '#848484',
        series: [
            {
                type: 'tree',
                animation: false,
                data: (nodes)?[nodes]:[],

                top: '1%',
                left: '20%',
                bottom: '1%',
                right: '20%',

                symbolSize: 7,

                label: {
                    position: 'left',
                    verticalAlign: 'middle',
                    align: 'right',
                    fontSize: 9
                },

                leaves: {
                    label: {
                        position: 'right',
                        verticalAlign: 'middle',
                        align: 'left'
                    }
                },

                expandAndCollapse: true,
                animationDuration: 550,
                animationDurationUpdate: 750
            }
        ]
    });
    // 分项能耗用
    const getOption2 =(resEleTypeValue,type,rankType)=> {
        // var type = 'lightrcptcl';
        // var rankType = 'rankUnitHeadE';


        var dataAxis=[] ,data=[];
        if(resEleTypeValue[type]&&resEleTypeValue[type][rankType] && resEleTypeValue[type][rankType].length > 0){
            var rankData = resEleTypeValue[type][rankType];
            dataAxis = r.map(({title})=>(title))(rankData);
            data = r.map(({info})=>((info[type]&&info[type][rankTypeValueLink[rankType]])?(info[type][rankTypeValueLink[rankType]]):0))(rankData);
        }

        var yMax = data.length>0?(data[0]+20):20;
        var dataShadow = [];

        for (var i = 0; i < data.length; i++) {
            dataShadow.push(yMax);
        }
        return {
        title: {
            text: optionName[type]+optionName[rankType]+'排名',
            // subtext: optionName[type]+optionName[rankType]+'排名',
            // textAlign:'right'
            x:'center'
        },
            backgroundColor: '#848484',
        xAxis: {
            data: dataAxis,
            axisLabel: {
                // inside: true,
                textStyle: {
                    color: '#161616'
                }
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            z: 10
        },
        yAxis: {
            // data:dataAxis,
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                // inside:false,
                textStyle: {
                    color: '#161616'
                }
            }
        },
        dataZoom: [
            {
                type: 'inside'
            }
        ],
        series: [
            { // For shadow
                type: 'bar',
                itemStyle: {
                    color: 'rgba(0,0,0,0.05)'
                },
                barGap: '-100%',
                barCategoryGap: '40%',
                data: dataShadow,
                animation: false
            },
            {
                type: 'bar',
                animation: false,
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            // 	textStyle: { //数值样式
                            // // 		color: 'black',
                            // 		fontSize: 16
                            // 	}
                        },
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#83bff6'},
                                {offset: 0.5, color: '#188df0'},
                                {offset: 1, color: '#188df0'}
                            ]
                        )
                    },
                },
                emphasis: {
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#2378f7'},
                                {offset: 0.7, color: '#2378f7'},
                                {offset: 1, color: '#83bff6'}
                            ]
                        )
                    }
                },
                data: data
            }
        ]
    }};
    // 总能耗用
    const getOption3 =(funcAllData,rankType)=> {
        // var type = 'lightrcptcl';
        // var rankType = 'rankUnitHeadE';


        var dataAxis=[] ,data=[];

        if(funcAllData.length> 0){
            dataAxis=r.map(({title})=>(title))(funcAllData);
            data=(rankType=='rankAll')?r.map(({value})=>((value.value)?(value.value):0))(funcAllData):r.map(({[rankType]:v})=>(v))(funcAllData);
        }
        console.log('dataAxis -- ');
        console.log(dataAxis);
        console.log(data);

        var yMax = data.length>0?(data[0]+20):20;
        var dataShadow = [];

        for (var i = 0; i < data.length; i++) {
            dataShadow.push(yMax);
        }
        return {
        title: {
            text: optionName[rankType]+'排名',
            // subtext: ((rankType==='rankAll'?'总耗电量':(rankType === 'unitHeadE'?'人均能耗':'单位面积能耗')))+'月度前五名',
            // textAlign:'right'
            x:'center'
        },
            backgroundColor: '#848484',
        xAxis: {
            data: dataAxis,
            axisLabel: {
                // inside: true,
                textStyle: {
                    color: '#161616'
                }
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            z: 10
        },
        yAxis: {
            // data:dataAxis,
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                // inside:false,
                textStyle: {
                    color: '#323232'
                }
            }
        },
        dataZoom: [
            {
                type: 'inside'
            }
        ],
        series: [
            { // For shadow
                type: 'bar',
                itemStyle: {
                    color: 'rgba(0,0,0,0.05)'
                },
                barGap: '-100%',
                barCategoryGap: '40%',
                data: dataShadow,
                animation: false
            },
            {
                type: 'bar',
                animation: false,
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            // 	textStyle: { //数值样式
                            // // 		color: 'black',
                            // 		fontSize: 16
                            // 	}
                        },
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#83bff6'},
                                {offset: 0.5, color: '#188df0'},
                                {offset: 1, color: '#188df0'}
                            ]
                        )
                    },
                },
                emphasis: {
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#2378f7'},
                                {offset: 0.7, color: '#2378f7'},
                                {offset: 1, color: '#83bff6'}
                            ]
                        )
                    }
                },
                data: data
            }
        ]
    }};
    const onChange = (date) => {
        console.log('date');
        console.log(date);
        dateArr[0] = date[0] ? date[0].format('YYYYMMDD') : moment();
        dateArr[1] = date[1] ? date[1].format('YYYYMMDD') : moment();
    }



    return (
		<div className="newReport">
            <div>
                <h4>模块管理</h4>
                <Button  type="primary" onClick={()=>loadData()} >装载</Button>
                <Button type="primary" onClick={()=>exportDoc()}>导出</Button>
                <Upload {...uploadProps}>
                    <Button type="primary" >导入</Button>
                </Upload>
            </div>
            <div className='echartsDiv' id='nodesEcharts' style={style3}>
            </div>

            <div className='echartsDiv' id='funcAll' style={style2}>
            </div>
            <div className='echartsDiv' id='funcAllUnitHeadE' style={style2}>
            </div>
            <div className='echartsDiv' id='funcAllUnitAreaE' style={style2}>
            </div>
            <div className='echartsDiv' id='funcLightAll' style={style2}>
            </div>
            <div className='echartsDiv' id='funcLightUnitHeadE' style={style2}>
        </div>
            <div className='echartsDiv' id='funcLightUnitAreaE' style={style2}>
            </div>

            <div className='echartsDiv' id='funcAirAll' style={style2}>
            </div>
            <div className='echartsDiv' id='funcAirUnitHeadE' style={style2}>
            </div>
            <div className='echartsDiv' id='funcAirUnitAreaE' style={style2}>
            </div>

            <div className='echartsDiv' id='funcPowerAll' style={style2}>
            </div>
            <div className='echartsDiv' id='funcPowerUnitHeadE' style={style2}>
            </div>
            <div className='echartsDiv' id='funcPowerUnitAreaE' style={style2}>
            </div>

            <div className='echartsDiv' id='funcSpecialAll' style={style2}>
            </div>
            <div className='echartsDiv' id='funcSpecialUnitHeadE' style={style2}>
            </div>
            <div className='echartsDiv' id='funcSpecialUnitAreaE' style={style2}>
            </div>

            <div>
                <Form className='newReportForm'>
                    <Row>
                        <div>

                            <div>
                                <RangePicker
                                    defaultValue={[moment().subtract(1,'month').startOf('month'),
                                        moment().subtract(1,'month').endOf('month')]}
                                    onChange={onChange}
                                    disabledDate={disabledDate}
                                />
                            </div>
                            <Form.Item {...formItemLayout}
                                       label="项目名称"
                            >
                                {getFieldDecorator('name', {
                                    initialValue: project.name,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="人数"
                            >
                                {getFieldDecorator('headCount', {
                                    initialValue: project.headCount,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="面积"
                            >
                                {getFieldDecorator('totalArea', {
                                    initialValue: project.totalArea,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="项目类型"
                            >
                                {getFieldDecorator('sector', {
                                    initialValue: project.sector,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="经度"
                            >
                                {getFieldDecorator('longitude2', {
                                    initialValue: project.longitude2,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="纬度"
                            >
                                {getFieldDecorator('latitude2', {
                                    initialValue: project.latitude2,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="区域"
                            >
                                {getFieldDecorator('region', {
                                    initialValue: project.region,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="建筑id"
                            >
                                {getFieldDecorator('buildingId', {
                                    initialValue: project.buildingId,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="地址"
                            >
                                {getFieldDecorator('locate', {
                                    initialValue: project.locate,
                                    // rules: [{required: false}],
                                })(
                                    <Input />
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="最高层数"
                            >
                                {getFieldDecorator('floors', {
                                    initialValue: project.floors,
                                    // rules: [{required: false}],
                                })(
                                    <Input />
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="简称"
                            >
                                {getFieldDecorator('shortName', {
                                    initialValue: project.shortName,
                                    // rules: [{required: false}],
                                })(
                                    <Input />
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="建筑功能分区"
                            >
                                {getFieldDecorator('func', {
                                    initialValue: '建筑对应功能分区',
                                    // rules: [{required: false}],
                                })(
                                    <Input.TextArea  disabled={true}/>
                                )}

                            </Form.Item>

                            <Form.Item >
                            <div className="informWay" >

                                {/*{ project.informWay && project.informWay.map((element, index) => (*/}
                                { informWay && informWay.map((element, index) => (
                                    <span key={index} style={{width:'100%'}}>
							<Col span={widSpan}>
								    <Form.Item  >
									{getFieldDecorator(`funcLocate_${index}`, {
                                        initialValue: element.funcLocate,
                                        rules: [{
                                            required: true,
                                            message: '请输入楼层(楼栋)',
                                        }],
                                    })(
                                        <Input placeholder="请输入楼层(楼栋)"  onChange={e => {
                                            dealInformWay('funcLocate',index,e.target.value);}} />,
                                    )}
									</Form.Item>
							</Col>

							<Col span={widSpan}>
								   <Form.Item >
									{getFieldDecorator(`funcDomain_${index}`, {
                                        initialValue: element.funcDomain,
                                        // initialValue: '23',
                                        rules: [{
                                            required: true,
                                            message: '请输入公司(功能区)',
                                        }],
                                    })(
                                        <Input   placeholder= '请输入公司(功能区)'  onChange={e => {
                                            dealInformWay('funcDomain',index,e.target.value);}}/>,
                                    )}
									</Form.Item>
								</Col>
							<Col span={widSpan}>
								   <Form.Item >
									{getFieldDecorator(`funcHeads_${index}`, {
                                        initialValue: element.funcHeads,
                                        // initialValue: '23',
                                        rules: [{
                                            required: true,
                                            message: '请输入人数',
                                        }],
                                    })(
                                        <Input   placeholder= '请输入人数'  onChange={e => {
                                            dealInformWay('funcHeads',index,e.target.value);}}/>,
                                    )}
									</Form.Item>
								</Col>
							<Col span={widSpan}>
								   <Form.Item >
									{getFieldDecorator(`funcArea_${index}`, {
                                        initialValue: element.funcArea,
                                        // initialValue: '23',
                                        rules: [{
                                            required: true,
                                            message: '请输入面积',
                                        }],
                                    })(
                                        <Input   placeholder= '请输入面积'  onChange={e => {
                                            dealInformWay('funcArea',index,e.target.value);}}/>,
                                    )}
									</Form.Item>
								</Col>

							<Col span={3} offset={1} className='operaCol'>
								{index < 10&& <Button shape="circle" size="small" icon="plus" type="primary"  onClick={() => onLinkWay(index, 'add')} className='operaButtom'/>}
                                {index >=0 && <Button shape="circle" size="small" icon="minus" type="default"  onClick={() => onLinkWay(index, 'remove')}  className='operaButtom' />}
							</Col>
                         </span>

                                ))}
                            </div>

                            </Form.Item>
                            {/*<br/>*/}
                            {/*/!*<br/><h3> ---------- 2.1 能耗监测种类 ---------- </h3><br/>*!/*/}
                            {/*<Form.Item {...formItemLayout}*/}
                                       {/*label="耗电分项"*/}
                            {/*>*/}
                                {/*{getFieldDecorator('eleTypes', {*/}
                                    {/*initialValue: project.floors,*/}
                                    {/*// rules: [{required: false}],*/}
                                {/*})(*/}
                                    {/*<Input />*/}
                                {/*)}*/}

                            {/*</Form.Item>*/}
                            {/*<Form.Item {...formItemLayout}*/}
                                       {/*label="耗水分项"*/}
                            {/*>*/}
                                {/*{getFieldDecorator('watTypes', {*/}
                                    {/*initialValue: project.floors,*/}
                                    {/*// rules: [{required: false}],*/}
                                {/*})(*/}
                                    {/*<Input />*/}
                                {/*)}*/}

                            {/*</Form.Item>*/}
                            {/*/!*<br/><h3> ---------- 2.2 能耗监测设备 ---------- </h3><br/>*!/*/}
                            {/*<Form.Item {...formItemLayout}*/}
                                       {/*label="dev_summary"*/}
                            {/*>*/}
                                {/*<Input placeholder="能耗监测设备总"/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item {...formItemLayout}*/}
                                       {/*label="dev_ele"*/}
                            {/*>*/}
                                {/*<Input placeholder="设备_电"/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item {...formItemLayout}*/}
                                       {/*label="dev_water"*/}
                            {/*>*/}
                                {/*<Input placeholder="设备_水"/>*/}
                            {/*</Form.Item>*/}

                            {/*/!*<br/><h4> ---------- 表 能耗监测设备 ---------- </h4><br/>*!/*/}
                            {/*<Form.Item {...formItemLayout}*/}
                                       {/*label="dev_tp"*/}
                            {/*>*/}
                                {/*<Input placeholder="设备能耗类型"/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item {...formItemLayout}*/}
                                       {/*label="dev_name"*/}
                            {/*>*/}
                                {/*<Input placeholder="设备名称"/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item {...formItemLayout}*/}
                                       {/*label="dev_model"*/}
                            {/*>*/}
                                {/*<Input placeholder="型号"/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item {...formItemLayout}*/}
                                       {/*label="dev_num"*/}
                            {/*>*/}
                                {/*<Input placeholder="数量"/>*/}
                            {/*</Form.Item>*/}
                            {/*<Form.Item {...formItemLayout}*/}
                                       {/*label="dev_nodes"*/}
                            {/*>*/}
                                {/*<Input placeholder="设备节点"/>*/}
                            {/*</Form.Item>*/}


                            {/*<br/><h3> ---------- 载入 ---------- </h3><br/>*/}
                            <Form.Item {...formItemLayout}
                                       label="载入"
                            >
                                {getFieldDecorator('load', {
                                    initialValue: project.load,
                                })(
                                    <Radio.Group>
                                        <Radio value={1}>载入</Radio>
                                        <Radio value={2}>不载入</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        </div>

                    </Row>
                </Form>




                <Upload {...uploadProps}>
                    <Button>导入</Button>
                </Upload>
                <Button onClick={()=>loadData()}>导出</Button>
            </div>

		</div>
	);
}

export default UpdateReportForm;