import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { of } from 'rxjs';
import { context, loadupdateData } from '../billingService'

import Api from 'src/api';

const confirm = Modal.confirm;
const BillingServiceDelete = (props) => {
    const { dispatch } = useContext(context)
    const showConfirm = () => {
        const { allData: { id } } = props.record
        confirm({
            title: '您确定要删除该仪表吗！',
            onOk() {
                return (
                    Api.post('/billingservice/delete', {
                        id
                    }).then(res => {
                        res.data.message === '' ? message.info('删除成功') : message.info(res.data.message)
                        dispatch(state => of({ ...state, loading: true }))
                        return dispatch(state => loadupdateData({ ...state, loading: true }))
                    }))
            },
            onCancel() { },
        });
    }
    return (
        <span onClick={showConfirm} style={{ color: '#FF0927', marginLeft: '20px' }}>
            删除
    </span>
    )
}


export default BillingServiceDelete;