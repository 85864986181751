import React, { useState, useContext } from 'react';
import { Modal, message, Button, Input, Row, Col } from 'antd';
import { of } from 'rxjs';
import { context, loadManagerStates } from '../manager';
import * as r from 'ramda';

import Api from 'src/api';


const Delete = (props) => {
    const { dispatch } = useContext(context);
    const { information } = props.record;
    const { tag, title, description } = information
    const [visible, setVisible] = useState(false);
    const [changeTitle, setChangeTitle] = useState(title);
    const [changeDes, setChangeDes] = useState(description);

    const handleOk = async () => {
        Api.post('/collector/update',
            r.assoc('description', changeDes)(r.assoc('title', changeTitle)(information))
        ).then(() => {
            message.info('修改成功')
            setVisible(false)
            dispatch(state => of({ ...state, loading: true }))
            return dispatch(state => loadManagerStates({ ...state, loading: true, }))
        }).catch(() => message.info('修改失败'))
    };

    const handleCancel = () => {
        setVisible(false)
    };

    const changeName = (e) => {
        setChangeTitle(e.target.value)
    }
    const changeDescription = e => {
        setChangeDes(e.target.value)
    }

    return (
        <div style={{
            float: 'left',
            marginRight: "10px"
        }}>
            <Button size='small' onClick={() => setVisible(true)} >
                编辑
    </Button>
            <Modal
                title="管理智能仪表"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={960}
                style={{ top: 20 }}
                okText='保存'
                cancelText='取消'
                destroyOnClose={true}
            >
                <Row>
                    <Col><span>标识</span></Col><Col><Input value={tag} disabled /></Col>
                    <Col><span>名称</span></Col><Col><Input defaultValue={title} onChange={changeName} /></Col>
                    <Col><span>描述</span></Col><Col><Input.TextArea defaultValue={description} onChange={changeDescription} /></Col>
                </Row>

            </Modal>
        </div>
    );
}


export default Delete;