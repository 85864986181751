import React, { useContext, useState } from 'react';
import { context, loadNodeUsage } from '../../byitem'
import { Table, Button, Icon, message, Modal, Tooltip } from 'antd';
import { of } from 'rxjs';
import Api from 'api';
import Delete from '../../byitemDelete/byitemDelete';
import ByitemModal from '../../byitemModal/byitemModal'
import {ROOT_NODE} from "src/context/treeView";
const { confirm } = Modal;
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: '90px'
    },
    {
        title: '设备名称',
        dataIndex: 'title',
        key: 'title',
        width: '270px',
        render: (text) => {
            return text.length > 16 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
                width: '200px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip > : <span>{text}</span>
        }
    },
    {
        title: '设备ID',
        dataIndex: 'id',
        key: 'id',
        width: '240px'
    },
    {
        title: '设备类型',
        dataIndex: 'type',
        key: 'type',
        width: '200px'
    },
    {
        title: '设备通道',
        dataIndex: 'channel',
        key: 'ichanneld',
        width: '200px'
    },
    {
        title: '能耗类型',
        dataIndex: 'category',
        key: 'category',
        width: '200px'
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
            return <div><Delete record={record} /></div>
        }
    }, {
        title: '信息',
        dataIndex: 'information',
        key: 'information',
        className: 'hide'
    }
];

const ByitemRightBottom = () => {
    const { state: { dataSource, loading, node }, dispatch } = useContext(context)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows)
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const allDelect = () => {
        confirm({
            title: '您确定要删除该仪表吗！',
            onOk() {
                console.log(selectedRows)
                return (
                    selectedRows.forEach((k, i) => {
                        Api.delete(`/project/${Api.projectId}/node/${k.removeid}`).then(res => {
                            res.data.message ? message.info(res.data.message) : message.info('删除成功');
                            setSelectedRows([])
                            setSelectedRowKeys([])
                            dispatch(state => of({ ...state, loading: true }))
                            return dispatch(state => loadNodeUsage({ ...state, loading: true }))
                        })
                    })

                )
            },
            onCancel() {
                dispatch(state => of({ ...state, loading: true }))
                return dispatch(state => loadNodeUsage({ ...state, loading: true }))
            },
        });


    }
    return (
        <div className="byitemRightBottom">
            <div className='byitemRightBottomHead'>
                <div className="byitemRightBottomHeadLeft">
                    {node === ROOT_NODE ? '' : <ByitemModal />}
                    {selectedRowKeys.length > 0 ? <Button type="danger" onClick={allDelect}><Icon type="delete" />删除</Button> : ''}
                </div>
                <div className="byitemRightBottomHeadRight">
                    {dataSource.length} 个结果
                </div>
            </div>
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{ y: 600 }}
                rowSelection={rowSelection}
                loading={loading}
            />
        </div>

    )
}

export default ByitemRightBottom