import React from 'react';
import { from } from 'rxjs';
import './byitem.scss';
import withStates from 'src/context/withStates';
import Api from 'api';
import * as r from 'ramda';
import { categoryFormat, deviceTypeFormat } from 'src/components/typeCast/typeCast.js'
import ByitemTree from './byitemTree/byitemTree'
import ByitemRight from './byitemRight/byitemRight'
import {extractDevices} from "../../context/society";
const ROOT_NODE = '0';
const mapIndexed = r.addIndex(r.map);
const Byitem = () => (
  <div className="byitem">
    <ByitemTree />
    <ByitemRight />
  </div>
)



const channelFormat = v => {
  const channelMap = {
    '01': "正向有功",
    '09': "总冷量",
    '10': "总热量",
    '11': "累计流量",
    '12': "冷量系数",
    '13': "热量系数",
    '15': "冷水刻度",
    '16': "热水刻度",
  }
  return r.propOr(false)(v)(channelMap)
}

const nodeUsage = async ({ node = ROOT_NODE, search = '', selectTableType, nodeId }) => {
  const { data } = await Api.get(`/api/v3/project/${Api.projectId}/subentry`)
  const deviceData = (value) => r.flatten(r.map((k) => k.children ? deviceData(k.children) : k)(value))

  const toChildren = (value, key) => r.unnest(mapIndexed(([x, y], i) => {
    const arr = [];
    arr.push({
      index: i + 1 < 10 ? `0${i + 1}` : i + 1,
      title: x,
      key: `${key}-${i}`,
      area: y.area || 0,
      person: y.person || 0,
      id: y.id,
      children: y.devices ? r.map(r.pipe(x => ({ ...x, children: '', key: `${x.key}` })))(y.devices) : toChildren(y, `${key}-${i}`)
    })
    return arr
  })(r.toPairs(r.filter(n => r.is(Object, n))(value))))

  const treeData = [
    {
      title: Api.projectName,
      key: ROOT_NODE,
      children: toChildren(data, ROOT_NODE),
    },
  ];

  const nodeData = r.path(r.flatten(r.map(v => [v, 'children'])(node.split('-'))))(treeData);
  const tableData = {
    device: mapIndexed((v, i) => ({
      key: v.key,
      index: i + 1 < 10 ? "0" + (i + 1) : (i + 1),
      title: v.title,
      id: (v.sid).slice(12, 24),
      category: categoryFormat(v.coding),
      channel: channelFormat(v.channel.slice(-2)),
      type: deviceTypeFormat(v.type),
      did: v.sid,
      removeid: v.id,
      information: v
    }))(r.uniqWith(r.eqBy(r.prop('key')))(deviceData(nodeData))),
    node: nodeData
  }

  const inSearch = r.innerJoin(
    (x, y) => {
      const re = new RegExp(y)
      return re.test(x.title) || re.test(x.category) || re.test(x.channel) || re.test(x.key) || re.test(x.type)
    }
  )(r.propOr([])(selectTableType)(tableData))([search])
  return {
    loading: false,
    dataSource: inSearch,
    devices: extractDevices(data, ROOT_NODE),
    searchIsShow: false,
    node: node,
    tree: treeData,
    nodeId
  }
}

const loadByitem = (state) => {
  const input = Promise.all(
    [nodeUsage(state)])
    .then(r.mergeAll)
  return from(input)
}

export const loadNodeUsage = (state) => {
  return from(nodeUsage(state)
    .then(res => ({ ...state, ...res })))
}
const initState = {
  loading: true,
  node: ROOT_NODE,
  tree: [],
  dataSource: [],
  selectTableType: 'device'
}

export const { context, Component } = withStates(
  Byitem,
  initState,
  loadByitem,
)

export default Component
