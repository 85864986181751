import React, { useContext, useState } from 'react';
import * as r from 'ramda';
import fileDownload from 'js-file-download';
import { context, loadNodeStructure } from '../../buildingStructure'
import { Table, Button, Icon, message, Modal, Tooltip, Upload } from 'antd';
import { of } from 'rxjs';
import Api from 'api';
import Excel from 'src/components/excel/excel'
import Delete from '../../buildingStructureDelete/buildingStructureDelete';
import ByitemModal from '../../buildingStructureModal/buildingStructureModal';
import EditableCell, { EditableFormRow } from 'src/components/table/tableEdit/editbleCell';
import Management from 'src/components/modal/management/management'
import { handleTitleChange } from "src/context/formula";

const components = {
    body: {
        row: EditableFormRow,
        cell: EditableCell,
    },
};

const { confirm } = Modal;
const columnsA = dispatch => [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: '90px'
    },
    {
        title: '设备名称',
        dataIndex: 'title',
        key: 'title',
        width: '220px',
        render: (text) => {
            return text.length > 16 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
                width: '200px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip > : <span>{text}</span>
        },
        onCell: record => ({
            record,
            editable: true,
            dataIndex: 'title',
            title: '设备名称',
            handleSave: handleTitleChange(dispatch, loadNodeStructure),
        }),
    },
    {
        title: '设备ID',
        dataIndex: 'id',
        key: 'id',
        width: '240px'
    },
    {
        title: '设备类型',
        dataIndex: 'type',
        key: 'type',
        width: '240px'
    },
    {
        title: '倍率',
        dataIndex: 'comi',
        key: 'comi',
        width: '190px',
        onCell: record => ({
            record,
            editable: true,
            dataIndex: 'comi',
            title: '倍率',
            handleSave: handleComiChange(dispatch),
        }),
    },
    {
        title: '能耗类型',
        dataIndex: 'category',
        key: 'category',
        width: '200px'
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
            return <div><Delete record={record} />
            <Management  record={record} dispatch={dispatch}/>
            </div>
        }
    }, {
        title: '信息',
        dataIndex: 'information',
        key: 'information',
        className: 'hide'
    }
];

const columnsB = dispatch => [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: '90px'
    },
    {
        title: '设备名称',
        dataIndex: 'title',
        key: 'title',
        width: '270px',
        render: (text) => {
            return text.length > 16 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
                width: '200px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip > : <span>{text}</span>
        },
        onCell: record => ({
            record,
            editable: true,
            dataIndex: 'title',
            title: '设备名称',
            handleSave: handleTitleChange(dispatch, loadNodeStructure),
        }),
    },
    {
        title: '面积',
        dataIndex: 'area',
        key: 'area',
        width: '270px'
    }, {
        title: '人数',
        dataIndex: 'person',
        key: 'person',
        width: '270px'
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
            return <div><Delete record={record} /></div>
        }
    }, {
        title: '信息',
        dataIndex: 'information',
        key: 'information',
        className: 'hide'
    }
];

const handleComiChange = dispatch => row => {
    const { information: { id, deviceId, comi: oldValue }, comi } = row;
    if (oldValue === comi) {
        return;
    }
    dispatch(r.pipe(r.assoc('loading', true), of))
    Api.put(`/project/${Api.projectId}/node/${id}/device/${deviceId}`, {
        comi
    }).then(
        () => {
            message.success('修改成功')
            return dispatch(loadNodeStructure)
        },
        () => {
            message.error('修改失败')
            dispatch(r.pipe(r.assoc('loading', false), of))
        }
    )

}

const BuildingStructureRightBottom = () => {
    const { state: { dataSource, loading, selectTableType, node, buildingid }, dispatch } = useContext(context)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows)
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const allDelete = () => {
        confirm({
            title: '您确定要删除该仪表吗！',
            onOk() {
                return (
                    selectedRows.forEach((k, i) => {
                        Api.delete(`/project/${Api.projectId}/node/${r.path(['information', 'id'])(k)}`).then(() => {
                            message.info('删除成功');
                            setSelectedRows([]);
                            setSelectedRowKeys([]);
                            dispatch(state => of({ ...state, loading: true }));
                            return dispatch(state => loadNodeStructure({ ...state, loading: true }))
                        }).catch(e => message.info(`删除失败`))
                    })

                )
            },
            onCancel() { },
        });
    }

    const uploadProps = {
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        showUploadList: false,
        customRequest: (info, a, b) => {
            let fileList = info.file;
            let formdata = new FormData()
            formdata.append('project', Api.projectId)
            formdata.append('projectid', Api.projectId)
            formdata.append('buildingid', buildingid)
            formdata.append('sensors', fileList)

            Api.post('/import/equipments',
                formdata
            ).then(({ data }) => {
                console.log(data)
                if (data.code === 0) {
                    message.success(`文件上传成功.`);
                } else {
                    message.error(`文件上传失败.`);
                    message.error(data.message);
                }
            })
        },
    }
    const exportFn = () => {
        Api.post('/export/equipments', {
            projectid: Api.projectId
        }).then(res => {
            const dataPath = r.pathOr('')(['data', 'result', 'fn'])(res)
            if (!dataPath) {
                message.error('请求失败')
                return
            }
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = `${process.env.REACT_APP_API_HOST}/docs/${dataPath}`;
            tempLink.setAttribute('download', `${Api.projectName}设备管理表.xls`);

            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
        })
    }

    return (
        <div className="buildingStructureRightBottom">
            <div className='buildingStructureRightBottomHead'>
                <div className="buildingStructureRightBottomHeadLeft">
                    {node !== '0' ? <ByitemModal /> : ''}
                    {/* <Button onClick={selectNode}>面积/人数</Button>
                    <Button onClick={selectDevice}>设备/仪表</Button> */}
                    {selectedRowKeys.length > 0 ? <Button type="danger" onClick={allDelete}><Icon type="delete" />删除</Button> : ''}
                </div>
                <div className="buildingStructureRightBottomHeadRight">
                    {dataSource.length} 个结果
                    <Button onClick={exportFn}>导出</Button>
                    {/* <Button onClick={() => Excel(r.filter(n => n.title !== "操作" && n.title !== "信息", columnsA(dispatch)), dataSource)}>导出</Button> */}
                    <Upload {...uploadProps}>
                        <Button>导入</Button>
                    </Upload>
                </div>
            </div>
            <Table
                components={components}
                dataSource={dataSource}
                columns={selectTableType === 'device' ? columnsA(dispatch) : columnsB(dispatch)}
                // pagination={false}
                scroll={{ y: 600 }}
                rowSelection={rowSelection}
                loading={loading}
                childrenColumnName={''}
            />
        </div >

    )
}

export default BuildingStructureRightBottom