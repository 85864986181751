import React from 'react';
import { from } from 'rxjs';
import './buildingStructure.scss';
import withStates from 'src/context/withStates';
import Api from 'api';
import * as r from 'ramda'
import BuildingStructureTree from './buildingStructureTree/buildingStructureTree';
import BuildingStructureRight from './buildingStructureRight/buildingStructureRight';
import { formatTree } from '../../context/treeView';
import { extractDevices, searchFilter } from "src/context/society";

const ROOT_NODE = '0';

const BuildingStructure = () => (
  <div className="buildingStructure">
    <BuildingStructureTree />
    <BuildingStructureRight />
  </div>
)

const nodeStructure = async ({ node = ROOT_NODE, search = '' }) => {
  const { data } = await Api.get(`/api/v3/project/${Api.projectId}/nodes`);
  const { data: { result: { detail } } } = await Api.post('/building/info', { projectid: Api.projectId });
  // 修复unionWith 后 index 值不正常
  const fixIndex = r.addIndex(r.map)((v, i) => r.assoc('index')(`${i + 1}`.padStart(2, '0'))(v))(r.unionWith(
    r.eqBy(r.prop('did')))
    (searchFilter(extractDevices(data, node))([search])
    )([]))
  return {
    loading: false,
    buildingid: detail[0].id,
    dataSource: fixIndex,
    searchIsShow: false,
    node,
    tree: [
      {
        title: Api.projectName,
        key: ROOT_NODE,
        children: formatTree(ROOT_NODE)(data),
      },
    ],
    devices: extractDevices(data, ROOT_NODE)
  }
}

export const loadNodeStructure = (state) => {
  return from(nodeStructure(state)
    .then(res => ({ ...state, ...res })))
}

const initState = {
  loading: true,
  node: ROOT_NODE,
  tree: [],
  dataSource: [],
  selectTableType: 'device',
  devices: []
}

export const { context, Component } = withStates(
  BuildingStructure,
  initState,
  loadNodeStructure,
)

export default Component
