
import React from 'react';
import BuildingStructureAddNodeSearch from './buildingStructureAddNodeSearch/buildingStructureAddNodeSearch';
import BuildingStructureAddNodeTable from './buildingStructureAddNodeTable/buildingStructureAddNodeTable';
const BuildingStructureAddNodeRight = () => (
    <div className='buildingStructureAddNodeRight'>
        <BuildingStructureAddNodeSearch />
        <BuildingStructureAddNodeTable />
    </div>
)
export default BuildingStructureAddNodeRight
