import * as r from "ramda";
import cookie from "react-cookies";
import Api from 'api';

export const setPermission = permission =>  window.sessionStorage.setItem('permission', permission)
export const setRegionPermission = permission =>  window.sessionStorage.setItem('permission-region', permission)
export const setProjectName = name =>  window.sessionStorage.setItem('projectName', name)
export const setUid = uid =>  window.sessionStorage.setItem('uid', uid)
//isRoot 增加一个是否是区域账号的判断
// export const isRoot = () => (window.sessionStorage.getItem('permission') === 'ROOT')&&(!window.sessionStorage.getItem('permission-region') === 'REGION');
export const isRoot = () => window.sessionStorage.getItem('permission') === 'ROOT';
export const isRegion = () => window.sessionStorage.getItem('permission-region') === 'REGION';
//增加判断用户的列表
// export const isRegion = async () => {
//     return Api.get('/api/v3/accountRegion/'+window.sessionStorage.getItem('uid')).then(
//         data => {
//             if(data && data.length>0){
//                 return ``
//             }
//
//         }
//     );
// };

export const setCurrentProject = (id, title) => {
  const domain = window.location.hostname.replace(/^\w+\./, '')
  cookie.save('projectId', id, {path: '/', domain})
  cookie.save('projectName', title, {path: '/', domain})
  cookie.save('projectInfo', title, {path: '/', domain})
}

export const currentProject = projects => r.find(p => p._id === Api.projectId)(projects)

export const moduleNavigation = async () => {
    let flag = true;
    if( flag ){
        const [
            projects,
            regionProjects,
        ]=await Promise.all([Api.projectId ?
            Api.get('/api/v3/project')
                .then(r.propOr([], 'data')).then(data => {
                // super admin can access multiple projects
                setPermission('ROOT')
                setProjectName(cookie.load('uid'))
                setUid(cookie.load('uid'))
                return data
            }).catch(err => {
                // if(err.response.status !== 403) {
                //   return []
                // }
                return Api.projectId ? Api.get('/api/v3/projectRegion')//原先请求一个，这里只要不是root用户，还是把全部的项目返回
                    .then(r.prop('data')).then(x => {
                        setPermission('OTHER')
                        setProjectName(cookie.load('projectName'))
                        setUid(cookie.load('uid'))
                        return x
                    }) : []
            }) : [],
            Api.projectId ?
                Api.get('/api/v3/accountRegion/'+ cookie.load('uid'))
                    .then(r.propOr([], 'data')).then(data => {
                    // super admin can access multiple projects
                    if(data && data.length > 0){
                        setRegionPermission('REGION');
                        cookie.save('isRegion', true);
                    }
                    return data
                }) : []
        ])
        return [
            projects,
            regionProjects,
        ];
    }


  // 原先存在的一种方法
  return Api.projectId ?
    Api.get('/api/v3/project')
      .then(r.propOr([], 'data')).then(data => {
        // super admin can access multiple projects
        setPermission('ROOT')
        setProjectName(cookie.load('uid'))
        setUid(cookie.load('uid'))
        return data
    }).catch(err => {
      // if(err.response.status !== 403) {
      //   return []
      // }
      return Api.projectId ? Api.get('/api/v3/project/' + Api.projectId)
        .then(r.prop('data')).then(x => {
          setPermission('OTHER')
          setProjectName(cookie.load('projectName'))
          setUid(cookie.load('uid'))
          return [x]
        }) : []
    }) : []
}
/**
 * 获取区域项目列表
 * @returns {Promise<Array>}
 */
export const moduleRegionNavigation = async () => {
    // console.log('----------- region navigation');

    return Api.projectId ?
        Api.get('/api/v3/accountRegion/'+ Api.projectId)
            .then(r.propOr([], 'data')).then(data => {
            // super admin can access multiple projects
            setRegionPermission('REGION')
            return data
        }) : []
}
