import React from 'react';
import { Input, Button, } from 'antd';
import './projectHead.scss'
import {loadupdateData} from "../../account/account";
import {of} from "rxjs/index";
const { Search } = Input;


class ProjectHead extends React.Component {


    handleChange(value) {
        console.log(`selected ${value}`);
    }


    render() {
        // const onSearch = value => {
        //     dispatch(state => of({ ...state, loading: true, search: value }))
        //     return dispatch(state => loadupdateData({ ...state, loading: true, search: value }))
        // }

        return (
            <div className="indexHead">
                <ul>
                    <li>
                        <span>项目名称</span>
                        <Input placeholder="请输入" className='input' />
                    </li>
                    <li><Button type="primary">查询</Button></li>
                    <li>
                        {/*<Search*/}
                            {/*placeholder="input search text"*/}
                            {/*onSearch={onSearch}*/}
                            {/*style={{ width: 200 }}*/}
                        {/*/>*/}
                    </li>
                </ul>
            </div>
        )
    }

};


export default ProjectHead;