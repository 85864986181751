import React, {useState, useEffect, useContext} from 'react';

import {Table, Tree, Icon, Popconfirm, Switch, Button, Tooltip, message, Modal, Tag, Popover,Input} from 'antd';
import * as r from 'ramda';
import {Link} from 'react-router-dom'
import {of} from 'rxjs';
import Api from 'api';
import moment from 'moment';
import {context, loadManagerStates} from '../editMeter';
import {handleTitleChange} from "../../../context/formula";
import {loadNodeStructure} from "../../buildingStructure/buildingStructure";
import EditableCell, {EditableFormRow} from "../../../components/table/tableEdit/editbleCell";

const mapIndex = r.addIndex(r.map);
const {TreeNode} = Tree;
const confirm = Modal.confirm;
const components = {
    body: {
        row: EditableFormRow,
        cell: EditableCell,
    },
};


const columns = (dispatch) => [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: 'ID',
        dataIndex: '_id',
        key: '_id',
        className: 'hidden'
    },
    {
        title: '名称',
        dataIndex: 'title',
        key: 'title',
        render: (text) => {
            return text.length > 16 ? <Tooltip title={text} overlayStyle={{whiteSpace: 'nowrap'}}> <span style={{
                width: '200px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip> : <span>{text}</span>
        },
        width: '200px'
    }, {
        title: '采集开关',
        dataIndex: 'collectswtich',
        key: 'collectswtich',
        width: '200px',
        render: (text, record) => text==false?
            <div><span style={{color:'red'}}>关闭或未设置</span><a style={{fontSize: '12px'}}
                                                           onClick={() => switchStatus(record, dispatch)}>切换</a>
            </div>:( text&&text==true?
                <div><span style={{color:'green'}}>开启</span><a style={{fontSize: '12px'}}
                                                               onClick={() => switchStatus(record, dispatch)}>切换</a>
                </div>:<div><span  style={{color:'blue'}}>未设置(可用)</span><a style={{fontSize: '12px'}}
                                                                     onClick={() => switchStatus(record, dispatch)}>切换</a></div>)

        ,
    },
    // {
    //     title: '错误值标志',
    //     dataIndex: 'valuewrongflag',
    //     key: 'valuewrongflag',
    //     width: '200px',
    //     render: (text, record) => <div><span>{text}</span> <a style={{fontSize: '12px'}}
    //                                                           onClick={() => switchStatus(record, dispatch)}>切换</a>
    //     </div>,
    // },
    {
        title: '历史累加值',
        dataIndex: 'oldvalues',
        key: 'oldvalues',
        onCell: record => ({
            record,
            editable: true,
            dataIndex: 'oldvalues',
            title: '历史累加值',
            handleSave: handleSensorChange(dispatch,1),
        }),
        // render: (text, record) => text ? <Tag color="#87d068">正常</Tag> : <Tag color="#f50">异常</Tag>
    },
    {
        title: '基准数值',
        dataIndex: 'backvalue',
        key: 'backvalue',
        onCell: record => ({
            record,
            editable: true,
            dataIndex: 'backvalue',
            title: '基准数值',
            handleSave: handleSensorChange(dispatch,2),
        }),
    },
    {
        title: '实际数值',
        dataIndex: 'realdata',
        key: 'realdata',
    },
    {
        title: '上次数值',
        dataIndex: 'lasttotal',
        key: 'lasttotal',
    },
    {
        title: '最后通讯时间',
        dataIndex: 'lastupdate',
        key: 'lastupdate',
    },
    {
        title: '操作',
        key: 'operation',
        dataIndex: 'operation',
        render: (text, record) =>
            <div>
                <a style={{fontSize: '12px'}}
                   onClick={() => switchStatus(record, dispatch)}>确认修改</a>
                {/*<ManagerEdit record={record}/>*/}
                {/*<ManagerDelete record={record}/>*/}
            </div>
    },
];
const handleDelete = key => {
    const dataSource = [...this.state.dataSource];
    this.setState({dataSource: dataSource.filter(item => item.key !== key)});
};
// rowSelection objects indicates the need for row selection
const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
    },
};
const switchStatus = (record, dispatch) => {
    const {_id,collectswtich} = record;
    console.log('record');
    console.log(record);
    Api.postV3('/sensors/collectSwitch', {
        _id: _id,
        projectid: Api.projectId,
        project: Api.projectId,
        collectswtich: (collectswtich == true )? false : true
    }).then(() => {
            message.success('修改成功');
            dispatch(state => of({...state, loading: true}));
            return dispatch(state => loadManagerStates({...state, loading: true}))
        },
        () => {
            message.error('修改失败')
        })

}


const handleSensorChange = (dispatch,type) => row => {
    const {  _id, oldvalues, backvalue } = row;
    // console.log('row---'+type);
    // console.log(row);
    let params = {
        _id: _id,
        projectid: Api.projectId,
        project: Api.projectId,};
    if(type === 1 ){
        if(isNaN(Number(oldvalues))){
            message.error('历史累加值需要是数字！');
            return;
        }else{
            params.oldvalues=oldvalues;
        }

    }
    if(type === 2 ){
        if(isNaN(Number(backvalue))){
            message.error('基准值需要是数字！');
            return;
        }else{
            params.backvalue=backvalue;
        }

    }


    Api.postV3('/sensors/collectSwitch',
        params
    ).then(() => {
            message.success('修改成功');
            dispatch(state => of({...state, loading: true}));
            return dispatch(state => loadManagerStates({...state, loading: true}))
        },
        () => {
            message.error('修改失败')
        })

}




const Manager = () => {
    const {dispatch, state: {dataSource}} = useContext(context)
    const [isSwitchAll, setIsSwitchAll] = useState(true);
    const [opPws, setOpPws] = useState('');
    const [opCode, setOpCode] = useState('');

    useEffect(()=>{
        console.log('init operapwd --- ');
        getOperaCode();

    })
    const getOperaCode=()=>{//获取操作码
        if(opCode  ){
            console.log('exist,get createCode from state ---- ');
            return opCode;
        }else{
            Api.postV3('/getNormalOperaCode', {project:'123',operaType:'create'}).then(res=>{
                console.log('getNormalOperaCode --- ');
                // console.log(res);
                let data = res.data;
                if(data&& data.result ){
                    // console.log('getcontent');
                    // console.log(data.result[0].content);
                    let createArr = data.result;
                    r.map(y=>{
                        if(y.opera_name === 'createProject'){
                            setOpCode(y.content);
                        }
                    })(createArr);

                }else{
                    message.info('没有获取操作码CreateProjet',6);
                }
            });
        }
    }


    const switchAll = () => {


        Api.postV3('/sensors/collectSwitchAll', {
            // _id: _id,
            projectid: Api.projectId,
            project: Api.projectId,
            collectswtich: isSwitchAll
        }).then(() => {
                message.info('全部切换成：'+isSwitchAll?'开启':'关闭')
                setIsSwitchAll(!isSwitchAll);
                dispatch(state => of({...state, loading: true}))
                return dispatch(state => loadManagerStates({...state, loading: true}))
            },
            () => {
                message.error('修改失败')
            })

    }
    const addBaseValueAll = () => {
        confirm({
            title: '确定提交吗？提交之后无法再进行更改',
            content: ( <div>
                <div className=''>
                    <Input
                        placeholder='请输入创建密码:'
                        onChange={(e) => setOpPws(e.target.value )}
                    />
                </div>
            </div>),
            onOk() {
                if(opPws !== opCode){
                    message.info('密码错误');
                    return;
                }
                // message.info('密码正确');
                // return;
                return Api.postV3('/sensors/addBaseValueAll', {
                    projectid: Api.projectId,
                    project: Api.projectId,
                }).then(res=>{message.info('操作成功')
                    dispatch(state => of({...state, loading: true}))
                    return dispatch(state => loadManagerStates({...state, loading: true}))

                });
            },
            onCancel() {
                return;
            },
        })


    }
    const delStrctureAndByItem = () => {

        confirm({
            title: '确定提交吗？删除之后无法再进行更改',
            content: ( <div>
                <div className=''>
                    <Input
                        placeholder='请输入创建密码:'
                        onChange={(e) => setOpPws(e.target.value )}
                    />
                </div>
            </div>),
            onOk() {
                if(opPws !== opCode){
                    message.info('密码错误');
                    return;
                }



                return Api.postV3(`/project/${Api.projectId}/delNodes`, {
                    // _id: _id,
                    projectid: Api.projectId,
                    project: Api.projectId,
                }).then(() => {
                        message.info('删除完成')
                        dispatch(state => of({...state, loading: true}))
                        return dispatch(state => loadManagerStates({...state, loading: true}))
                    },
                    () => {
                        message.error('修改失败')
                    });
            },
            onCancel() {
                return;
            },
        })


    }
    const delFenxiang = () => {


        Api.postV3('/sensors/collectSwitchAll', {
            // _id: _id,
            projectid: Api.projectId,
            project: Api.projectId,
            collectswtich: isSwitchAll
        }).then(() => {
                message.info('全部切换成：'+isSwitchAll?'开启':'关闭')
                setIsSwitchAll(!isSwitchAll);
                dispatch(state => of({...state, loading: true}))
                return dispatch(state => loadManagerStates({...state, loading: true}))
            },
            () => {
                message.error('修改失败')
            })

    }
    const delmeters = () => {


        confirm({
            title: '确定提交吗？删除之后无法再进行更改',
            content: ( <div>
                <div className=''>
                    <Input
                        placeholder='请输入创建密码:'
                        onChange={(e) => setOpPws(e.target.value )}
                    />
                </div>
            </div>),
            onOk() {
                if(opPws !== opCode){
                    message.info('密码错误');
                    return;
                }



                return Api.postV3(`/project/${Api.projectId}/delMeters`, {
                    // _id: _id,
                    projectid: Api.projectId,
                    project: Api.projectId,
                }).then(() => {
                        message.info('删除完成')
                        dispatch(state => of({...state, loading: true}))
                        return dispatch(state => loadManagerStates({...state, loading: true}))
                    },
                    () => {
                        message.error('修改失败')
                    });
            },
            onCancel() {
                return;
            },
        })


    }
    const delAllEnergyData = () => {



        confirm({
            title: '确定提交吗？删除之后无法再进行更改',
            content: ( <div>
                <div className=''>
                    <Input
                        placeholder='请输入创建密码:'
                        onChange={(e) => setOpPws(e.target.value )}
                    />
                </div>
            </div>),
            onOk() {



                return Api.postV3(`/project/${Api.projectId}/delEnergyData`, {
                    // _id: _id,
                    projectid: Api.projectId,
                    project: Api.projectId,
                }).then(() => {
                        message.info('删除完成')
                        dispatch(state => of({...state, loading: true}))
                        return dispatch(state => loadManagerStates({...state, loading: true}))
                    },
                    () => {
                        message.error('修改失败')
                    });
            },
            onCancel() {
                return;
            },
        })


    }

    return (
        <div className="manager">
            <div>
                <Button  type="primary" onClick={switchAll}>开通统计通道</Button>
                &nbsp;
                <Button  type="primary" onClick={addBaseValueAll}>全部添加基准值</Button>
                &nbsp;
                <Button  type="primary" onClick={delStrctureAndByItem}>删除建筑结构和分项能耗</Button>
                &nbsp;
                {/*<Button  type="primary" onClick={delFenxiang}>删除分项能耗</Button>*/}
                &nbsp;
                <Button  type="primary" onClick={delmeters}>删除电表信息</Button>
                &nbsp;
                <Button  type="primary" onClick={delAllEnergyData}>删除该项目所有数据</Button>
            </div>
            <Table
                components={components}
                dataSource={dataSource} columns={columns(dispatch)} pagination={{defaultPageSize: 13}}/>
        </div>
    )
}

export default Manager;