import React from 'react';
import * as r from 'ramda';
import {from} from 'rxjs';
import './manager.scss';
import withStates from 'src/context/withStates';
import Api from 'api';
import ManagerTable from './managerTable/managerTable';
import moment from 'moment';

const mapIndex = r.addIndex(r.map);

const Manager = () => (
    <div className="manager">
        <ManagerTable/>
    </div>
)

const managerStates = async ({}) => {
    const {data: {result: {detail = []}}} = await Api.post('/collector/info', {
        project: Api.projectId,
        pagesize: 9999
    });

    return {
        loading: false,
        dataSource: mapIndex((v, i) => ({
            key: i,
            index: i + 1,
            title: v.title,
            tag: v.tag,
            isconnect: v.isconnect,
            status: v.status === 'DEBUG' ? '调试中' : '运行中',
            lastupdate: moment(v.lastupdate * 1000).format('YYYY-MM-DD HH:mm:ss'),
            ip: v.ip,
            software: v.software,
            information: v
        }))(detail),
    }
}

export const loadManagerStates = (state) => {
    return from(managerStates(state)
        .then(res => ({...state, ...res})))
}

const initState = {
    loading: true,
    dataSource: [],
}

export const {context, Component} = withStates(
    Manager,
    initState,
    loadManagerStates,
)

export default Component
