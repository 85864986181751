import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { context } from '../../departmentStateAddNode'
import { Table, Button, Icon } from 'antd';
import Api from 'api';
import Delete from '../../departmentStateAddNodeDelete/departmentStateAddNodeDelete';
import DepartmentStateAddNodeModal from '../../departmentStateAddNodeModal/departmentStateAddNodeModal'
import {handleTitleChange} from "src/context/formula";
import EditableCell, { EditableFormRow } from 'src/components/table/tableEdit/editbleCell';
import {loadDepartmentStateAddNode} from "src/modules/departmentStateAddNode/departmentStateAddNode";
import * as r from "ramda";
import BuildingStateAddNodeModal
    from "src/modules/buildingStateAddNode/buildingStateAddNodeModal/buildingStateAddNodeModal";

const components = {
    body: {
        row: EditableFormRow,
        cell: EditableCell,
    },
};

const columns = dispatch => [

    {
        title: '节点名称',
        dataIndex: 'title',
        key: 'title',
        width: '33%',
        onCell: record => ({
            record,
            editable: true,
            dataIndex: 'title',
            title: '节点名称',
            handleSave: handleTitleChange(dispatch, loadDepartmentStateAddNode),
        }),
    },
    {
        title: '子节点数量',
        dataIndex: 'count',
        key: 'count',
        width: '33%'
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
            return (<div>
                { r.includes(',', record.path) ? <> </> : <DepartmentStateAddNodeModal props={record} size={'small'} /> }
                <Delete record={record} />
            </div>)
        },
        width: '33%'
    }, {
        title: '信息',
        dataIndex: 'information',
        key: 'information',
        className: 'hide'
    }
];

const DepartmentStateAddNodeTable = () => {
    const { state: { tree, node, loading }, dispatch } = useContext(context)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = selectedRowKeys => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        // this.setState({ selectedRowKeys });
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <div className="departmentStateAddNodeTable">
            <div className='departmentStateAddNodeTableHead'>
                <div className="departmentStateAddNodeTableHeadLeft">
                    <DepartmentStateAddNodeModal props={{ id: '0', title: Api.projectName }} />
                    <Link to='/DepartmentState'>
                        <Button>
                            <Icon type="rollback" />
                            返回
                        </Button>
                    </Link>
                    {/* {selectedRowKeys.length > 0 ? <Button type="danger">删除</Button> : ''} */}
                </div>
                <div className="departmentStateAddNodeTableHeadRight">
                    {tree.length}
                </div>
            </div>
            <Table
                dataSource={tree}
                components={components}
                columns={columns(dispatch)}
                pagination={false}
                scroll={{ y: 600 }}
                rowSelection={rowSelection}
                loading={loading}
                defaultExpandedRowKeys={[node]}
            />
        </div>

    )
}

export default DepartmentStateAddNodeTable