import React, { useContext } from 'react';
import { Icon, Table } from 'antd'
import { Link } from "react-router-dom";
import Api from 'api';
import './billingService.scss'

import { from } from 'rxjs'
import withStates from 'src/context/withStates'
import * as r from 'ramda'
import IndexHead from './billingServiceHead/billingServiceHead';
import Management from './billingServiceManagement/billingServiceManagement';
import Delete from './billingServiceDelete/billingServiceDelete';
const mapIndexed = r.addIndex(r.map);

const columns = [{
  title: '序号',
  dataIndex: 'index',
  key: 'index',
}, {
  title: '策略组名',
  dataIndex: 'name',
  key: 'name',
}, {
  title: '单价',
  dataIndex: 'fixprice',
  key: 'fixprice',
}, {
  title: '关联仪表',
  dataIndex: 'equipments',
  key: 'equipments',
}, {
  title: '操作',
  dataIndex: 'action',
  key: 'action',
  render: (text, record) => {
    return <div>
      <Management record={record} />
      <Delete record={record} /></div>
  }
}, {
  title: 'allData',
  dataIndex: 'allData',
  key: 'allData',
  className: 'hide'
}];



const BillingService = () => {
  const { state: { data, loading } } = useContext(context);
  return (
    <div className="index">
      {/* <IndexHead /> */}
      <p className='addNewProject'>
        <Link to='/NewBillingService'>
          <Icon type="plus" style={{ fontSize: '16px', color: '#8B93A7', padding: '7px' }} />新建计费策略
          </Link>
        <span>{data.length}个结果</span>
      </p>
      <Table columns={columns} dataSource={data} className='table' loading={loading} />
    </div>
  )
}


const tableData = async () => {
  const { data: { result } } = await Api.post('/billingservice/info', { project: Api.projectId });

  const tableData = mapIndexed((k, i) => (
    {
      key: i,
      index: i + 1 < 10 ? '0' + (i + 1) : i + 1,
      name: k.title,
      fixprice: k.rules.fixprice,
      equipments: k.equipments,
      allData: k
    }
  ))(r.flatten([result]))

  return {
    loading: false,
    data: tableData,
  }
}

const updateData = async () => {
  return ''
}

const loadBillingService = (state) => {
  const input = tableData(state)
    .then(r.mergeAll)
  return from(input)
}

export const loadupdateData = (state) => {
  return from(tableData(state)
    .then(res => ({ ...state, ...res })))
}
const initState = {
  loading: true,
  data: []
}

export const { context, Component } = withStates(
  BillingService,
  initState,
  loadBillingService,
)

export default Component