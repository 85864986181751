import React, { useContext } from 'react';
import { Table, } from 'antd'
import Api from 'api';

import { from } from 'rxjs'
import withStates from 'src/context/withStates'
import * as r from 'ramda'
import './building.scss';

import AddNewBuilding from './addNewBuilding/addNewBuilding';
import BuildingDelete from './buildingDelete/buildingDelete';
import BillingEdit from './billingEdit/billingEdit';

const columns = [{
    title: '序号',
    dataIndex: 'index',
    key: 'index',
}, {
    title: '建筑编码',
    dataIndex: 'code',
    key: 'code',
}, {
    title: '建筑名称',
    dataIndex: 'buildingName',
    key: 'buildingName',
}, {
    title: '面积（平方）',
    dataIndex: 'area',
    key: 'area',

}, {
    title: '人数',
    dataIndex: 'headCount',
    key: 'headCount',

}, {
    title: '描述',
    dataIndex: 'note',
    key: 'note',
}, {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    render: (text, record) => <div>
        <BillingEdit record={record} />
        <BuildingDelete record={record} />
    </div>
}, {
    title: '信息',
    dataIndex: 'detail',
    key: 'detail',
    className: 'hide'
}];


const Building = () => {
    const { state: { data, loading } } = useContext(context);
    return (
        <div className="building">
            <div className='addNewBuilding'>
                <AddNewBuilding />
                <span>{data.length} 个结果</span>
            </div>
            <Table columns={columns} dataSource={data} className='table' loading={loading} />
        </div>
    )
}


const tableData = async ({ loading }) => {
    const { data: { result: { detail } } } = await Api.post('/building/info', { projectid: Api.projectId });

    const tableData = detail.map((k, i) => (
        {
            key: i,
            index: `${i + 1}`.padStart(2, '0'),
            code: k.id,
            buildingName: k.title,
            area: k.acreage,
            headCount: k.headCount,
            note: k.description,
            detail: k
        }
    ))

    return {
        loading: false,
        data: tableData,
    }
}


const loadBuilding = (state) => {
    const input = tableData(state)
        .then(r.mergeAll)
    return from(input)
}

export const loadTableData = (state) => {
    return from(tableData(state)
        .then(res => ({ ...state, ...res })))
}
const initState = {
    loading: false,
    data: []
}

export const { context, Component } = withStates(
    Building,
    initState,
    loadBuilding,
)

export default Component
