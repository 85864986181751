import React, { useState, useEffect } from 'react';
import {
  Form, Input, Select, Row, Col, Button, DatePicker, message, InputNumber
} from 'antd';
import { Link } from "react-router-dom";
import Api from 'api';
import * as r from 'ramda';
import moment from 'moment';
import './newShops.scss';
import NewBillingServiceModal from './newShops/newShops';

const { Option } = Select;


const NewShopsFrom = (props) => {
  const [fixpriceArr, setFixpriceArr] = useState({ addId: [], removeId: [] });
  const [defaultData, setDefaultData] = useState({});
  const billingServicetId = r.pathOr('')(['location', 'state', 'id'])(props);
  useEffect(() => {
    if (billingServicetId) {
      Api.post('/billingservice/info', {
        id: billingServicetId
      }).then(res => {
        const { data: { result: { title, equipments, id, rules: { fixprice } } } } = res;
        setDefaultData({
          title, equipments, fixprice, id
        })
      })
    }

  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err, values) => {
      let res;
      if (!err) {
        const { fixprice, title } = values
        if (r.isEmpty(defaultData)) {
          res = await Api.post('/billingservice/add', {
            title,
            projectid: Api.projectId,
            channels: fixpriceArr.addId,
            rules: { fixprice },
          })
        } else {
          res = await Api.post('/billingservice/update', {
            title,
            projectid: Api.projectId,
            addChannels: fixpriceArr.addId,
            removeChannels: fixpriceArr.removeId,
            rules: { fixprice },
            id: defaultData.id
          })
        }

        const msg = res.data.message === '' ? '成功' : res.data.message;
        message.info(msg);
        if (msg === '成功') {
          props.history.push('/Shops')
        }
      }
    });
  }

  const { getFieldDecorator } = props.form;
  const formItemLayout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 12,
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      span: 24,
      // offset: 0,
    },
  };

  const getFixpriceArr = (arr) => {
    setFixpriceArr(arr)
  }
  return (
    <div className="newProject">
      <Form onSubmit={handleSubmit} className='newProjectForm'>
        <Row>
          <Col span={10}>
            <Form.Item {...formItemLayout}
              label="商户名称"
            >
              {getFieldDecorator('title', {
                rules: [{
                  required: true, message: '商户名称不能为空',
                }]
              })(
                <Input placeholder="请输入商户名称" />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}
              label='登录帐号'
            >
              {getFieldDecorator('account', {
                rules: [{
                  required: true, message: '登录帐号不能为空',
                }]
              })(
                <Input placeholder="请输入登录帐号" />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}
              label='登录密码'
            >
              {getFieldDecorator('password', {
                initialValue: defaultData.fixprice,
                rules: [{
                  required: true, message: '登录密码不能为空',
                }]
              })(
                <Input placeholder="请输入登录密码" />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}
              label='确认密码'
            >
              {getFieldDecorator('confirmPassword', {
                rules: [{
                  required: true, message: '确认密码不能为空',
                }]
              })(
                <Input placeholder="请输入确认密码" />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}
              label='报警阈值'
            >
              {getFieldDecorator('alerthreshold', {
                rules: [{
                  required: true, message: '报警阈值不能为空',
                }]
              })(
                <Input placeholder="请输入报警阈值" />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}
              label='关联仪表'
            >
              {getFieldDecorator('fixpriceArr', {
                initialValue: defaultData.equipments,
                rules: [
                  {
                    message: '请选择计费仪表',
                    validator: (rule, value, cb) => {
                      return value > 0 ? cb() : cb(true)
                    },
                  },
                ],
              })(
                <div><InputNumber value={fixpriceArr.addId.length || defaultData.equipments || 0} disabled formatter={value => `${value}个`} /><NewBillingServiceModal getFixpriceArr={getFixpriceArr} defaultId={defaultData.id} /></div>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}
              label='手机'
            >
              {getFieldDecorator('mobile')(
                <Input placeholder="请输入手机号码" />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}
              label='描述'
            >
              {getFieldDecorator('description')(
                <Input.TextArea placeholder="请输入描述内容" />)}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" className='newProjectFormButton'>确认</Button>
              <Button className='newProjectFormButton' ><Link to='/Shops' >取消</Link></Button>
            </Form.Item>

          </Col>
        </Row>

      </Form>
    </div>
  );
}

const NewShops = Form.create({ name: 'register' })(NewShopsFrom);


export default NewShops;