import React, { useState, useContext } from 'react';
import { Modal, message, Row, Col, Input, Select, Form } from 'antd';
import { of } from 'rxjs';
import * as r from 'ramda';
import { context, loadupdateData } from '../account';

import Api from 'src/api';

const confirm = Modal.confirm;
const { Option } = Select

const pattern = /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?\(\)]).*$/;
const Edit = (props) => {
    const { dispatch } = useContext(context);
    const [visible, setVisible] = useState(false);
    const [forRef, setForRef] = useState();
    const [modalProps, setModalProps] = useState({ title: '', user: '', mobile: '', permission: '' })
    const showModal = () => {
        const { all: { user } } = props.record;

        Api.post('/account/info', {
            uid: user
        }).then(({ data: { result: { title, user, mobile, character: { id } } } }) => {
            setModalProps({
                title, user, mobile, permission: id
            })
        })
        setVisible(true)
    };
    const handleCancel = () => {
        setVisible(false)
    };
    const handleCreate = () => {
        const { form } = forRef.props;
        form.validateFields(async (err, values) => {
            const {
                id,
                password,
                permission,
                tel,
                title,
            } = values
            if (err) {
                return;
            }
            if(password&&!pattern.test(password)){
                alert('密码强度弱,需8位以上。包含数组，大小写字母，特殊字符！');
                return;
            }

            const res = await Api.post('/account/update', {
                character: permission,
                mobile: tel,
                title: id,
                _id: title,
                passwd: password
            })
            const msg = res.data.message === '' ? '修改成功' : res.data.message;
            message.info(msg);
            if (msg === '修改成功') {
                form.resetFields();
                setVisible(false)
                dispatch(state => of({ ...state, loading: true,changePwd:true }))
                return dispatch((state) => loadupdateData({ ...state, loading: true ,changePwd:true}))
            }

        });
    };
    const saveFormRef = formRef => {
        setForRef(formRef)
    };
    const userPermission = {
        ROOT: "超级管理员",
        TECHSUPPORT: "技术支持",
        APPID: "用于第三方接口调用",
        ADMIN: "管理员",
        TENEMENTADMIN: "管理员",
        TENEMENTENGINEER: "物业工程",
        TENEMENTFINANCE: "物业财务",
        USER: "用户"
    }
    const grid = {
        span: 4,
        offset: 1
    }
    const grid2 = {
        span: 16,
        offset: 1
    }
    return (
        <div>
            <span onClick={showModal} style={{ color: '#2196f3', cursor: 'pointer' }}>
                {props.name ? props.name : '编辑'}
            </span>
            <AccountEdit
                wrappedComponentRef={saveFormRef}
                visible={visible}
                onCancel={handleCancel}
                onCreate={handleCreate}
                record={modalProps}
            />
        </div>

    );
}

class AccountEditFrom extends React.Component {
    state = {
        title: '',
        _id: '',
        mobile: ''
    }

    render() {
        const { visible, onCancel, onCreate, form, record } = this.props;
        const { mobile, permission, title, user } = record;
        const { getFieldDecorator, getFieldValue } = form;
        // const { title, _id, mobile } = this.state

        const formItemLayout = {
            labelCol: {
                span: 5,
            },
            wrapperCol: {
                span: 12,
            },
        };
        const userPermission = {
            ROOT: "超级管理员",
            TECHSUPPORT: "技术支持",
            APPID: "用于第三方接口调用",
            ADMIN: "管理员",
            TENEMENTADMIN: "管理员",
            TENEMENTENGINEER: "物业工程",
            TENEMENTFINANCE: "物业财务",
            USER: "用户"
        }
        const handleChange = () => {

        }

        return (
            <Modal
                title="编辑账号"
                visible={visible}
                onCancel={onCancel}
                onOk={onCreate}
                destroyOnClose
            >
                <Form className='newProjectForm'>
                    <Form.Item {...formItemLayout}
                        label="用户账号"
                    >
                        {getFieldDecorator('title', {
                            initialValue: user,
                            rules: [{
                                required: true, message: '用户账号不能为空',
                            }
                            ],
                        })(
                            <Input placeholder="请输入用户账号" disabled />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="用户名称"
                    >
                        {getFieldDecorator('id', {
                            initialValue: title,
                            rules: [{
                                required: true, message: '用户名称不能为空',
                            }],
                        })(
                            <Input placeholder="请输入用户名称" />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="新密码"
                    >
                        {getFieldDecorator('password', {
                            rules: [
                                {
                                    min: 8, message: '用户密码不能少于8位',
                                },
                            ],
                        })(<Input.Password placeholder="请输入新的密码" />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="重复新密码"
                    >
                        {getFieldDecorator('confirm', {
                            rules: [
                                { required: getFieldValue('password'), message: '重复密码不能为空' },
                                {
                                    validator: (rule, value, callback) => {
                                        if (value && value !== getFieldValue('password')) {
                                            callback('两次输入不一致！')
                                        }
                                        callback()
                                    },
                                }],
                        })(
                            <Input.Password placeholder="请输入重复密码" type='number' />
                        )}
                    </Form.Item>
                    {
                        window.sessionStorage.getItem('permission') === 'ROOT' ?
                            <Form.Item {...formItemLayout}
                                label="用户权限"
                            >
                                {getFieldDecorator('permission', {
                                    initialValue: permission
                                })(
                                    <Select onChange={handleChange}>
                                        {
                                            r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(userPermission))
                                        }
                                    </Select>
                                )}
                            </Form.Item>
                            : ''
                    }

                    <Form.Item {...formItemLayout}
                        label="联系方式"
                    >
                        {getFieldDecorator('tel', {
                            initialValue: mobile,
                            rules: [{ required: true, message: '联系方式不能为空' }, {
                                pattern: /^([1][3,4,5,6,7,8,9])\d{9}$/, message: '请输入正确的手机号'
                            }]
                        })(
                            <Input placeholder="请输入联系方式" />
                        )}
                    </Form.Item>
                    {/* <Form.Item {...formItemLayout}
                    label="信息描述"
                >
                    {getFieldDecorator('description', {})(
                        <Input.TextArea />
                    )}
                </Form.Item> */}

                </Form>
            </Modal>
        );
    }
}
const AccountEdit = Form.create({ name: 'AccountEdit' })(AccountEditFrom);


export default Edit;