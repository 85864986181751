
import React from 'react';
import BuildingStructureRightTop from './buildingStructureRightTop/buildingStructureRightTop';
import ByitemRightBottom from './buildingStructureRightBottom/buildingStructureRightBottom';
const BuildingStructureRight = () => (
    <div className='buildingStructureRight'>
        <BuildingStructureRightTop />
        <ByitemRightBottom />
    </div>
)
export default BuildingStructureRight
