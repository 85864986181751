import React, { useContext } from 'react';
import { of } from 'rxjs';
import { Input } from 'antd';
import { context, loadDepartmentStateAddNode } from '../../departmentStateAddNode'
const { Search } = Input;

const DepartmentStateAddNodeSearch = () => {
    const { dispatch } = useContext(context);
    const onSearch = (value) => {
        dispatch(state => of({ ...state, loading: true }))
        return dispatch(state => loadDepartmentStateAddNode({
            ...state, loading: true,
            search: value
        }))
    }

    return (<div className="departmentStateAddNodeSearch">
        <div className="departmentStateAddNodeSearchContainer">
            <Search
                placeholder="input search text"
                onSearch={onSearch}
                style={{ width: 200 }}
            // onChange={onSearch}
            />
        </div>

    </div>)
}

export default DepartmentStateAddNodeSearch
