import React, { useContext, useState } from 'react';
import { Modal, message, Input } from 'antd';
import { context, loadUpdateData } from './reportAll';
import { of } from 'rxjs';
import * as r from 'ramda'
import Api from 'api';

const confirm = Modal.confirm;

const Delete = (props) => {
    const { id } = props.record;
    const { dispatch } = useContext(context)
    const [visible, setVisible] = useState(false);
    const [cPw, setCPw] = useState('');
    const [opCode, setOpCode] = useState('');

    // useEffect(()=>{
    //     console.log('init operapwd --- ');
    //     getOperaCode();
    //
    // })
    const getOperaCode=()=>{//获取操作码
        if(opCode  ){
            console.log('exist,get createCode from state ---- ');
            return opCode;
        }else{
            Api.postV3('/getNormalOperaCode', {project:'123',operaType:'create'}).then(res=>{
                console.log('getNormalOperaCode --- ');
                // console.log(res);
                let data = res.data;
                if(data&& data.result ){
                    // console.log('getcontent');
                    // console.log(data.result[0].content);
                    let createArr = data.result;
                    r.map(y=>{
                        if(y.opera_name === 'createProject'){
                            setOpCode(y.content);
                        }
                    })(createArr);

                }else{
                    message.info('没有获取操作码CreateProjet',6);
                }
            });
        }
    }


    const handleOk = () => {
        console.log(cPw)
        if (cPw === opCode) {
            Api.delete(`/project/${id}`).then(({ data }) => {
                message.info('删除成功');
                dispatch(state => of({ ...state, loading: true }))
                setVisible(false)
                return dispatch(state => loadUpdateData({
                    ...state, loading: true,
                }))
            }).catch(() => {
                message.info('请重试');
            })
        } else {
            message.error('密码错误');
        }
    }
    const handleCancel = () => {
        setVisible(false)
    }
    const showModal = () => {
        setVisible(true)
    };



    return (
        <div>
            <span onClick={showModal} style={{ color: '#FF0927' }}>
                删除
    </span>
            <Modal
                title="您确定要删除该项目吗！"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <p>删除该项目后所有历史数据将被清除请谨慎操作。</p>
                <Input.Password placeholder='请输入密码' onChange={(e) => setCPw(e.target.value)} />
            </Modal>
        </div>
    );
}


export default Delete;