import {Redirect} from "react-router-dom";
import React, {useContext} from "react";
import * as r from "ramda";
import {Spin} from "antd";
import {context, firstTopRoute, loadProjects} from "src/router";

const Home = () => {
  const {state: {thisProject}, dispatch} = useContext(context)
  if (r.isNil(thisProject)) {
    dispatch(loadProjects)
    return <Spin />
  }
  return <Redirect to={firstTopRoute(thisProject.modules)} />
}

export default Home