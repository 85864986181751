import React, { useContext,useState } from 'react';
import { Table, Button,Input, Icon } from 'antd';
// import Highlighter from 'react-highlight-words';
import { Link } from "react-router-dom";
import Api from 'api';
import './project.scss'

import moment from 'moment'
import { from } from 'rxjs'
import withStates from 'src/context/withStates'
import * as r from 'ramda'
import IndexHead from './projectHead/projectHead';
import Management from './management';
import Delete from './delete';
import Model from './model'
import {loadupdateData} from "../account/account";
import {of} from "rxjs/index";
// import {Input} from "antd/lib/index";

// const { Search } = Input;

const prj_width = 100;
const prj_width2 = 100;


const projectType = type => {
  const typeMap = {
    BASIC: "计量基础版",
    ANALYSIS: "能源分析版"
  }
  return r.propOr(type)(type)(typeMap)
}
const qualityGuaranteeState = state => {
  const stateMap = {
    normal: "质保期",
    extend: "维保期",
    expired: "逾期"
  }
  return r.propOr(state)(state)(stateMap)
}
const projectState = state => {
  const stateMap = {
      SPORT: '体育建筑',
      CBD: '综合建筑',
      HOSPITAL: '医疗卫生建筑',
      HOTEL: '宾馆饭店建筑',
      MARKET: '商场建筑',
      OFFICE: '办公建筑',
      TEACH: '文化教育建筑',
      OTHER: '其它建筑',

  }
  return r.propOr(state)(state)(stateMap)
}
const projectSource = source => {
  const sourceMap = {
    SELF: '自有',
    AGENT: '代理商',
    FACTORY: '厂家',
    INTEG: '集成商',
    RUNOP: '运营方',
    NMKT: '网络销售',

  }
  return r.propOr(source)(source)(sourceMap)
}


const Project = () => {

    const [searchText,setSearchText]=useState('');
    const [searchedColumn,setSearchedColumn]=useState('');
  // const { state: { data, loading } } = useContext(context);
    const { state: { data, loading }, dispatch } = useContext(context);
    const onSearch = value => {
        dispatch(state => of({ ...state, loading: true, search: value }))
        return dispatch(state => loadupdateData({ ...state, loading: true, search: value }))
    }




  //  自定义筛选的功能
     const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    // ref={node => {
                    //     this.searchInput = node;
                    // }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            (record[dataIndex]?record[dataIndex]:'')
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            // if (visible) {
            //     setTimeout(() => this.searchInput.select());
            // }
        },
        // render: text =>
        //     searchedColumn === dataIndex ? (
        //         <span
        //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //             // searchWords={[this.state.searchText]}
        //             searchWords={[searchText]}
        //             autoEscape
        //             textToHighlight={text.toString()}
        //         />
        //     ) : (//adfs
        //         text
        //     ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        // this.setState({
        //     searchText: selectedKeys[0],
        //     searchedColumn: dataIndex,
        // });
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
            // this.setState({ searchText: '' });
    };


    //  --e --



    const columns = [{
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width:50
    }, {
        title: '项目名称',
        dataIndex: 'name',
        key: 'name',

        ...getColumnSearchProps('name'),
        width:120
    }, {
        title: '创建时间',
        dataIndex: 'createTime',
        key: 'createTime',
        onFilter: (value, record) => record.createTime.includes(value),
        width:prj_width
    }, {
        title: '截止日期',
        dataIndex: 'stopTime',
        key: 'stopTime',
        onFilter: (value, record) => record.stopTime.includes(value),
        width:prj_width
    }, {
        title: '质保类型',
        dataIndex: 'type',
        key: 'type',
        width:prj_width
    }, {
        title: '质保状态',
        dataIndex: 'state',
        key: 'state',
        width:prj_width,
        render: (text) => {
            let classN = '';
            switch (text) {
                case '维保期':
                    classN = 'blue'
                    break;
                case '逾期':
                    classN = 'red'
                    break;
                case '质保期':
                    classN = 'green'
                    break;
                default:
                    classN = 'green'
                    break;
            }
            return <span className={classN}>&#183; {text}</span>
        }
    }, {
        title: '项目模式',
        dataIndex: 'model',
        key: 'model',
        width:prj_width,
        render: (text, record) => {
            return <Model record={record} />
        }
    }, {
        title: '管理器数量',
        dataIndex: 'gNub',
        key: 'gNub',
        width:prj_width
    }, {
        title: '仪表数量',
        dataIndex: 'yNub',
        key: 'yNub',
        width:prj_width
    }, {
        title: '项目属性',
        dataIndex: 'attribute',
        key: 'attribute',
        ...getColumnSearchProps('attribute'),
        width:prj_width
    }, {
        title: '项目来源',
        dataIndex: 'from',
        key: 'from',
        width:prj_width
    },  {
        title: '归属区域',
        dataIndex: 'region',
        key: 'region',
        width:prj_width2,
        ellipsis: true,
        className: 'hide',
        // colSpan:0
    },{
        title: '地理建筑编号',
        dataIndex: 'building',
        key: 'building',
        width:prj_width2,
        ellipsis: true,
        className: 'hide',
        // colSpan:0
    },{
        title: '项目经度',
        dataIndex: 'longitude2',
        key: 'longitude2',
        width:prj_width2,
        ellipsis: true,
        className: 'hide',
        // colSpan:0
    },{
        title: '仪表间隔判断',
        dataIndex: 'interval',
        key: 'interval',
        width:prj_width2,
        ellipsis: true,
        className: 'hide',
        // colSpan:0
    },{
        title: '项目纬度',
        dataIndex: 'latitude2',
        key: 'latitude2',
        width:prj_width2,
        ellipsis: true,
        className: 'hide',
        // colSpan:0
    },{
        title: '项目地址',
        dataIndex: 'locate',
        key: 'locate',
        width:prj_width2,
        ellipsis: true,
        className: 'hide',
        // colSpan:0
    },{
        title: '父节点编号',
        dataIndex: 'parent',
        key: 'parent',
        width:prj_width2,
        ellipsis: true,
        className: 'hide',
        // colSpan:0
    },{
        title: '路径编号',
        dataIndex: 'path',
        key: 'path',
        width:prj_width2,
        ellipsis: true,
        className: 'hide',
        // colSpan:0
    }, {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width:prj_width2,
        render: (text, record) => {
            return <div><Management record={record} /><Delete record={record} /></div>
        }
    },
        {
            title: '备注',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: '是否归入区域统计',
            dataIndex: 'isAnalyzeForRegion',
            key: 'isAnalyzeForRegion',
            ...getColumnSearchProps('isAnalyzeForRegion'),
            // className: 'hide',
        },
        {
            title: '区域统计自动监测',
            dataIndex: 'isPermitAuto',
            key: 'isPermitAuto',
            // className: 'hide',
            ...getColumnSearchProps('isPermitAuto'),
            render: (text) => {
                let classN = '';
                switch (text) {
                    case "1":
                        classN = 'green';
                        break;
                    case "0":
                        classN = 'blue';
                        break;
                    default:
                        classN = 'blue';
                        break;

                }
                return <span className={classN}>&#183; {(text==="1")?'是':((text==="0")?'否':'')}</span>
            }
        },
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            className: 'hide',
        }];
  //

  return (
    <div className="project">
      <IndexHead />
      <p className='addNewProject'>
        <Link to='/NewProject'>
          <Icon type="plus" style={{ fontSize: '16px', color: '#8B93A7', padding: '7px' }} />新建项目
          </Link>
        <span>{data.length}个结果</span>
          <ul>
              <li>
                  {/*<Search*/}
                      {/*placeholder="input search text"*/}
                      {/*onSearch={onSearch}*/}
                      {/*style={{ width: 200 }}*/}
                  {/*/>*/}
              </li>
          </ul>
      </p>
      <Table columns={columns} dataSource={data} className='table' loading={loading} />
    </div>
  )
}


const tableData = async () => {
  const { data: result } = await Api.get("/api/v3/project");
  let tableData;
  tableData = result.map((k, i) => (
    {
      key: i,
      index: `${i + 1}`.padStart(2, '0'),
      name: k.title,
      createTime: moment(k.timecreate * 1000).format('YYYY-MM-DD'),
      stopTime: moment(k.warranty.expireDate).format('YYYY-MM-DD'),
      type: projectType(k.type),
      state: qualityGuaranteeState(k.warranty.type),
      model: k.status,
      gNub: k.statistic.collectors,
      yNub: k.statistic.devices,
      attribute: projectState(k.sector),
      from: projectSource(k.source),
      id: k._id,
      description: k.description,
      modules: k.modules,
      originalType: k.type,
      interval: k.interval,
      //  'longitude2','latitude2','region','bu
        // ilding','parent','path',
      region:(k.projectLocate.region)?k.projectLocate.region:'',
      // region:(k.projectLocate.region)?regionJsonConverter[k.projectLocate.region]:'',
      building:k.projectLocate.building ,
      longitude2:k.projectLocate.longitude2,
      latitude2:k.projectLocate.latitude2,
        locate:k.projectLocate.locate,
      parent:k.regionTopology.parent,
      path:k.regionTopology.path,
        isAnalyzeForRegion:k.isAnalyzeForRegion,
        isPermitAuto:k.isPermitAuto?'1':'0',

    }
  ))

  return {
    loading: false,
    data: tableData,
  }
}


const loadProject = (state) => {
  const input = tableData(state)
    .then(r.mergeAll)
  return from(input)
}

export const loadUpdateData = (state) => {
  return from(tableData(state)
    .then(res => ({ ...state, ...res })))
}
const initState = {
  loading: true,
  data: [],
  updata: false,
  search: ''
}

export const { context, Component } = withStates(
  Project,
  initState,
  loadProject,
)

export default Component