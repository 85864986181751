import * as r from "ramda";
import {flattenDevices, flattenNodes, idAsKey} from "src/context/treeView";
import {channelsMap} from "src/context/typeCategory";
import {categoryFormat, channelFormat, deviceTypeFormat} from "src/components/typeCast/typeCast";
import {variablesOf} from "src/context/formula";

const ROOT_NODE = '0'
export const searchFilter = r.innerJoin(
  (x, term) => term ?
    r.any(r.applyTo(x))(r.map(
      r.propSatisfies(m => m && new RegExp(term).test(m)))(
      ['title', 'category', 'channel', 'key', 'type']
      )
    ) : true
)

const narrativeNodes = nodes => nodeId => nodeId === ROOT_NODE ?
  r.values(nodes) :
  [r.pathOr({})(nodeId.replace('0,', '').split(','))(nodes)];

export function extractDevices(data, node) {
  const nodes = idAsKey(data);
  const selectedNodes = r.chain(flattenNodes)(narrativeNodes(nodes)(node));

  const channelFilter = r.filter(x => r.propSatisfies(r.__, 'channel', x)(r.flip(r.includes)(r.keys(channelsMap))));
  const devicesUnderThisNode = r.chain(flattenDevices)(selectedNodes);
  // console.log(channelFilter(devicesUnderThisNode))
  return r.addIndex(r.map)((v, i) => ({
    key: `${v.id}_${v.channel}_${v.sid}`,
    index: `${i + 1}`.padStart(2, '0'),
    title: v.title,
    id: (v.sid).slice(12, 24),
    category: categoryFormat(v.coding),
    channel: channelFormat(v.channel.slice(-2)),
    type: deviceTypeFormat(v.type),
    area: variablesOf(v.formula).AREA,
    person: variablesOf(v.formula).PERSON,
    did: v.key,
    comi: v.comi,
    information: v,
  }))(r.uniqBy(v=>v.sid)( channelFilter(devicesUnderThisNode)));
}

export function extractNodes(data, node) {
  const nodes = idAsKey(data);
  const selectedNodes = r.chain(flattenNodes)(narrativeNodes(nodes)(node));

  return r.addIndex(r.map)((v, i) => ({
    key: v.id,
    index: `${i + 1}`.padStart(2, '0'),
    title: v.title,
    id: v.id,
    type: v.type,
    area: r.includes(v.title)(['水', '电']) ? '' : variablesOf(v.formula).AREA || 0,
    person: r.includes(v.title)(['水', '电']) ? '' : variablesOf(v.formula).PERSON || 0,
    information: v,
  }))(selectedNodes);
}