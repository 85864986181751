import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { of } from 'rxjs';
import { context, loadupdateData } from '../account';

import Api from 'src/api';

const confirm = Modal.confirm;

const Delete = (props) => {
    const { dispatch } = useContext(context);
    const { name } = props.record;
    const showConfirm = () => {

        confirm({
            title: '您确定要删除该账号吗！',
            onOk() {
                return (
                    Api.post('/account/delete', {
                        id: name
                    }).then(res => {
                        res.data.message === '' ? message.info('删除成功') : message.info(res.data.message)
                        dispatch(state => of({ ...state, loading: true }))
                        return dispatch(state => loadupdateData({ ...state, loading: true }))
                    }))
            },
            onCancel() { },
        });
    }
    return (
        <span onClick={showConfirm} style={{ color: '#FF0927', cursor: 'pointer' }}>
            删除
    </span>
    );
}


export default Delete;