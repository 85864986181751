import React, {useState, useEffect} from 'react';
import {Checkbox, Form, Input, Modal, Select, Tabs, message} from 'antd';
import * as r from 'ramda';


// import Information from './information/information';
// import Drive from './drive/drive';

import Api from 'src/api'
import './management.scss'
import {of} from "rxjs";
import {loadNodeStructure} from "../../../modules/buildingStructure/buildingStructure";

const TabPane = Tabs.TabPane;
const {Option} = Select;

class FromConatainer extends React.Component {

    state = {
        adaptdeviceArr: []
    };

    componentDidUpdate(prevProps) {
        const {driver, ext} = this.props.informationData;
        console.log(this.props)
        console.log(prevProps)

        if (this.props.informationData !== prevProps.informationData) {
            if (!r.isEmpty(ext) && driver) {
                Api.post('/control/driverinfo', {
                    driver: driver
                }).then(({data: {result}}) => r.isEmpty(result) ? this.setState({adaptdeviceArr: []}) : this.setState({adaptdeviceArr: r.values(result.adaptdevice)}))
            }
        }
    }


    render() {
        const {visible, onCancel, onOk, form, informationData, driverEnum} = this.props;
        const {addrid, areaid, comi, freq, did, reportable, title, driver, ext} = informationData;
        const {getFieldDecorator, getFieldValue} = form;
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };
        const formTailLayout = {
            wrapperCol: {span: 20, offset: 4},
        };
        const adaptDevice = () => {
            if (this.state.adaptdeviceArr.length) {
                return <Form.Item
                    {...formItemLayout}
                    label="仪表型号">
                    {
                        getFieldDecorator('code', {
                            initialValue: ext.adaptdevice
                        })(
                            <Select>
                                {r.map(v => <Option
                                    value={v.code}>{v.desc}</Option>)
                                (this.state.adaptdeviceArr)
                                }
                            </Select>)
                    }
                </Form.Item>
            }
        };
        const defaultSelectValue = (index) => {
            if (driver) {
                return driver.split('/')[index];
            }
        };


        const handleChange = (value) => {
            if (value) {
                Api.post('/control/driverinfo', {
                    driver: `${getFieldValue('brand')}/${getFieldValue('type')}/${value}`
                }).then(({data: {result}}) => r.isEmpty(result) ? this.setState({adaptdeviceArr: []}) : this.setState({adaptdeviceArr: r.values(result.adaptdevice)}))
            } else {
                this.setState({adaptdeviceArr: []})
            }

        };
        return (
            <Modal
                visible={visible}
                onOk={onOk}
                // confirmLoading={confirmLoading}
                onCancel={onCancel}
                destroyOnClose={true}
            >
                <Form className='components_form'>
                    <Tabs type="card">
                        <TabPane tab="信息" key="1">
                            <Form.Item
                                {...formItemLayout}
                                label="设备标识">
                                {getFieldDecorator('areaid', {
                                    initialValue: areaid
                                })(
                                    <Input disabled={true}/>
                                )}
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                label="仪表ID"
                            >
                                {getFieldDecorator('addrid', {
                                    initialValue: addrid
                                })(
                                    <Input disabled={true}/>
                                )}
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                label="uid"
                            >
                                {getFieldDecorator('did', {
                                    initialValue: did
                                })(
                                    <Input disabled={true}/>
                                )}
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                label="设备名称"
                            >
                                {getFieldDecorator('title', {
                                    initialValue: title
                                })(
                                    <Input/>
                                )}
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                label="更新频率"
                            >
                                {getFieldDecorator('freq', {
                                    initialValue: freq
                                })(
                                    <Input/>
                                )}
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                label="倍率"
                            >
                                {getFieldDecorator('comi', {
                                    initialValue: comi
                                })(
                                    <Input/>
                                )}
                            </Form.Item>
                            <Form.Item
                                {...formTailLayout}
                            >
                                {getFieldDecorator('reportable', {
                                    initialValue: reportable === 'no',
                                    valuePropName: 'checked',
                                })(
                                    <Checkbox>设为总表</Checkbox>
                                )}
                            </Form.Item>

                        </TabPane>
                        <TabPane tab="驱动" key="2">
                            <Form.Item
                                {...formItemLayout}
                                label="仪表品牌">
                                {
                                    getFieldDecorator('brand', {
                                        initialValue: defaultSelectValue(0)
                                    })(
                                        <Select>
                                            <Option value=''>请选择</Option>
                                            {
                                                r.isEmpty(driverEnum) ? '' : r.map(v => <Option
                                                    value={v.name}>{v.name}</Option>)(r.values(driverEnum))
                                            }
                                        </Select>
                                    )
                                }

                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                label='仪表类型'>
                                {
                                    getFieldDecorator('type', {
                                        initialValue: defaultSelectValue(1)
                                    })(
                                        <Select>
                                            <Option value=''>请选择</Option>
                                            {getFieldValue('brand') ? r.map(v => <Option
                                                value={v.name}>{v.name}</Option>)
                                            (r.values(r.pathOr({})([getFieldValue('brand'), 'driver'])(driverEnum))) : ''
                                            }
                                        </Select>)
                                }
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                label="仪表驱动">
                                {
                                    getFieldDecorator('drive', {
                                        initialValue: defaultSelectValue(2)
                                    })(
                                        <Select onChange={handleChange}>
                                            <Option value=''>请选择</Option>
                                            {getFieldValue('brand') ? r.map(v => <Option
                                                value={v.name}>{v.name}</Option>)
                                            (r.values(r.pathOr({})([getFieldValue('brand'), 'driver', getFieldValue('type'), 'driver'])(driverEnum))) : ''
                                            }
                                        </Select>)
                                }
                            </Form.Item>
                            {adaptDevice()}
                        </TabPane>
                    </Tabs>
                </Form>
            </Modal>
        );
    }
}

const CollectionCreateForm = Form.create({name: 'form_in_modal'})(FromConatainer);

const Management = (props) => {
    const [visible, setVisible] = useState(false);
    const [informationData, setInformationData] = useState({});
    const [driverEnum, setDriverEnum] = useState([{name: ''}]);
    const [formRef, setFormRef] = useState({});
    useEffect(() => {
        const {did} = props.record;
        if (visible) {
            Api.post('/sensor/info', {
                did: did,
                project: Api.projectId
            }).then(
                ({data: {result}}) => {
                    const {addrid, areaid, comi, freq, title, did, driver, ext} = result;
                    setInformationData({
                        addrid,
                        areaid,
                        comi,
                        reportable: result.channels[0].reportable,
                        freq,
                        title,
                        did: did,
                        driver,
                        ext,
                        channels: result.channels
                    })
                });
            Api.post('/driver/enum').then(({data: {result}}) => {
                setDriverEnum(result)
            })
        }
    }, [visible]);

    const showModal = () => {
        setVisible(true)
    }

    const handleOk = () => {
        const {getFieldsValue} = formRef.props.form;
        const {addrid, areaid, comi, did, code, title, brand, type, drive, reportable} = getFieldsValue();
        Api.post('/sensor/update', {
            projectid: Api.projectId,
            addrid,
            areaid,
            comi,
            did,
            driver: brand && type && drive ? `${brand}/${type}/${drive}` : '',
            ext: {adaptdevice: code},
            title: title,
            reportable: reportable ? 'no' : 'yes'
        }).then(res => {
            if (res.data.code === 0) {
                message.info('修改成功');
                setVisible(false)
                props.dispatch(state => of({...state, loading: true}))
                return props.dispatch(state => loadNodeStructure({...state, loading: true}))
            } else {
                message.info(res.data.message);
            }

        })
    };

    const handleCancel = () => {
        setVisible(false)
    };

    const saveFormRef = formRef => {
        setFormRef(formRef)
    };

    return (
        <div className='management'>
            <span onClick={showModal}>管理</span>
            <CollectionCreateForm
                wrappedComponentRef={saveFormRef}
                visible={visible}
                onCancel={handleCancel}
                onOk={handleOk}
                informationData={informationData}
                driverEnum={driverEnum}
            />
        </div>
    )
};
export default Management;