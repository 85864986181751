import React from 'react';
import { from } from 'rxjs';
import './departmentStateAddNode.scss';
import withStates from 'src/context/withStates';
import Api from 'api';
import * as r from 'ramda';
import DepartmentStateAddNodeRight from './departmentStateAddNodeRight/departmentStateAddNodeRight';
const ROOT_NODE = '0';

const DepartmentStateAddNode = () => (
  <div className="departmentStateAddNode">
    <DepartmentStateAddNodeRight />
  </div>
)

const treeViewData = async () => {
  const { data: { result = [] } } = await Api.post('/eca/info', {
    projectid: Api.projectId,
    type: "BYAPART"
  });

  const treeFn = v => r.map(({ title, id, count, path, child }) => {
    return { title, id, key: id, count, path, children: r.isEmpty(r.filter(r.where({ type: r.equals("BYAPART") }))(child)) && r.where({ type: r.equals("BYAPART") }) ? NaN : treeFn(r.values(child)) }
  })(r.filter(r.where({ type: r.equals("BYAPART") }))(v))

  return {
    searchIsShow: false,
    loading: false,
    node: ROOT_NODE,
    tree: [
      {
        title: Api.projectName,
        key: ROOT_NODE,
        id: ROOT_NODE,
        path: '',
        count: result.length,
        children: treeFn(result),
      },
    ],
  }
}


export const loadDepartmentStateAddNode = (state) => {
  return from(treeViewData(state)
    .then(res => ({ ...state, ...res })))
}

const initState = {
  loading: true,
  node: ROOT_NODE,
  tree: [],
  dataSource: []
}

export const { context, Component } = withStates(
  DepartmentStateAddNode,
  initState,
  loadDepartmentStateAddNode,
)

export default Component
