import React from 'react';
import Img_index from 'img/index.png';
import './index.scss';
import er1 from 'img/er1.jpg'
import er2 from 'img/er2.jpg'
const Index = () => (
  <div className="index">
    <div className={'indexLeft'}>
      <h1>物联网建筑云能源系统结构图</h1>
      <img src={Img_index} alt="" />
    </div>
    <div className={'indexRight'}>
      {/*<h1>欢迎登陆物联网建筑云能源系统</h1>*/}

      {/*<div className={'Qrcode'} >*/}
        {/*<img src={er2} alt="" />*/}
      {/*</div>*/}

      {/*<div className={'Qrcode'} >*/}
        {/*<img src={er2} alt="" />*/}
      {/*</div>*/}

    </div>
  </div>
)



export default Index;