import React from 'react';
import {Button, Checkbox, Col, DatePicker, Form, Input, message, Radio, Row, Select} from 'antd';
import {Link} from "react-router-dom";
import * as r from 'ramda';
import moment from 'moment';
import './newProject.scss'
import Api from 'api';
import Util2  from 'src/util/util2';
import {
    formItemLayout,
    groupFormItemLayout, joinAllModules,
    projectDefaultModules,
    projectModules,
    projectSource,
    projectType,
    rightFormItemLayout,
    rightRadioFormItemLayout,
    tailFormItemLayout
} from "src/context/project";

const {Option} = Select;
const {RangePicker} = DatePicker
const regionJsonConverter = Util2.regionJsonConverter;

const pattern = /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?\(\)]).*$/;
// console.log(regionJsonConverter['330301']);
class NewProjectFrom extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
    };



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            console.log(err)
            console.log(values)

            if (!err) {
                console.log('Received values of form: ', values);
                const {name: title, website: description, from: source,
                    state: sector, account: user, pw: passwd, phone: mobile,region:region,
                    longitude2:longitude2,address:locate,latitude2:latitude2,building:building,regionBelong,
                    category: type, time, timeRange, web, admin, analysis=[], extra=[]} = values;
                let regionName = regionJsonConverter[region];
                let regionBelongName = regionJsonConverter[regionBelong];
                let effectiveDays = time;
                if (time === "自定义") {
                    effectiveDays = timeRange[1].diff(timeRange[0], 'days')
                }
                const modules = joinAllModules(web, admin, analysis, extra)
                // console.log('创建项目的数据 ---- ');
                // console.log({
                //     title,
                //     description,
                //     sector,
                //     source,
                //     user,
                //     passwd,
                //     mobile,
                //     region,
                //     regionName,
                //     regionBelong,
                //     regionBelongName,
                //     locate,
                //     longitude2,
                //     latitude2,
                //     building,
                //     type,
                //     effectiveDays,
                //     modules
                // });
                // return;
                const res = await Api.post('/project/add', {
                    title,
                    description,
                    sector,
                    source,
                    user,
                    passwd,
                    mobile,
                    region,
                    regionName,
                    regionBelong,
                    regionBelongName,
                    locate,
                    longitude2,
                    latitude2,
                    building,
                    type,
                    effectiveDays,
                    modules
                })
                console.log(res.data.message)
                const msg = res.data.message === '' ? '创建成功' : res.data.message;
                message.info(msg);
                if (msg === '创建成功') {
                    this.props.history.push('/Project')
                }
            }
        });
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    }

    handleWebsiteChange = (value) => {
        let autoCompleteResult;
        if (!value) {
            autoCompleteResult = [];
        } else {
            autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
        }
        this.setState({autoCompleteResult});
    }

    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '86',
        })(
            <Select style={{width: 70}}>
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>
        );
        const disabledDate = (current) => {
            return current && current < moment().subtract(1, 'days');
        }
        console.log('modules', r.toPairs(r.propOr({})('web')(projectModules)))
        return (
            <div className="newProject">
                <Form onSubmit={this.handleSubmit} className='newProjectForm'>
                    <Row>
                        <Col span={10}>
                            <div className="">
                                <h4>创建项目</h4>
                                <Form.Item {...formItemLayout}
                                           label="项目名称"
                                >
                                    {getFieldDecorator('name', {
                                        rules: [{
                                            required: true, message: '项目名称不能为空',
                                        }],
                                    })(
                                        <Input placeholder="请输入项目名称"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="项目属性"
                                >
                                    {getFieldDecorator('state', {
                                        rules: [{
                                            required: true, message: '项目属性不能为空',
                                        }],
                                    })(
                                        <Select placeholder="请选择项目属性">
                                            {
                                                r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(projectType))
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="项目来源"
                                >
                                    {getFieldDecorator('from', {
                                        rules: [{
                                            required: true, message: '项目来源不能为空',
                                        }],
                                    })(
                                        <Select placeholder="请选择项目属性">
                                            {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(projectSource))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label='设置账号'
                                >
                                    {getFieldDecorator('account', {
                                        rules: [{required: true, message: '设置账号不能为空', whitespace: true}, {
                                            min: 6,
                                            message: '账号长度不能少于6位',
                                            whitespace: true
                                        }],
                                    })(
                                        <Input/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="账号密码"
                                >
                                    {getFieldDecorator('password', {
                                        // initialValue: ['zhejiang', 'hangzhou', 'xihu'],
                                        rules: [{required: true, message: '账号密码不能为空'}, {
                                            min: 8,
                                            message: '密码长度不能少于8位',
                                            whitespace: true
                                        }, {pattern: pattern, message: '密码为数字，大小写字母，特殊字符组合', whitespace: true}],
                                    })(
                                        <Input.Password/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="控制密码"
                                >
                                    {getFieldDecorator('pw', {
                                        // initialValue: ['zhejiang', 'hangzhou', 'xihu'],
                                        rules: [{required: true, message: '控制密码不能为空'}, {
                                            min: 6,
                                            message: '密码长度不能少于6位',
                                            whitespace: true
                                        }, {pattern: /^[0-9a-zA-Z]*$/, message: '控制密码只能为数字和字母', whitespace: true}],
                                    })(
                                        <Input.Password/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="联系方式"
                                >
                                    {getFieldDecorator('phone', {
                                        rules: [{required: true, message: '联系方式不能为空'}, {
                                            // 由于工信部放号段不定时，所以建议使用泛解析
                                            pattern: /^([1][3,4,5,6,7,8,9])\d{9}$/, message: '请输入正确的手机号'
                                        }],
                                    })(
                                        <Input addonBefore={prefixSelector} style={{width: '100%'}}/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="可查看区域"
                                >
                                    {getFieldDecorator('region', {
                                        initialValue: 0,
                                    })(
                                        <Select>
                                            <Option value={0}>不可查看</Option>
                                            <Option value={"330301"}>市辖区</Option>
                                            <Option value={"330302"}>鹿城区</Option>
                                            <Option value={"330303"}>龙湾区</Option>
                                            <Option value={"330304"}>瓯海区</Option>
                                            <Option value={"330305"}>洞头区</Option>
                                            <Option value={"330324"}>永嘉县</Option>
                                            <Option value={"330326"}>平阳县</Option>
                                            <Option value={"330327"}>苍南县</Option>
                                            <Option value={"330328"}>文成县</Option>
                                            <Option value={"330329"}>泰顺县</Option>
                                            <Option value={"330371"}>温州高新区(经开区)</Option>
                                            <Option value={"330381"}>瑞安市</Option>
                                            <Option value={"330382"}>乐清市</Option>
                                            <Option value={"330354"}>温州生态园</Option>
                                            <Option value={"330355"}>瓯江口</Option>
                                            <Option value={"330383"}>龙港市</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="归属区域"
                                >
                                    {getFieldDecorator('regionBelong', {
                                        initialValue: 330302,
                                    })(
                                        <Select>
                                            {/*<Option value={0}>不可查看</Option>*/}
                                            {/*<Option value={330300}>温州市</Option>*/}
                                            <Option value={"330301"}>市辖区</Option>
                                            <Option value={"330302"}>鹿城区</Option>
                                            <Option value={"330303"}>龙湾区</Option>
                                            <Option value={"330304"}>瓯海区</Option>
                                            <Option value={"330305"}>洞头区</Option>
                                            <Option value={"330324"}>永嘉县</Option>
                                            <Option value={"330326"}>平阳县</Option>
                                            <Option value={"330327"}>苍南县</Option>
                                            <Option value={"330328"}>文成县</Option>
                                            <Option value={"330329"}>泰顺县</Option>
                                            <Option value={"330371"}>温州高新区(经开区)</Option>
                                            <Option value={"330381"}>瑞安市</Option>
                                            <Option value={"330382"}>乐清市</Option>
                                            <Option value={"330354"}>温州生态园</Option>
                                            <Option value={"330355"}>瓯江口</Option>
                                            <Option value={"330383"}>龙港市</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="项目经度"
                                >
                                    {getFieldDecorator('longitude2', {
                                        // initialValue: ['zhejiang', 'hangzhou', 'xihu'],
                                        rules: [{required: true, message: '经度不能为空，小数保留4位'}, {
                                            // min: 6,
                                            message: '经度不能为空，小数保留4位',
                                            // whitespace: true
                                        }, {pattern: /^[0-9]*[.][0-9]{4}/, message: '经度不能为空，小数保留4位', whitespace: true}],
                                    })(
                                        <Input placeholder="xx.xxxx"/>
                                    )}

                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="项目纬度"
                                >
                                    {getFieldDecorator('latitude2', {
                                        // initialValue: ['zhejiang', 'hangzhou', 'xihu'],
                                        rules: [{required: true, message: '纬度不能为空，小数保留4位'}, {
                                            // min: 6,
                                            message: '纬度不能为空，小数保留4位',
                                            // whitespace: true
                                        }, {pattern: /^[0-9]*[.][0-9]{4}/, message: '纬度不能为空，小数保留4位', whitespace: true}],
                                    })(
                                        <Input placeholder="xx.xxxx"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="建筑编号"
                                >
                                    {getFieldDecorator('building', {
                                        // initialValue: ['zhejiang', 'hangzhou', 'xihu'],
                                        rules: [{required: true, message: '只能为数字和字母'}, {
                                            // min: 6,
                                            message: '只能为数字和字母',
                                            // whitespace: true
                                        }, {pattern: /^[0-9a-zA-Z]*$/, message: '只能为数字和字母', whitespace: true}],
                                    })(
                                        <Input/>
                                    )}

                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="项目描述"
                                >
                                    {getFieldDecorator('website', {})(
                                        <Input.TextArea/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="项目地址"
                                >
                                    {getFieldDecorator('address', {})(
                                        <Input.TextArea/>
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={14}>
                            <div className="">
                                <h4>模块管理</h4>
                                <Form.Item {...rightRadioFormItemLayout}
                                           label="有效日期"
                                >
                                    {getFieldDecorator('time', {
                                        initialValue: 31,
                                    })(
                                        <Radio.Group>
                                            <Radio value={31}>一个月</Radio>
                                            <Radio value={90}>三个月</Radio>
                                            <Radio value={36500}>永久</Radio>
                                            <Radio value="自定义">自定义</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item {...rightRadioFormItemLayout}
                                >
                                    {getFieldDecorator('timeRange', {
                                        initialValue: [moment(), getFieldValue('time') === '自定义' ? moment() : moment().add(getFieldValue('time'), 'day')],
                                        // rules: [{ type: 'array', required: true, message: '请选择时间!' }]
                                    })(
                                        <RangePicker
                                            disabled={getFieldValue('time') !== '自定义'}
                                            disabledDate={disabledDate}
                                            format={'YYYY-MM-DD'}
                                            // value={() => console.log(getFieldValue('time'))}
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item {...rightRadioFormItemLayout}
                                           label="项目类别"
                                >
                                    {getFieldDecorator('category', {
                                        initialValue: "BASIC",
                                    })(
                                        <Radio.Group>
                                            <Radio value="BASIC">计量基础版</Radio>
                                            <Radio value="ANALYSIS">能源计量分析版本</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                                <Form.Item {...rightFormItemLayout}
                                           label="网页前台模块："
                                >
                                    {getFieldDecorator('web', {
                                        initialValue: r.map(([v, k]) => (
                                            v
                                        ))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'web'])(projectDefaultModules)))
                                    })(
                                        <Checkbox.Group style={{width: "100%"}}>
                                            <Row>
                                                {
                                                    r.map(([v, k]) => (
                                                        <Col {...groupFormItemLayout} key={v}>
                                                            <Checkbox value={v}>{k}</Checkbox>
                                                        </Col>
                                                    ))(r.toPairs(r.propOr({})('web')(projectModules)))
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>
                                <Form.Item {...rightFormItemLayout}
                                           label="综合分析二级："
                                >
                                    {getFieldDecorator('analysis', {
                                        initialValue: r.map(([v, k]) => (
                                            v
                                        ))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'analysis'])(projectDefaultModules)))
                                    })(
                                        <Checkbox.Group style={{width: "100%"}}>
                                            <Row>
                                                {
                                                    r.map(([v, k]) => (
                                                        <Col {...groupFormItemLayout} key={v}>
                                                            <Checkbox disabled={r.includes('analysis', getFieldValue('web')) ? null : 'disabled'} value={v}>{k}</Checkbox>
                                                        </Col>
                                                    ))(r.toPairs(r.propOr({})('analysis')(projectModules)))
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>

                                <Form.Item {...rightFormItemLayout}
                                           label="网页后台模块："
                                >
                                    {getFieldDecorator('admin', {
                                        initialValue: r.map(([v, k]) => (
                                            v
                                        ))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'admin'])(projectDefaultModules)))
                                    })(
                                        <Checkbox.Group style={{width: "100%"}}>
                                            <Row>
                                                {
                                                    r.map(([v, k]) => (
                                                        <Col {...groupFormItemLayout} key={v}>
                                                            <Checkbox value={v}>{k}</Checkbox>
                                                        </Col>
                                                    ))(r.toPairs(r.propOr({})('admin')(projectModules)))
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>

                                <Form.Item {...rightFormItemLayout}
                                           label="定制功能："
                                >
                                    {getFieldDecorator('extra', {
                                        initialValue: r.map(([v, k]) => (
                                            v
                                        ))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'extra'])(projectDefaultModules)))
                                    })(
                                        <Checkbox.Group style={{width: "100%"}}>
                                            <Row>
                                                {
                                                    r.map(([v, k]) => (
                                                        <Col {...groupFormItemLayout} key={v}>
                                                            <Checkbox value={v}>{k}</Checkbox>
                                                        </Col>
                                                    ))(r.toPairs(r.propOr({})('extra')(projectModules)))
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    )}
                                </Form.Item>

                            </div>
                        </Col>
                    </Row>
                    <Form.Item {...tailFormItemLayout}>
                        <Button type="primary" htmlType="submit" className='newProjectFormButton'>确认</Button>
                        <Button className='newProjectFormButton'><Link to='/Project'>取消</Link></Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

const NewProject = Form.create({name: 'register'})(NewProjectFrom);


export default NewProject;
