
import React from 'react';
import ByitemRightTop from './byitemRightTop/byitemRightTop';
import ByitemRightBottom from './byitemRightBottom/byitemRightBottom';
const ByitemRight = () => (
    <div className='byitemRight'>
        <ByitemRightTop />
        <ByitemRightBottom />
    </div>
)
export default ByitemRight
