import React, { useContext } from 'react';
import { Table, Input, Select, Button, } from 'antd'
import Api from 'api';

import moment from 'moment'
import { from, of } from 'rxjs'
import withStates from 'src/context/withStates'
import * as r from 'ramda'
import './account.scss';

import AddNewAccount from './addNewAccount/addNewAccount';
import AccountDelete from './accountDelete/accountDelete';
import AccountEdit from './accountEdit/accountEdit';

const Option = Select.Option;
const { Search } = Input;
const columns = [{
    title: '序号',
    dataIndex: 'index',
    key: 'index',
}, {
    title: '账号名称',
    dataIndex: 'name',
    key: 'name',
}, {
    title: '所属项目',
    dataIndex: 'belongTo',
    key: 'belongTo',
}, {
    title: '权限级别',
    dataIndex: 'PermissionLevel',
    key: 'PermissionLevel',
}, {
    title: '负责人',
    dataIndex: 'principal',
    key: 'principal',
}, {
    title: '联系电话',
    dataIndex: 'phone',
    key: 'phone',

}, {
    title: '最后登录时间',
    dataIndex: 'lastLoginTime',
    key: 'lastLoginTime',
}, {
    title: '权限管理',
    dataIndex: 'rightsManagement',
    key: 'rightsManagement',
}, {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    render: (text, record) => {
        return <div className='tableOperation'>
            <AccountEdit record={record} />
            <AccountDelete record={record} />
        </div>
    }
}, {
    title: 'all',
    dataIndex: 'all',
    key: 'all',
    className: 'hide'
}];

const Account = () => {
    const { state: { data, loading }, dispatch } = useContext(context);
    const onSearch = value => {
        dispatch(state => of({ ...state, loading: true, search: value }))
        return dispatch(state => loadupdateData({ ...state, loading: true, search: value }))
    }
    return (
        <div className="account">
            <div className="accountHead">
                <ul>
                    <li>
                        <Search
                            placeholder="input search text"
                            onSearch={onSearch}
                            style={{ width: 200 }}
                        />
                    </li>
                </ul>
            </div>
            <div className='addNewAccount'>
                <AddNewAccount />
                <span>{data.length}个结果</span>
            </div>
            <Table columns={columns} dataSource={data} className='table' loading={loading} />
        </div>
    )
}


const tableData = async ({ search }) => {
    const { data: { result: { accounts } } } = await Api.post('/account/info', {
        pagesize: 1000
    });

    const tableData = accounts.map((k, i) => (
        {
            key: i,
            index: i + 1 < 10 ? '0' + (i + 1) : i + 1,
            name: k.user,
            belongTo: k.title,
            PermissionLevel: k.character.title,
            principal: k.title,
            phone: k.mobile ? k.mobile : '-',
            lastLoginTime: moment(k.lastlogin * 1000).format('YYYY-MM-DD HH:ss:mm'),
            rightsManagement: k.character.title,
            note: k.description,
            all: k
        }
    ))

    return {
        loading: false,
        data: r.innerJoin(
            (x, y) => {
                const re = new RegExp(y)
                return re.test(x.name) || re.test(x.belongTo) || re.test(x.PermissionLevel) || re.test(x.principal) || re.test(x.phone)
            }
        )(tableData)([search]),
    }
}

const loadAccount = (state) => {
    const input = tableData(state)
        .then(r.mergeAll)
    return from(input)
}

export const loadupdateData = (state) => {

        console.log('state ---');
        console.log(state);

    return from(tableData(state)
        .then(res => ({ ...state, ...res })))
}
const initState = {
    loading: true,
    data: [],
    search: ''
}

export const { context, Component } = withStates(
    Account,
    initState,
    loadAccount,
)

export default Component