import React, { useState, useEffect } from 'react';
import {
  Form, Input, Select, Row, Col, Button, DatePicker, message, InputNumber
} from 'antd';
import { Link } from "react-router-dom";
import Api from 'api';
import * as r from 'ramda';
import moment from 'moment';
import './newBillingService.scss';
import NewBillingServiceModal from './newBillingServiceModal/newBillingServiceModal';

const { Option } = Select;


const NewBillingServicetFrom = (props) => {
  const [fixpriceArr, setFixpriceArr] = useState({ addId: [], removeId: [] });
  const [defaultData, setDefaultData] = useState({});
  const billingServicetId = r.pathOr('')(['location', 'state', 'id'])(props);
  useEffect(() => {
    if (billingServicetId) {
      Api.post('/billingservice/info', {
        id: billingServicetId
      }).then(res => {
        const { data: { result: { title, equipments, id, rules: { fixprice } } } } = res;
        setDefaultData({
          title, equipments, fixprice, id
        })
      })
    }

  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFieldsAndScroll(async (err, values) => {
      let res;
      if (!err) {
        const { fixprice, title } = values
        if (r.isEmpty(defaultData)) {
          res = await Api.post('/billingservice/add', {
            title,
            projectid: Api.projectId,
            channels: fixpriceArr.addId,
            rules: { fixprice },
          })
        } else {
          res = await Api.post('/billingservice/update', {
            title,
            projectid: Api.projectId,
            addChannels: fixpriceArr.addId,
            removeChannels: fixpriceArr.removeId,
            rules: { fixprice },
            id: defaultData.id
          })
        }

        const msg = res.data.message === '' ? '成功' : res.data.message;
        message.info(msg);
        if (msg === '成功') {
          props.history.push('/BillingService')
        }
      }
    });
  }

  const { getFieldDecorator } = props.form;
  const formItemLayout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 12,
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      span: 24,
      // offset: 0,
    },
  };

  const getFixpriceArr = (arr) => {
    setFixpriceArr(arr)
  }
  return (
    <div className="newProject">
      <Form onSubmit={handleSubmit} className='newProjectForm'>
        <Row>
          <Col span={10}>
            <Form.Item {...formItemLayout}
              label="策略名称"
            >
              {getFieldDecorator('title', {
                initialValue: defaultData.title,
                rules: [{
                  required: true, message: '策略名称不能为空',
                }]
              })(
                <Input placeholder="请输入策略名称" />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}
              label='设置价格'
            >
              {getFieldDecorator('fixprice', {
                initialValue: defaultData.fixprice,
                rules: [{
                  required: true, message: '价格不能为空',
                }]
              })(
                <Input placeholder="请输入价格" />
              )}
            </Form.Item>
            <Form.Item {...formItemLayout}
              label='计费仪表'
            >
              {getFieldDecorator('fixpriceArr', {
                initialValue: defaultData.equipments,
                rules: [
                  {
                    message: '请选择计费仪表',
                    validator: (rule, value, cb) => {
                      return value > 0 ? cb() : cb(true)
                    },
                  },
                ],
              })(
                <div><InputNumber value={fixpriceArr.addId.length || defaultData.equipments || 0} disabled formatter={value => `${value}个`} /><NewBillingServiceModal getFixpriceArr={getFixpriceArr} defaultId={defaultData.id} /></div>
              )}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" className='newProjectFormButton'>确认</Button>
              <Button className='newProjectFormButton' ><Link to='/BillingService' >取消</Link></Button>
            </Form.Item>

          </Col>
        </Row>

      </Form>
    </div>
  );
}

const NewBillingService = Form.create({ name: 'register' })(NewBillingServicetFrom);


export default NewBillingService;