import React, { useState, useContext } from 'react';
import { Modal, message, Button, Input } from 'antd';
import { of } from 'rxjs';
import { context, loadManagerStates } from '../manager';
import sha1 from 'sha1';

import Api from 'src/api';

const Delete = (props) => {
    const { dispatch } = useContext(context);
    const { information: { tag } } = props.record;
    const [controlPassword, setControlPassword] = useState('');
    const [visible, setVisible] = useState(false);

    const onOk = () => {
        Api.post("/collector/delete", {
            tag,
            projectid: Api.projectId,
            ctrlcode: controlPassword
        })
            .then(({ data }) => {
                if (data.message === '' || data.message === undefined) {
                    message.info('删除成功');
                    setVisible(false)
                    dispatch(state => of({ ...state, loading: true }))
                    return dispatch(state => loadManagerStates({ ...state, loading: true }))
                } else { message.info(data.message) }
            })
    }

    const onCancel = () => { setVisible(false) };

    return (
        <div>
            <Button onClick={() => setVisible(true)} size='small' style={{ color: '#FF0927', cursor: 'pointer' }}>
                删除
    </Button>
            <Modal
                title="删除管理器"
                visible={visible}
                onOk={onOk}
                onCancel={onCancel}
                // width={960}
                style={{ top: 20 }}
                okText='确认'
                cancelText='取消'
                destroyOnClose={true}
            >
                <p>您确定要删除该仪表吗！</p>
                <Input.Password onChange={e => setControlPassword(sha1(e.target.value).toUpperCase())} placeholder='请输入控制密码' />
            </Modal>
        </div>

    );
}


export default Delete;