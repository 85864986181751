
import React from 'react';
import BuildingStateAddNodeSearch from './buildingStateAddNodeRightTableSearch/buildingStateAddNodeRightTableSearch';
import BuildingStateAddNodeTable from './buildingStateAddNodeRightTable/buildingStateAddNodeRightTable';
const BuildingStateAddNodeRight = () => (
    <div className='buildingStateAddNodeRight'>
        <BuildingStateAddNodeSearch />
        <BuildingStateAddNodeTable />
    </div>
)
export default BuildingStateAddNodeRight
