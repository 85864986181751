import * as r from 'ramda';
const categoryFormat = (v) => {
    const categoryMap = {
        "01000": "总表用电",
        "01A00": "照明与插座用电",
        "01A10": "照明与插座",
        "01A20": "走廊与应急",
        "01A30": "室外景观",
        "01B00": "空调用电",
        "01B10": "冷热站",
        "01B1A": "冷冻泵",
        "01B1B": "冷却泵",
        "01B1C": "冷机",
        "01B1D": "冷塔",
        "01B1E": "热水循环泵",
        "01B1F": "电锅炉",
        "01B20": "空调末端",
        "01B2A": "全空气机组",
        "01B2B": "新风机组",
        "01B2C": "排风机组",
        "01B2D": "风机盘管",
        "01B2E": "分体式空调",
        "01C00": "动力用电",
        "01C10": "电梯",
        "01C20": "水泵",
        "01C30": "通风机",
        "01D00": "特殊用电",
        "01D10": "信息中心",
        "01D20": "洗衣房",
        "01D30": "厨房餐厅",
        "01D40": "游泳池",
        "01D50": "健身房",
        "02000": "水",
        "03000": "管道煤气",
        "04000": "集中供热量",
        "05000": "集中供冷量",
        "01D90": "其他分项",
        "80001": "开关量输入",
        "90001": "继电器控制",
    }
    return r.propOr(v)(v)(categoryMap)
}

const deviceTypeFormat = v => {
    const devicrMap = {
        COLDWATERMETER: "冷水表",
        DDWATERMETER: "直饮水",
        ELECTRICITYMETER: "电表",
        ENERGYMETER: "能量表",
        HEATENERGYMETER: "热能表",
        HOTWATERMETER: "热水表",
        INNERCONTROLMETER: "内控电表",
        MELECTRICITYMETER: "多功能电表",
        NBCOLDWATERMETER: "NB冷水表",
        PHOTOVOLTAIC: "光伏",
        NBELECTRICITYMETER: "NB电表",
        NBFEECONTROLMETER: "NB费控表",
        OXYGENMETER: "氧气表",
        PRESSUREMETER: "压力表",
        TEMPERATURECONTROL: "温控器",
        TEMPHUMMETER: "温湿度表",
        TIMERMETER: "时间采集器",
        TTYPETEMPCONTROL: "T型温控器",
        ULTRACOLDWATERMETER: "超声波水表",
        ZTYPETEMPCONTROL: "Z型温控器",
    }
    return r.propOr(v)(v)(devicrMap)
}

const channelFormat = v => {
    const channelMap = {
        '01': "正向有功",
        '02': "A相电压",
        '03': "B相电压",
        '04': "C相电压",
        '05': "A相电流",
        '06': "B相电流",
        '07': "C相电流",
        '08': "能量系数",
        '09': "总冷量",
        '10': "总热量",
        '11': "累计流量",
        '12': "冷量系数",
        '13': "热量系数",
        '14': "瞬时流量",
        '15': "冷水刻度",
        '16': "热水刻度",
        '17': "供水温度",
        '18': "回水温度",
        "19": "设定温度",
        '20': "室内温度",
        '21': "面板状态",
        '22': "56状态",
        '23': "空调模式",
        '24': "流速",
    }
    return r.propOr(v)(v)(channelMap)
}
const userPermissionFormat = v => {
    const userPermissionMap = {
        ROOT: "超级管理员",
        TECHSUPPORT: "技术支持",
        APPID: "用于第三方接口调用",
        ADMIN: "管理员",
        TENEMENTADMIN: "管理员",
        TENEMENTENGINEER: "物业工程",
        TENEMENTFINANCE: "物业财务",
        USER: "用户"
    }
    return r.propOr(v)(v)(userPermissionMap)
}

// const measurementChannels = v => {
//     const channelsMap = {
//         '01': "正向有功",
//         '08': "能量系数",
//         '09': "总冷量",
//         '10': "总热量",
//         '11': "累计流量",
//         '12': "冷量系数",
//         '13': "热量系数",
//         '15': "冷水刻度",
//         '16': "热水刻度",
//     }
//     return channelsMap
// }

export {
    categoryFormat,
    deviceTypeFormat,
    channelFormat,
    userPermissionFormat
}