import React from 'react';
import { Link } from 'react-router-dom'
import './billingServiceManagement.scss'

const BillingServiceManagement = (props) => (
    <Link to={{ pathname: '/NewBillingService', state: { id: props.record.allData.id } }}>
        编辑
    </Link>
)                                                                  

export default BillingServiceManagement