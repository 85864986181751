import React, { useContext } from 'react';
import { Modal, message, Button, Icon } from 'antd';
import { of } from 'rxjs';
import { context, loadDepartmentStateAddNode } from '../departmentStateAddNode';

import Api from 'src/api';

const confirm = Modal.confirm;

const Delete = (props) => {
    const { dispatch } = useContext(context);
    const { id: removeid } = props.record


    const showConfirm = () => {
        confirm({
            title: '您确定要删除该节点吗！',
            onOk() {
                return (
                  Api.delete(`/project/${Api.projectId}/node/${removeid}`).then(res => {
                    message.info('删除成功')
                    dispatch(state => of({...state, loading: true}))
                    return dispatch(state => loadDepartmentStateAddNode({...state, loading: true}))
                  })).catch(e => message.info(`删除失败`, e.toString))
            },
            onCancel() { },
        });
    }
    return (
        <Button type="danger" size='small' onClick={showConfirm} >
            <Icon type="delete" />
            删除
    </Button>
    );
}


export default Delete;