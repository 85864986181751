// 这个是2020年的能耗数据，用来查询和生成能耗报告的

import React , { useState, useEffect } from 'react';
import {Checkbox, Col, DatePicker, Form, Radio, Row,Select,Input,Button, Upload} from 'antd';
import * as r from 'ramda';
import moment from 'moment';
import './newReportAll.scss'
import {
	groupFormItemLayout,
	projectDefaultModules,
	projectModules,
	rightFormItemLayout,
	updateProjectFormLayout
} from "src/context/project";
import {formItemLayout} from "../../../context/project";
import Api from "../../../api";
import {message} from "antd/lib/index";
import echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';


import Docxtemplater from'docxtemplater';
import ImageModule  from'docxtemplater-image-module-free';
import PizZip from'pizzip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';
import energyReport_single from './wznhjc_reportAll220713.docx';
// import energyReport_single from './wznhjc_reportAll0817.docx';
import Util2  from 'src/util/util2';
import energyMonthTj2023 from './energy_month_tj2023.txt';

import energyProjectInfo from './energyProjectInfo.json';
import energy_month_tj2023_res from './energy_month_tj2023_res.json';


const regionJsonConverter = Util2.regionJsonConverter;
const regionJson = Util2.regionJson;


const {RangePicker} = DatePicker;
 const projectType = Util2.projectTypeTransfer;
 const sectorNameArr = [
    'SPORT', 'CBD', 'HOSPITAL',
    'HOTEL',
    'MARKET',
    'OFFICE',
    'TEACH',
    'OTHER',
]
const {Option} = Select;
const filterModulesByType = type => r.filter(r.flip(r.includes)(r.keys(r.propOr({}, type, projectModules))))

var ehartsNodes = [];
const dateArr = [moment().subtract(6,'month').startOf('month').format('YYYYMMDD'),
    moment().subtract(1,'month').endOf('month').format('YYYYMMDD')];
// 这里的时间是从平台开始到上个月，为了获取所有的数据，数据库压力会很大
// 数据库可以增加月份统计表，如果后续有需要的话
const dateHistoryArr = ['20190801',
    moment().subtract(1,'month').endOf('month').format('YYYYMMDD')];
//全局数据，给后面echarts引用
const eleType = [ 'lightrcptcl','powerele','airele','specialele'];
const rankTypeValueLink={'rankAll':'value',
    'rankUnitHeadE':'unitHeadE',
    'rankUnitAreaE':'unitAreaE',
}
const optionName = {
    'rankAll':'总能耗',
    'rankUnitHeadE':'人均能耗',
    'rankUnitAreaE':'单位面积能耗',
    'unitHeadE':'人均能耗',
    'unitAreaE':'单位面积能耗',
    'lightrcptcl':'照明插座',
    'powerele':'动力用电',
    'airele':'空调用电',
    'specialele':'特殊用电'
}

const lg =(x)=>{
    console.log(x);
}

const resEleTypeValue = {},resEleTypeTj={},analyzeEleType={},analyzeAll={};// 1.排名 2.分类数据整理成一层数据给doc
var dataJson2 ;
var energyBySector,
    energyByRegion,
    regionAllEnergyMonths,
    regionEnergyUnitHeadEMonths,
    regionEnergyUnitAreaEMonths,
    SPORT_unitHeadERank,
    CBD_unitHeadERank,
    HOSPITAL_unitHeadERank,
    HOTEL_unitHeadERank,
    MARKET_unitHeadERank,
    OFFICE_unitHeadERank,
    TEACH_unitHeadERank,
    OTHER_unitHeadERank,
    sectorUnitHeadRanks,

    SPORT_unitAreaERank,
    CBD_unitAreaERank,
    HOSPITAL_unitAreaERank,
    HOTEL_unitAreaERank,
    MARKET_unitAreaERank,
    OFFICE_unitAreaERank,
    TEACH_unitAreaERank,
    OTHER_unitAreaERank,
    sectorUnitAreaRanks,

    SPORT_unitHeadAreaEHistory,
    CBD_unitHeadAreaEHistory,
    HOSPITAL_unitHeadAreaEHistory,
    HOTEL_unitHeadAreaEHistory,
    MARKET_unitHeadAreaEHistory,
    OFFICE_unitHeadAreaEHistory,
    TEACH_unitHeadAreaEHistory,
    OTHER_unitHeadAreaEHistory,
    sectorUnitAreaRanksHistory;

function monthInBetween(startMoment, endMoment) {
    const totalMonths = Math.ceil(moment.duration(
        endMoment.endOf('month').diff(startMoment.startOf('month'), 'month', true)));
    return r.map(x => x.format('YYYYMM'))(
        r.filter(m => m.isSameOrBefore(moment()))(
            r.map(offset => startMoment.clone().startOf('month').add(offset, 'month'))
            (r.range(0, totalMonths))))
}

var months = monthInBetween(moment(dateArr[0],'YYYYMM'),moment(dateArr[1],'YYYYMM'));
var monthsHistory = monthInBetween(moment(dateHistoryArr[0],'YYYYMM'),moment(dateHistoryArr[1],'YYYYMM'));

//部分没有数据的月份，默认添加斜杠，用于word生成时候好看一些
const   supplyMonthData = (dataArr)=>{
    // lg('supplyMonthData 处理前===》');
    // lg(dataArr);
    let monthStr  = '2023';
    let itemMonth = '';
    r.map(y=>{
        for(let i =1;i<13;i++){
            if(i<10){
                itemMonth = monthStr+'0'+i;
            }else{
                itemMonth = monthStr+i;
            }
            if(!y[itemMonth]){
                y[itemMonth] = '/';
            }

        }
    })(dataArr);

    // lg('supplyMonthData 处理后===》');
    // lg(dataArr);
    return dataArr;

}

const UpdateReportForm = props => {
	const {getFieldDecorator, getFieldValue} = props.form;
	const {project} = props;
    const [init, setInit] = useState( false);
    const [nodes, setNodes] = useState([{name:'nodes'}]);
    const [style1, setStyle1] = useState({ width: "400px", height: "260px" });
    // const [style2, setStyle2] = useState({ width: "400px", height: "260px" ,marginLeft:'50px'});
    const [style2, setStyle2] = useState({ width: "700px", height: "760px" ,marginLeft:'50px'});
    const [style3, setStyle3] = useState({ width: "400px", height: "460px" ,marginLeft:'50px'});
    var funcArr = [];

    useEffect(()=>{
        console.log('useEffect run --- ');

        energyBySector = echarts.init(document.getElementById('energyBySector'));
        energyByRegion = echarts.init(document.getElementById('energyByRegion'));
        regionAllEnergyMonths = echarts.init(document.getElementById('regionAllEnergyMonths'));
        regionEnergyUnitHeadEMonths = echarts.init(document.getElementById('regionEnergyUnitHeadEMonths'));
        regionEnergyUnitAreaEMonths = echarts.init(document.getElementById('regionEnergyUnitAreaEMonths'));
        //各类型人均能耗排名
        SPORT_unitHeadERank = echarts.init(document.getElementById('SPORT_unitHeadERank'));
        CBD_unitHeadERank = echarts.init(document.getElementById('CBD_unitHeadERank'));
        HOSPITAL_unitHeadERank = echarts.init(document.getElementById('HOSPITAL_unitHeadERank'));
        HOTEL_unitHeadERank = echarts.init(document.getElementById('HOTEL_unitHeadERank'));
        MARKET_unitHeadERank = echarts.init(document.getElementById('MARKET_unitHeadERank'));
        OFFICE_unitHeadERank = echarts.init(document.getElementById('OFFICE_unitHeadERank'));
        TEACH_unitHeadERank = echarts.init(document.getElementById('TEACH_unitHeadERank'));
        OTHER_unitHeadERank = echarts.init(document.getElementById('OTHER_unitHeadERank'));

        sectorUnitHeadRanks={SPORT_unitHeadERank:SPORT_unitHeadERank,
            CBD_unitHeadERank:CBD_unitHeadERank,
            HOSPITAL_unitHeadERank:HOSPITAL_unitHeadERank,
            HOTEL_unitHeadERank:HOTEL_unitHeadERank,
            MARKET_unitHeadERank:MARKET_unitHeadERank,
            OFFICE_unitHeadERank:OFFICE_unitHeadERank,
            TEACH_unitHeadERank:TEACH_unitHeadERank,
            OTHER_unitHeadERank:OTHER_unitHeadERank,
        };

        //各类型单位面积能耗排名
        SPORT_unitAreaERank = echarts.init(document.getElementById('SPORT_unitAreaERank'));
        CBD_unitAreaERank = echarts.init(document.getElementById('CBD_unitAreaERank'));
        HOSPITAL_unitAreaERank = echarts.init(document.getElementById('HOSPITAL_unitAreaERank'));
        HOTEL_unitAreaERank = echarts.init(document.getElementById('HOTEL_unitAreaERank'));
        MARKET_unitAreaERank = echarts.init(document.getElementById('MARKET_unitAreaERank'));
        OFFICE_unitAreaERank = echarts.init(document.getElementById('OFFICE_unitAreaERank'));
        TEACH_unitAreaERank = echarts.init(document.getElementById('TEACH_unitAreaERank'));
        OTHER_unitAreaERank = echarts.init(document.getElementById('OTHER_unitAreaERank'));

        sectorUnitAreaRanks={SPORT_unitAreaERank:SPORT_unitAreaERank,
            CBD_unitAreaERank:CBD_unitAreaERank,
            HOSPITAL_unitAreaERank:HOSPITAL_unitAreaERank,
            HOTEL_unitAreaERank:HOTEL_unitAreaERank,
            MARKET_unitAreaERank:MARKET_unitAreaERank,
            OFFICE_unitAreaERank:OFFICE_unitAreaERank,
            TEACH_unitAreaERank:TEACH_unitAreaERank,
            OTHER_unitAreaERank:OTHER_unitAreaERank,
        };
        //各类型，历年能耗数据
        SPORT_unitHeadAreaEHistory = echarts.init(document.getElementById('SPORT_unitHeadAreaEHistory'));
        CBD_unitHeadAreaEHistory = echarts.init(document.getElementById('CBD_unitHeadAreaEHistory'));
        HOSPITAL_unitHeadAreaEHistory = echarts.init(document.getElementById('HOSPITAL_unitHeadAreaEHistory'));
        HOTEL_unitHeadAreaEHistory = echarts.init(document.getElementById('HOTEL_unitHeadAreaEHistory'));
        MARKET_unitHeadAreaEHistory = echarts.init(document.getElementById('MARKET_unitHeadAreaEHistory'));
        OFFICE_unitHeadAreaEHistory = echarts.init(document.getElementById('OFFICE_unitHeadAreaEHistory'));
        TEACH_unitHeadAreaEHistory = echarts.init(document.getElementById('TEACH_unitHeadAreaEHistory'));
        OTHER_unitHeadAreaEHistory = echarts.init(document.getElementById('OTHER_unitHeadAreaEHistory'));
        sectorUnitAreaRanksHistory={
            SPORT_unitHeadAreaEHistory:SPORT_unitHeadAreaEHistory,
            CBD_unitHeadAreaEHistory:CBD_unitHeadAreaEHistory,
            HOSPITAL_unitHeadAreaEHistory:HOSPITAL_unitHeadAreaEHistory,
            HOTEL_unitHeadAreaEHistory:HOTEL_unitHeadAreaEHistory,
            MARKET_unitHeadAreaEHistory:MARKET_unitHeadAreaEHistory,
            OFFICE_unitHeadAreaEHistory:OFFICE_unitHeadAreaEHistory,
            TEACH_unitHeadAreaEHistory:TEACH_unitHeadAreaEHistory,
            OTHER_unitHeadAreaEHistory:OTHER_unitHeadAreaEHistory,
        };

    })
    // reactEcharts = echarts.init(document.getElementById('ReactEcharts'));

	if(project.funcLocate&&!init){
        console.log('init locate --- ');
        // var funcArr = [];
        var funcLocate = project.funcLocate;
        var funcDomain = project.funcDomain;
        console.log(funcLocate);
        console.log(funcDomain);
        for(var key in funcLocate){
            var index = key.lastIndexOf('_');
            var num = key.substr(index+1,key.length);
            funcArr.push({funcLocate:funcLocate[key], funcDomain:funcDomain['funcDomain_'+num]});
        }
        console.log('init func ---');
        console.log(funcArr);
        setInit(true);
	}

    const [informWay, setInformWay] = useState( funcArr.length>0?funcArr:[
        {funcLocate:'',funcDomain:''},//功能区位置 功能区
    ]);

	const disabledDate = (current) => {
		return current && current > moment().subtract(1, 'days');
	}
	const getTimeValue = (start, end) => {
		const span = Math.floor(moment.duration(moment(end).diff(moment(start))).asDays())
		return r.includes(span, [31, 90, 36500]) ? span : '自定义'
	}
    //处理统计数据 txt 格式
    const dealTjTxt =()=>{
	    console.log('---------  处理统计数据txt ---------');
        console.log(energyMonthTj2023);
        let tjArr = energyMonthTj2023.split(' ');
        console.log(tjArr);
    }
    const monthThisYear = ['202306','202305','202304','202303','202302','202301'];
    // 导出文档
    const loadData = async ()=>{

        // dealTjTxt();
         console.log(energyProjectInfo) ;
         // console.log(energy_month_tj2023_res) ;
         let energyTj = {},energyTjHis ={};
        for(let k in energy_month_tj2023_res){
            if(!energyTj[k]){
                energyTj[k] = {};
            }
            if(!energyTjHis[k]){
                energyTjHis[k] = {};
            }
            let item = energy_month_tj2023_res[k];
            for(let k2 in item){
                let item2 = item[k2];
                if(monthThisYear.indexOf(k2)!==-1){
                    energyTj[k][k2]=item2;
                    energyTjHis[k][k2]=item2;
                }else{
                    energyTjHis[k][k2]=item2;
                }
            }
        }
        console.log('energyTj energyTjHis ===== > ');
        console.log(energyTj);
        console.log(energyTjHis);

        // return;
        //获取项目的信息 这里是原始的api接口获取的数据，现在替换成从json文件里获取，因为生产上数据获取失败，可能是太多了导致失败
        // const [{data:allProjectInfo1},
        //     // {data: dayReport},
        //     {data:prjsMonthEnergy},
        //     {data:prjsMonthEnergyHistory}] = await Promise.all([Api.get(`/api/v3/allProjectInfo1`),
        //     // Api.get(`/api/v3/project/${project.id}/report/0?from=${dateArr[0]}&to=${dateArr[1]}`),
        //     Api.get(`/api/v3/energyNew/usageRatioMonthlyRegion2?region=330300&project=${Api.projectId}&from=${dateArr[0]}&to=${dateArr[1]}&cacheType=short`),
        //     Api.get(`/api/v3/energyNew/usageRatioMonthlyRegion2?region=330300&project=${Api.projectId}&from=${dateHistoryArr[0]}&to=${dateHistoryArr[1]}&cacheType=long`),
        // ]);

        // 时间段内项目的每个月的能耗数值

        //存在负数的项目，进行剔除-s
        let usefulProjectIdsPlan = [],infoProjectIdsPlan = [], usefulProjectIds = [],energyTjHisUse = {},energyTjUse={},energyProjectInfoUse={};
        for(let k in energyTjHis){
            let item = energyTjHis[k];
            let isUse = true;
            for(let k2 in item){
                let item2 = item[k2];
                if(Number(item2.electricity) < 0){
                    console.log('negative value: ' +k+'|'+ item2.electricity);
                    isUse = false;
                }

            }
            if(isUse){
                if(!k.startsWith('del')){//删除的，不要包括进来
                    usefulProjectIdsPlan.push(k);
                }

            }

        }
        //最后还是要根据allinfo里面的来，有些有数据的项目不一定要放进来，还要在info中有信息的才可以用
        for(let k in energyProjectInfo){
            if(usefulProjectIdsPlan.indexOf(k)!==-1){
                usefulProjectIds.push(k);
            }

        }

        console.log('usefulProjectIds' + usefulProjectIds);

        for(let k in energyTjHis){
            if(usefulProjectIds.indexOf(k)!==-1){
                energyTjHisUse[k]=energyTjHis[k];
            }

        }
        for(let k in energyTj){
            if(usefulProjectIds.indexOf(k)!==-1){
                energyTjUse[k]=energyTj[k];
            }

        }
        for(let k in energyProjectInfo){
            if(usefulProjectIds.indexOf(k)!==-1){
                energyProjectInfoUse[k]=energyProjectInfo[k];
            }

        }
        console.log('energyProjectInfoUse =====>');
        console.log(energyProjectInfoUse);
        for(let k in energyProjectInfoUse){
            let item = energyProjectInfoUse[k];
            if(regionJson[item.region]){
                console.log('发现区域没有转名称的： ' + item.region);
                item.region = regionJson[item.region];
            }
            console.log('info:'+item.title +'|'+item.region);
            if(Number(item.totalArea)<100){
                console.log('面积异常 === 》 ' + item.title + ':'+item.totalArea);
            //    TODO 有面积异常的，都是算100000 平方吧
                item.totalArea = '100000';
            }
        }
        console.log('project region =====>');
        for(let k in energyProjectInfoUse){
            let item = energyProjectInfoUse[k];
            console.log(item.region);
        }

        // return;
        // -e
        //-s 202307 有些项目，也许数据太不正常，就去剔除，有些项目，可能个别月份有问题，手动校正。权宜之计

        //1.   622856241f061a00113c6f09 万象城 202206月的单位面积能耗很大，手手动校正
        let dealPrjInfo;
        let prjId = '622856241f061a00113c6f09';
        dealPrjInfo = energyProjectInfoUse[prjId];
        lg('处理的项目信息');
        lg(dealPrjInfo);
        lg('处理前数值');
        lg(energyTjHisUse[prjId][202206]);
        energyTjHisUse[prjId][202206].electricity=energyTjHisUse[prjId][202207].electricity;
        energyTjHisUse[prjId][202206].total=energyTjHisUse[prjId][202207].total;

        lg('处理前数值');
        lg(energyTjHisUse[prjId][202206]);
        // office 5f8e42a0c8f86e0011df4577 202011

         prjId = '5f8e42a0c8f86e0011df4577';
        dealPrjInfo = energyProjectInfoUse[prjId];
        lg('处理的项目信息');
        lg(dealPrjInfo);
        lg('处理前数值');
        lg(energyTjHisUse[prjId][202011]);
        energyTjHisUse[prjId][202011].electricity=energyTjHisUse[prjId][202012].electricity;
        energyTjHisUse[prjId][202011].total=energyTjHisUse[prjId][202012].total;

        lg('处理前数值');
        lg(energyTjHisUse[prjId][202011]);

        //5eb4b662a08bfe0011eeea73 文化教育 温州医学院 202008
        prjId = '5eb4b662a08bfe0011eeea73';
        dealPrjInfo = energyProjectInfoUse[prjId];
        lg('处理的项目信息');
        lg(dealPrjInfo);
        lg('处理前数值');
        lg(energyTjHisUse[prjId][202008]);
        energyTjHisUse[prjId][202008].electricity=energyTjHisUse[prjId][202009].electricity;
        energyTjHisUse[prjId][202008].total=energyTjHisUse[prjId][202009].total;

        lg('处理前数值');
        lg(energyTjHisUse[prjId][202008]);

        //-e



        //将数值赋值给原先存储的数据
        let allProjectInfo1=energyProjectInfoUse;
        let prjsMonthEnergy=energyTjUse;
        let prjsMonthEnergyHistory=energyTjHisUse;







        console.log('prjsMonthEnergy');
        console.log(prjsMonthEnergy);
        console.log('prjsMonthEnergyHistory');
        console.log(prjsMonthEnergyHistory);
        console.log('allProjectInfo1');
        console.log(allProjectInfo1);

        // return;//TODO 20230711 这里只要看获取的数据格式，就直接返回了。后面再注释回来吧

        // 	能耗建筑区域分布,类型分布
        var formatPrjsInfo = r.map(([name,attrs])=>({name,attrs}))(r.toPairs()(allProjectInfo1));
        lg('format allprojectInfo1 -- ');
        lg(formatPrjsInfo);

        var regionDistry=r.groupBy(({name,attrs})=>(attrs.region))(formatPrjsInfo);
        // // 整理成可以直接给docx使用的数据，能耗建筑区域分布图
        // var regionDistriDocx = r.map(([region,info])=>({region:r.propOr('-',region)(regionJsonConverter),num:info.length,...info}))(r.toPairs(regionDistry));
        lg('region distribution --- ');
        lg(regionDistry);
        // lg(regionDistriDocx);

        var sectorDistry=r.groupBy(({name,attrs})=>(attrs.sector))(formatPrjsInfo);
        lg('sectorDistry --- ');
        lg(sectorDistry);

        //面积占比，计算总面积即可，后面直接除以总面积就可以
        var totalAreaAll = r.sum(r.map(y=>Number(y.attrs.totalArea))(formatPrjsInfo));
        var totalEnergyAll = 0;
        r.map(x=>(r.map(z=>((z.total)?(totalEnergyAll+=Number(z.total)):0))(x)))(prjsMonthEnergy);
        lg('totalArea,totalEnergy --- ');
        lg(totalAreaAll);
        lg(totalEnergyAll);
        //计算各种类型和各种地域的总能耗 每个月的总能耗也统计下，后面画图需要用到
        var sourceSectorRegion={regionDistry:regionDistry,sectorDistry:sectorDistry};
        var tjSectorRegion={regionDistry:{},sectorDistry:{}};
        for(var item in sourceSectorRegion){
            for(var k in sourceSectorRegion[item]){
                var prjsUnit = sourceSectorRegion[item][k];
                var headCount=0,totalArea=0,ids = [];
                r.map(y=>{
                    headCount+=Number(y.attrs.headCount);
                    totalArea+=Number(y.attrs.totalArea);
                    ids.push(y.name);
                })(prjsUnit);
                // var totalEnergyUnit = r.sum(r.map(y=>r.sum(r.map(y=>((y.total)?(Number(y.total)):0))(prjsMonthEnergy[y])))(ids));

                var totalEnergyUnit = r.sum(r.map(y=>{
                    var sm=0;
                    if(prjsMonthEnergy[y]){
                        r.map(z=>((z.total)?(sm+=Number(z.total)):0))(prjsMonthEnergy[y])
                    }
                    return sm;
                })(ids))
                tjSectorRegion[item][k]={headCount:headCount,totalArea:totalArea,areaPer:(100*totalArea/totalAreaAll).toFixed(2)+'%',totalEnergyUnit:totalEnergyUnit.toFixed(2),energyPer:(100*totalEnergyUnit/totalEnergyAll).toFixed(2)+'%'};
            }
        }

        lg('tjSectorRegion ---');
        lg(tjSectorRegion);
        //整理docx的 各类建筑基本信息和能耗
        // 整理成可以直接给docx使用的数据，能耗建筑类型分布图 sectorDistry
        var sectorDistriDocx = r.map(([sector,info])=>({
            title:r.propOr('-',sector)(projectType),
            num:(sectorDistry[sector]?sectorDistry[sector].length:0),
            ...info
        }))(r.toPairs(tjSectorRegion.sectorDistry));
        lg('sectorDistriDocx --- ');
        lg(sectorDistriDocx);
        // 整理成可以直接给docx使用的数据，能耗建筑区域分布图 regionDistry
        var regionDistriDocx = r.map(([region,info])=>({
            title:r.propOr('-',region)(regionJsonConverter),
            num:(regionDistry[region]?regionDistry[region].length:0),
            ...info
        }))(r.toPairs(tjSectorRegion.regionDistry));
        lg('regionDistriDocx --- ');
        lg(regionDistriDocx);


        // 建筑各月份总能耗统计表
        // 建筑各月份人均能耗统计表
        // 建筑各月份单位面积能耗统计表
        var energyAllMonthsArr={
            energyMonthsAll:[],
            energyMonthsUnitHeadE:[],
            energyMonthsUnitAreaE:[]
        };
        for(var k in prjsMonthEnergy){
            var prj = allProjectInfo1[k];
            var energyMonths = prjsMonthEnergy[k];
            var info = (prj)?{projectId:k,sector:prj.sector,region:prj.region,title:prj.title}:{region:'-',title:'-'};


            var heads = (prj&&prj.headCount)?Number(prj.headCount):1;
            var area = (prj&&prj.totalArea)?Number(prj.totalArea):1;
            var mapFormat = r.toPairs()(energyMonths);
            var monthsEnergyAll={},monthsEnergyUnitHeadE={},monthsEnergyUnitAreaE={};
            r.map(([dt,info])=>{
                monthsEnergyAll[dt]=info.total;
                monthsEnergyUnitHeadE[dt]=(info.total/heads).toFixed(2);
                monthsEnergyUnitAreaE[dt]=(info.total/area).toFixed(2);
            })(mapFormat);
            var total = r.sum(r.map(([dt,info])=>info.total)(mapFormat));


            // energyAllMonths.push((prj)?{info,monthsEnergy}:{region:'-',tregionJsonitle:'-',...monthsEnergy});
            energyAllMonthsArr.energyMonthsAll.push({...info,...monthsEnergyAll,total:Number(total.toFixed(2)),sectorName:r.propOr('-',info.sector)(projectType),regionName:r.propOr('-',info.region)(regionJsonConverter)});
            energyAllMonthsArr.energyMonthsUnitHeadE.push({...info,...monthsEnergyUnitHeadE,total:Number((total/heads).toFixed(2)),sectorName:r.propOr('-',info.sector)(projectType),regionName:r.propOr('-',info.region)(regionJsonConverter)});
            energyAllMonthsArr.energyMonthsUnitAreaE.push({...info,...monthsEnergyUnitAreaE,total:Number((total/area).toFixed(2)),sectorName:r.propOr('-',info.sector)(projectType),regionName:r.propOr('-',info.region)(regionJsonConverter)});

            if(!regionJsonConverter[info.regionName]){
                console.log('没有找到对应的region ' + prj);
            }

        }

        lg('energyAllMonthsArr --- ');
        lg(energyAllMonthsArr);

        // let checkEnergyMonthsUnitAreaE = energyAllMonthsArr.energyMonthsUnitAreaE;
        // r.map(y=>{lg('checkEnergyMonthsUnitAreaE  '+y.projectId + '|' + y.region +'|' + y.regionName+'|'+y.title)})(checkEnergyMonthsUnitAreaE);
        // return;
        // 各种类型的建筑，取人均能耗，单位面积能耗前5名
        var groupSortCalArr = {
            energyMonthsUnitHeadE:{},
            energyMonthsUnitAreaE:{},
        };

        for(var k in groupSortCalArr){

            var groupSort = r.map(r.compose(r.take(5), r.reverse,r.sortBy(r.compose(Number,r.prop('total')))))(r.groupBy(r.prop('sector'))(energyAllMonthsArr[k]));

            var groupSortCalAvg = r.map(y=>{
                var typeTotal = r.pipe(r.map(r.prop('total')),r.sum)(y);
                var avg = (typeTotal&&typeTotal!==0)?Number((typeTotal/y.length).toFixed(2)):'-';
                var second = (y.length>1)?y[1].total:'-';
                return r.map(z=>({avg,second,ovAvg:(avg&&avg!=='-')?((100*(z.total-avg)/avg).toFixed(2)+'%'):'-',
                    ovSec:(second&&second!=='-')?((100*(z.total-second)/second).toFixed(2)+'%'):'-',...z}))(y);
                // return y;
            })(groupSort);
            groupSortCalArr[k]=groupSortCalAvg;
        }
        lg('groupSortCalArr ---- ');
        lg(groupSortCalArr);

        // 整理出word的格式使用,上面算了人均和单位面积，报告里只用人均能耗，怎么简单怎么来，
        // 还是弄成单位面积的，人均没有意义，不准确20230713
        // 后期需要再加上去吧
        var sectorRanks = [];
        for(var k in groupSortCalArr.energyMonthsUnitHeadE){
            var unitSort = (groupSortCalArr.energyMonthsUnitHeadE)[k];
            var randE5={};
            for(var i =1;i<=unitSort.length;i++){
                randE5['rankUnitHeadE'+i]=unitSort[(i-1)].title;
            }
            sectorRanks.push({
                ...randE5,
                ...unitSort[0]
            });

        }
        console.log('sectorRanks ---- ');
        console.log(sectorRanks);

        var sectorAreaRanks = [];
        for(var k in groupSortCalArr.energyMonthsUnitAreaE){
            var unitSort = (groupSortCalArr.energyMonthsUnitAreaE)[k];
            var randE5={};
            for(var i =1;i<=unitSort.length;i++){
                randE5['rankUnitAreaE'+i]=unitSort[(i-1)].title;
            }
            sectorAreaRanks.push({
                ...randE5,
                ...unitSort[0]
            });

        }
        console.log('sectorAreaRanks ---- ');
        console.log(sectorAreaRanks);

        // word 2.3 各类建筑表格数据
        var sectorUnitHeadETables = {
            SPORT_unitHeadETable:[],
            CBD_unitHeadETable:[],
            HOSPITAL_unitHeadETable:[],
            HOTEL_unitHeadETable:[],
            MARKET_unitHeadETable:[],
            OFFICE_unitHeadETable:[],
            TEACH_unitHeadETable:[],
            OTHER_unitHeadETable:[],
        };
        for(var k in groupSortCalArr.energyMonthsUnitHeadE){
            var kName = k+'_unitHeadETable';
            sectorUnitHeadETables[kName] = groupSortCalArr.energyMonthsUnitHeadE[k];
        }
        lg('sectorUnitHeadETables -- ');
        lg(sectorUnitHeadETables);

        //20230713 单位面积的数据
        var sectorUnitAreaETables = {
            SPORT_unitAreaETable:[],
            CBD_unitAreaETable:[],
            HOSPITAL_unitAreaETable:[],
            HOTEL_unitAreaETable:[],
            MARKET_unitAreaETable:[],
            OFFICE_unitAreaETable:[],
            TEACH_unitAreaETable:[],
            OTHER_unitAreaETable:[],
        };
        for(var k in groupSortCalArr.energyMonthsUnitAreaE){
            var kName = k+'_unitAreaETable';
            sectorUnitAreaETables[kName] = groupSortCalArr.energyMonthsUnitAreaE[k];
        }
        lg('sectorUnitAreaETables -- ');
        lg(sectorUnitAreaETables);


        // 温州市分类建筑 历年数据
        var energyAllMonthsHistoryArr={
            energyMonthsAll:[],
            energyMonthsUnitHeadE:[],
            energyMonthsUnitAreaE:[],
        };
        for(var k in prjsMonthEnergyHistory){
            var prj = allProjectInfo1[k];
            var energyMonths = prjsMonthEnergyHistory[k];
            var info = (prj)?{projectId:k,sector:prj.sector,region:prj.region,title:prj.title}:{region:'-',title:'-'};
            var heads = (prj&&prj.headCount)?Number(prj.headCount):1;
            var area = (prj&&prj.totalArea)?Number(prj.totalArea):1;
            var mapFormat = r.toPairs()(energyMonths);
            var monthsEnergyAll={},monthsEnergyUnitHeadE={},monthsEnergyUnitAreaE={};
            r.map(([dt,info])=>{
                monthsEnergyAll[dt]=info.total;
                monthsEnergyUnitHeadE[dt]=(info.total/heads).toFixed(2);
                monthsEnergyUnitAreaE[dt]=(info.total/area).toFixed(2);
            })(mapFormat);
            var total = r.sum(r.map(([dt,info])=>info.total)(mapFormat));


            // energyAllMonths.push((prj)?{info,monthsEnergy}:{region:'-',title:'-',...monthsEnergy});
            energyAllMonthsHistoryArr.energyMonthsAll.push({...info,...monthsEnergyAll,total:Number(total.toFixed(2))});
            energyAllMonthsHistoryArr.energyMonthsUnitHeadE.push({...info,...monthsEnergyUnitHeadE,total:Number((total/heads).toFixed(2))});
            energyAllMonthsHistoryArr.energyMonthsUnitAreaE.push({...info,...monthsEnergyUnitAreaE,total:Number((total/area).toFixed(2))});
        }

        lg('energyAllMonthsHistoryArr --- ');
        lg(energyAllMonthsHistoryArr);
        // // 各种类型的建筑，取人均能耗，前5名
        // var groupSortHitory = r.map(r.compose(r.take(5), r.reverse,r.sortBy(r.compose(Number,r.prop('total')))))(r.groupBy(r.prop('sector'))(energyAllMonthsHistoryArr.energyMonthsUnitHeadE));
        //
        // lg('groupSortHitory ---- ');
        // lg(groupSortHitory);

        // 当前计算时间的各区域建筑  总能耗 人均能耗 单位面积能耗 2.2  docx这里有对图形的要求
        var RegionEnergyAllMonthsCalArr = {
                energyMonthsAll:[],
                energyMonthsUnitHeadE:[],
                energyMonthsUnitAreaE:[]
        }
        for(var k in RegionEnergyAllMonthsCalArr){
            RegionEnergyAllMonthsCalArr[k] = r.map(y=>{
                var monthDataCal = {
                };
                var sum=0;
                r.map(yy=>{
                    monthDataCal[yy]=r.sum(r.map(z=>r.compose(Number,r.propOr(0,yy))(z))(y));
                    sum=r.sum(r.map(z=>r.compose(Number,r.prop(yy))(z))(y));
                })(months);
                return monthDataCal;

            })((r.groupBy(r.prop('region'))(energyAllMonthsArr[k])));
        }
        lg('RegionEnergyAllMonthsCalArr  -- ');
        lg(RegionEnergyAllMonthsCalArr);
        // 历史数据 ：各种类型建筑 各个月份的 人均能耗 单位面积能耗

        var energyAllMonthsHistoryCalArr = {
                energyMonthsUnitHeadE:[],
                energyMonthsUnitAreaE:[]
        }
        for(var k in energyAllMonthsHistoryCalArr){
            energyAllMonthsHistoryCalArr[k] = r.map(y=>{
                var monthDataCal = {
                };
                var sum=0;
                r.map(yy=>{
                    // var a = r.map(z=>{
                    //     var b = r.compose(Number,r.prop(yy))(z)
                    //     var c = r.compose(r.prop(yy),Number)(z)
                    //
                    // })(y);
                    monthDataCal[yy]=r.sum(r.map(z=>r.compose(Number,r.propOr(0,yy))(z))(y));
                    sum=r.sum(r.map(z=>r.compose(Number,r.prop(yy))(z))(y));
                })(monthsHistory);
                return monthDataCal;

            })((r.groupBy(r.prop('sector'))(energyAllMonthsHistoryArr[k])));
        }

        lg('energyAllMonthsHistoryCalArr');
        lg(energyAllMonthsHistoryCalArr);
        //20230724 存在明显峰值的异常数据，找到2022年6月，异常的单位面积能耗的项目
        r.map(
            prj=>{
                if(prj.sector == 'MARKET'){
                    if(Number(prj[202206])>100 || Number(prj[202205])>100){
                        console.log('找到一个商场，约202206时候数值很大');
                        lg(prj);
                    }
                }
            }
        )(energyAllMonthsHistoryArr.energyMonthsUnitAreaE);
        //办公建筑 202011 202012 有异常 OFFICE
        r.map(
            prj=>{
                if(prj.sector == 'OFFICE'){
                    if(Number(prj[202011])>500 || Number(prj[202012])>500){
                        console.log('找到一个办公建筑，约 202011 时候数值很大');
                        lg(prj);
                    }
                }
            }
        )(energyAllMonthsHistoryArr.energyMonthsUnitAreaE);

        //  文化教育建筑 TEACH 202009
        r.map(
            prj=>{
                if(prj.sector == 'TEACH'){
                    if( Number(prj[202008])>500 || Number(prj[202009])>500 || Number(prj[202010])>500){
                        console.log('找到一个文化教育建筑，约 202009 时候数值很大');
                        lg(prj);
                    }
                }
            }
        )(energyAllMonthsHistoryArr.energyMonthsUnitAreaE);
        //--e




        // 增加图标的数据
        // 分区域的能耗占比，分类型的能耗占比
        var sectorLegend = r.map(r.propOr('','title'))(sectorDistriDocx);
        // var sectorLegend = r.map(({title,energyPer})=>((title+'i')))(sectorDistriDocx);
        // var sectorLegend = ['ewqerf','rw','r', 'i'];
        lg('sectorLegend ---- ');
        lg(sectorLegend);
        var sectorPer = r.map(r.propOr('','energyPer'))(sectorDistriDocx);
        var sectorData = r.map(({title,totalEnergyUnit})=>({
            name:title,
            value:Number(totalEnergyUnit)
        }))(sectorDistriDocx);
        energyBySector.setOption(getOption('各类建筑能耗占比情况',sectorLegend,sectorData));

        var regionLegend = r.map(r.propOr('','title'))(regionDistriDocx);
        // var regionLegend = r.map(({title,energyPer})=>(title+'-'+energyPer))(regionDistriDocx);
        var regionPer = r.map(r.propOr('','energyPer'))(regionDistriDocx);
        var regionData = r.map(({title,totalEnergyUnit})=>({
            name:title,
            value:Number(totalEnergyUnit)
        }))(regionDistriDocx);
        lg('region echarts -- ');
        lg(regionLegend+'|'+regionPer+'|'+regionData+'|')
        energyByRegion.setOption(getOption('各区域建筑能耗占比情况',regionLegend,regionData));

        // 2.2 不同地区 总能耗 人均能耗 单位面积能耗 RegionEnergyAllMonthsCalArr
        var regionEnergyArrEcharts = {
                    energyMonthsAll:{title:'不同区域各月份建筑总能耗图',xAxisData:months, dataArr:[]},
                    energyMonthsUnitHeadE:{title:'不同区域各月份建筑人均能耗图',xAxisData:months, dataArr:[]},
                    energyMonthsUnitAreaE:{title:'不同区域各月份建筑单位面积能耗图',xAxisData:months, dataArr:[]},
        }
        for(var k in RegionEnergyAllMonthsCalArr){
            var regionUnit = RegionEnergyAllMonthsCalArr[k];
            //    去掉没有这个分月数据的undefined字段。
            var lineDatas = r.map(r.pipe(
                r.props(months),r.map(y=>y?y:0)
            ))(regionUnit);
            regionEnergyArrEcharts[k].dataArr= lineDatas;
        }
        lg('regionEnergyArrEcharts --- ');
        lg(regionEnergyArrEcharts);
        regionAllEnergyMonths.setOption(getOption2(regionEnergyArrEcharts.energyMonthsAll));
        regionEnergyUnitHeadEMonths.setOption(getOption2(regionEnergyArrEcharts.energyMonthsUnitHeadE));
        regionEnergyUnitAreaEMonths.setOption(getOption2(regionEnergyArrEcharts.energyMonthsUnitAreaE));

        //2.3 docx 不同类型的建筑能耗排名 sectorUnitHeadETables
        var s = '_unitHeadERank';
        r.map(y=>{
            var s1 = y+'_unitHeadETable';
            var s2 = y+'_unitHeadAreaEHistory';
            // 计算区间
            // title,axisData,data
            var title = projectType[y]+'人均能耗排名';
            var axisData = [];
            var data = [];
            r.map(z=>{
                axisData.push(z.title);
                data.push(z.total);
            })(sectorUnitHeadETables[s1])
            lg('option3 -- ');
            lg(title + '|' + axisData + '|' + data);
            sectorUnitHeadRanks[y+'_unitHeadERank'].setOption(getOption3(title,axisData,data));
            // sectorUnitHeadRanks
            // sectorUnitAreaRanksHistory
        //    单位面积
            let sArea = y+'_unitAreaETable';
            // 计算区间
            // title,axisData,data
            let titleArea = projectType[y]+'单位面积能耗排名';
            let axisDataArea = [];
            let dataArea = [];
            r.map(z=>{
                axisDataArea.push(z.title.substr(0,4)+'..');
                dataArea.push(z.total);
            })(sectorUnitAreaETables[sArea])
            lg('option3Area -- ');
            lg(titleArea + '|' + axisDataArea + '|' + dataArea);
            sectorUnitAreaRanks[y+'_unitAreaERank'].setOption(getOption3(titleArea,axisDataArea,dataArea));

        //    历史数据区间
        //     var titleHistory = projectType[y]+'历史人均能耗，单位面积能耗排名';
            var titleHistory = projectType[y]+'历史单位面积能耗排名';
            // var legendHistory = ['人均能耗','单位面积能耗'];
            var legendHistory = ['单位面积能耗'];
            var axisHistory = monthsHistory;
            // var dataHistory =energyAllMonthsHistoryCalArr.energyMonthsUnitHeadE[y]?
            //     [r.props(monthsHistory)(energyAllMonthsHistoryCalArr.energyMonthsUnitHeadE[y]),r.props(monthsHistory)(energyAllMonthsHistoryCalArr.energyMonthsUnitAreaE[y])]:[]
            //只保留单位面积，人均的数值峰值很高，没有参考价值
            var dataHistory =energyAllMonthsHistoryCalArr.energyMonthsUnitHeadE[y]?
                [r.props(monthsHistory)(energyAllMonthsHistoryCalArr.energyMonthsUnitAreaE[y])]:[]

            lg('option4 -- ');
            lg(titleHistory + '|' + legendHistory + '|' +axisHistory + '|' + dataHistory);
            sectorUnitAreaRanksHistory[y+'_unitHeadAreaEHistory'].setOption(getOption4(titleHistory,legendHistory,axisHistory,dataHistory));

        })(sectorNameArr)


        //-e
        // lg('SPORT_unitHeadERank.getDataURL() ==');
        // lg(SPORT_unitHeadERank.getDataURL());
        // lg(HOSPITAL_unitHeadERank.getDataURL());


        dataJson2 = {
            compile_date:moment().format('YYYY年MM月DD日'),
            projectNums:Object.keys(allProjectInfo1).length,
            allBuildings:3*Object.keys(allProjectInfo1).length,
            totalAreaAll:totalAreaAll,
            regionDistriDocx:regionDistriDocx,
            sectorDistriDocx:sectorDistriDocx,
            prjInfos:r.map(([projectId,info])=>(info))(r.toPairs(allProjectInfo1)),
            allEnergyMonths:supplyMonthData(energyAllMonthsArr.energyMonthsAll),
            unitHeadEMonths:supplyMonthData(energyAllMonthsArr.energyMonthsUnitHeadE),
            unitAreaEMonths:supplyMonthData(energyAllMonthsArr.energyMonthsUnitAreaE),
            sectorRanks:sectorRanks,
            sectorAreaRanks:sectorAreaRanks,
            SPORT_unitHeadETable:sectorUnitHeadETables.SPORT_unitHeadETable,
            CBD_unitHeadETable:sectorUnitHeadETables.CBD_unitHeadETable,
            HOSPITAL_unitHeadETable:sectorUnitHeadETables.HOSPITAL_unitHeadETable,
            HOTEL_unitHeadETable:sectorUnitHeadETables.HOTEL_unitHeadETable,
            MARKET_unitHeadETable:sectorUnitHeadETables.MARKET_unitHeadETable,
            OFFICE_unitHeadETable:sectorUnitHeadETables.OFFICE_unitHeadETable,
            TEACH_unitHeadETable:sectorUnitHeadETables.TEACH_unitHeadETable,
            OTHER_unitHeadETable:sectorUnitHeadETables.OTHER_unitHeadETable,
        //    单位面积表格排名前五
            SPORT_unitAreaETable:sectorUnitAreaETables.SPORT_unitAreaETable,
            CBD_unitAreaETable:sectorUnitAreaETables.CBD_unitAreaETable,
            HOSPITAL_unitAreaETable:sectorUnitAreaETables.HOSPITAL_unitAreaETable,
            HOTEL_unitAreaETable:sectorUnitAreaETables.HOTEL_unitAreaETable,
            MARKET_unitAreaETable:sectorUnitAreaETables.MARKET_unitAreaETable,
            OFFICE_unitAreaETable:sectorUnitAreaETables.OFFICE_unitAreaETable,
            TEACH_unitAreaETable:sectorUnitAreaETables.TEACH_unitAreaETable,
            OTHER_unitAreaETable:sectorUnitAreaETables.OTHER_unitAreaETable,
        //     echarts 图形
            energyBySector:energyBySector.getDataURL(),
            energyByRegion:energyByRegion.getDataURL(),
            // 各地区 总能耗 人均能耗 平均能耗
            RegionAllEnergyMonths:regionAllEnergyMonths.getDataURL(),
            regionEnergyUnitHeadEMonths:regionEnergyUnitHeadEMonths.getDataURL(),
            regionEnergyUnitAreaEMonths:regionEnergyUnitAreaEMonths.getDataURL(),

            SPORT_unitHeadERank:SPORT_unitHeadERank.getDataURL(),
            CBD_unitHeadERank:CBD_unitHeadERank.getDataURL(),
            HOSPITAL_unitHeadERank:HOSPITAL_unitHeadERank.getDataURL(),
            showit:HOSPITAL_unitHeadERank.getDataURL(),
            HOTEL_unitHeadERank:HOTEL_unitHeadERank.getDataURL(),
            MARKET_unitHeadERank:MARKET_unitHeadERank.getDataURL(),
            OFFICE_unitHeadERank:OFFICE_unitHeadERank.getDataURL(),
            TEACH_unitHeadERank:TEACH_unitHeadERank.getDataURL(),
            OTHER_unitHeadERank:OTHER_unitHeadERank.getDataURL(),

            //单位面积排名图形
            SPORT_unitAreaERank:SPORT_unitAreaERank.getDataURL(),
            CBD_unitAreaERank:CBD_unitAreaERank.getDataURL(),
            HOSPITAL_unitAreaERank:HOSPITAL_unitAreaERank.getDataURL(),
            HOTEL_unitAreaERank:HOTEL_unitAreaERank.getDataURL(),
            MARKET_unitAreaERank:MARKET_unitAreaERank.getDataURL(),
            OFFICE_unitAreaERank:OFFICE_unitAreaERank.getDataURL(),
            TEACH_unitAreaERank:TEACH_unitAreaERank.getDataURL(),
            OTHER_unitAreaERank:OTHER_unitAreaERank.getDataURL(),

            SPORT_unitHeadAreaEHistory:SPORT_unitHeadAreaEHistory.getDataURL(),
            CBD_unitHeadAreaEHistory:CBD_unitHeadAreaEHistory.getDataURL(),
            HOSPITAL_unitHeadAreaEHistory:HOSPITAL_unitHeadAreaEHistory.getDataURL(),
            HOTEL_unitHeadAreaEHistory:HOTEL_unitHeadAreaEHistory.getDataURL(),
            MARKET_unitHeadAreaEHistory:MARKET_unitHeadAreaEHistory.getDataURL(),
            OFFICE_unitHeadAreaEHistory:OFFICE_unitHeadAreaEHistory.getDataURL(),
            TEACH_unitHeadAreaEHistory:TEACH_unitHeadAreaEHistory.getDataURL(),
            OTHER_unitHeadAreaEHistory:OTHER_unitHeadAreaEHistory.getDataURL(),
        };
        lg('dataJson2 ===== > ');
        console.log(dataJson2);

    }


    const exportDoc = ()=>{


        console.log('click the export word button : ------ ');
        // 点击导出word
        function replaceErrors(key, value) {
            if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function(error, key) {
                    error[key] = value[key];
                    return error;
                }, {});
            }
            return value;
        }

        function errorHandler(error) {
            console.log(JSON.stringify({error: error}, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
                const errorMessages = error.properties.errors.map(function (error) {
                    return error.properties.explanation;
                }).join("\n");
                console.log('errorMessages', errorMessages);
                // errorMessages is a humanly readable message looking like this :
                // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
        }

// image start ---
        var opts = {}
        opts.centered = false; //Set to true to always center images
        opts.fileType = "docx"; //Or pptx


// 导出echarts图片，格式转换
        const base64DataURLToArrayBuffer =(dataURL) => {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        }

        opts.getImage = function(chartId){
            return base64DataURLToArrayBuffer(chartId);
        }

//Pass the function that return image size
        opts.getSize = function(img, tagValue, tagName,widX,widY) {
            //img is the image returned by opts.getImage()
            //tagValue is 'examples/image.png'
            //tagName is 'image'
            //tip: you can use node module 'image-size' here
            return [widX?widX:150, widY?widY:150];
        }

        var imageModule = new ImageModule(opts);

// image end -----

        JSZipUtils.getBinaryContent(energyReport_single,function(error, content) {
            // exportTemplate.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
            // 抛出异常
            if (error) {
                throw error;
            }
            // 图片处理
            let opts = {}
            opts.centered = true;  // 图片居中，在word模板中定义方式为{%%image}
            opts.fileType = "docx";
            opts.getImage = function(chartId){
                return base64DataURLToArrayBuffer(chartId);
            }
            opts.getSize = function(img, tagValue, tagName,widX,widY) {
                //img is the image returned by opts.getImage()
                //tagValue is 'examples/image.png'
                //tagName is 'image'
                //tip: you can use node module 'image-size' here

                return [widX?widX:450, widY?widY:450];
            }
            let imageModule = new ImageModule(opts);
            // 创建一个PizZip实例，内容为模板的内容
            let zip = new PizZip(content);
            // 创建并加载docxtemplater实例对象
            let doc = new Docxtemplater();
            doc.attachModule(imageModule);
            doc.loadZip(zip);

            // 设置模板变量的值
            var i = 1;
            console.log('tb');
            //

            doc.setData(dataJson2);

            try {
                // 用模板变量的值替换所有模板变量
                doc.render();
            } catch (error) {
                // 抛出异常
                let e = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    properties: error.properties
                };
                throw error;
            }
            // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
            let out = doc.getZip().generate({
                type: "blob",
                mimeType:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            // 将目标文件对象保存为目标类型的文件，并命名
            saveAs(out, "exportDocx.docx");
        });


    }


    const uploadProps = {
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        showUploadList: false,
        customRequest: (info, a, b) => {
            let fileList = info.file;
            let formdata = new FormData()
            formdata.append('projectid', project.id)
            formdata.append('sensors', fileList)

            Api.post('/import/report',
                formdata
            ).then(({ data }) => {
                console.log(data);
                if (data.code === 0) {
                    message.success(`文件上传成功.`);
                } else {
                    message.error(`文件上传失败.`);
                    message.error(data.message);
                }
            })
        },
    }

    //给region 或者 sector 使用的饼图
    const getOption =(title,legendData,data) =>({
        title: {
            text: title,
            // subtext: '纯属虚构',
            left: 'center'
        },
        tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
            orient: 'vertical',
            left: 'left',
            data: legendData?legendData:['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
        },
        series: [
            {
                name: '访问来源',
                type: 'pie',
                radius: '55%',
                center: ['50%', '60%'],
                animation: false,
                data: data?data: [
                    {value: 335, name: '直接访问'},
                    {value: 310, name: '邮件营销'},
                    {value: 234, name: '联盟广告'},
                    {value: 135, name: '视频广告'},
                    {value: 1548, name: '搜索引擎'}
                ],
                label:{
                    show:true,
                    formatter:function(params){
                        return params.name + ':' + params.percent;
                    }
                },
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                }
            }
        ]
    });
    // 分项能耗用
    const getOption2 =(info)=> {

        var title = info.title;
        var xAxisData = info.xAxisData;
        var dataArr = info.dataArr;
        console.log('dataArr');
        console.log(dataArr);
        var legend = [];
        var data2 = r.map(([region,info])=>{
            legend.push(r.propOr(region,region)(regionJsonConverter));
            return info;
        })(r.toPairs(dataArr));
        lg('legend -- ');
        lg(legend);
        lg(data2);
        var legendId = 0;

        return {
            title: {
                text: title,
                // subtext: '纯属虚构',
                left: 'center'
            },
            legend: {
                // orient: 'vertical',
                // left: 'bottom',
                top:'4%',
                data: legend?legend:['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
            },
            xAxis: {
                type: 'category',
                data: xAxisData?xAxisData:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            // series: [{
            //     data: data?data:[820, 932, 901, 934, 1290, 1330, 1320],
            //     type: 'line'
            // }],
            series: data2?r.map(y=>({data:y,
                name:legend[legendId++],
            type:'line', animation: false}))(data2):[]
        }};
    // 总能耗用
    const getOption3 =(title,axisData,data)=> {

        var yMax = data.length>0?(data[0]+20):20;
        var dataShadow = [];

        for (var i = 0; i < data.length; i++) {
            dataShadow.push(yMax);
        }
        return {
        title: {
            text: title,
            // subtext: ((rankType==='rankAll'?'总耗电量':(rankType === 'unitHeadE'?'人均能耗':'单位面积能耗')))+'月度前五名',
            // textAlign:'right'
            x:'center'
        },
            backgroundColor: '#e8e8e8',
        xAxis: {
            data: axisData,
            axisLabel: {
                // inside: true,
                textStyle: {
                    color: '#161616'
                }
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            z: 10
        },
        yAxis: {
            // data:dataAxis,
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                // inside:false,
                textStyle: {
                    color: '#323232'
                }
            }
        },
        dataZoom: [
            {
                type: 'inside'
            }
        ],
        series: [
            { // For shadow
                type: 'bar',
                itemStyle: {
                    color: 'rgba(0,0,0,0.05)'
                },
                barGap: '-100%',
                barCategoryGap: '40%',
                data: dataShadow,
                animation: false
            },
            {
                type: 'bar',
                animation: false,
                itemStyle: {
                    normal: {
                        label: {
                            show: true, //开启显示
                            position: 'top', //在上方显示
                            // 	textStyle: { //数值样式
                            // // 		color: 'black',
                            // 		fontSize: 16
                            // 	}
                        },
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#83bff6'},
                                {offset: 0.5, color: '#188df0'},
                                {offset: 1, color: '#188df0'}
                            ]
                        )
                    },
                },
                emphasis: {
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#2378f7'},
                                {offset: 0.7, color: '#2378f7'},
                                {offset: 1, color: '#83bff6'}
                            ]
                        )
                    }
                },
                data: data
            }
        ]
    }};
    // 历史数据使用
    const getOption4 =(title,legendData,axisData,data)=> {

        // var title = info.title;
        // var xAxisData = info.xAxisData;
        // var dataArr = info.dataArr;
        // console.log('dataArr');
        // console.log(dataArr);
        // var legend = [];
        // var data2 = r.map(([region,info])=>{
        //     legend.push(r.propOr(region,region)(regionJsonConverter));
        //     return info;
        // })(r.toPairs(dataArr));
        // lg('legend -- ');
        // lg(legend);
        // lg(data2);
        var legendId = 0;

        return {
            title: {
                text: title,
                // subtext: '纯属虚构',
                left: 'center'
            },
            legend: {
                // orient: 'vertical',
                // left: 'bottom',
                top:'4%',
                data: legendData?legendData:['直接访问', '邮件营销', '联盟广告', '视频广告', '搜索引擎']
            },
            xAxis: {
                type: 'category',
                data: axisData?axisData:['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            // series: [{
            //     data: data?data:[820, 932, 901, 934, 1290, 1330, 1320],
            //     type: 'line'
            // }],
            series: data?r.map(y=>({data:y,
                name:legendData[legendId++],
                type:'line', animation: false}))(data):[]
        }};

    const onChange = (date) => {
        console.log('date');
        console.log(date);
        dateArr[0] = date[0] ? date[0].format('YYYYMMDD') : moment();
        dateArr[1] = date[1] ? date[1].format('YYYYMMDD') : moment();
        months = monthInBetween(moment(dateArr[0],'YYYYMM'),moment(dateArr[1],'YYYYMM'));
        monthsHistory = monthInBetween(moment(dateHistoryArr[0],'YYYYMM'),moment(dateHistoryArr[1],'YYYYMM'));
    }
    const onChangeHistory = (date) => {
        console.log('history date --- ');
        console.log(date);
        dateHistoryArr[0] = date[0] ? date[0].format('YYYYMMDD') : moment();
        dateHistoryArr[1] = date[1] ? date[1].format('YYYYMMDD') : moment();
        months = monthInBetween(moment(dateArr[0],'YYYYMM'),moment(dateArr[1],'YYYYMM'));
        monthsHistory = monthInBetween(moment(dateHistoryArr[0],'YYYYMM'),moment(dateHistoryArr[1],'YYYYMM'));
    }



    return (
		<div className="newReport">
            <div>
                <h4>模块管理</h4>
                <Button  type="primary" onClick={()=>loadData()} >装载</Button>
                <Button type="primary" onClick={()=>exportDoc()}>导出</Button>
                <Upload {...uploadProps}>
                    <Button type="primary" >导入</Button>
                </Upload>
            </div>
            {/*<div className='echartsDiv' id='energyBySector' style={style3}>*/}
            {/*</div>*/}

            <div className='echartsDiv' id='energyBySector' style={style2}>
            </div>
            <div className='echartsDiv' id='energyByRegion' style={style2}>
            </div>
            <div className='echartsDiv' id='regionAllEnergyMonths' style={style2}>
            </div>
            <div className='echartsDiv' id='regionEnergyUnitHeadEMonths' style={style2}>
            </div>
            <div className='echartsDiv' id='regionEnergyUnitAreaEMonths' style={style2}>
            </div>

            {/* 各种类型数据排列 */}
            <div className='echartsDiv' id='SPORT_unitHeadERank' style={style2}>
            </div>
            <div className='echartsDiv' id='CBD_unitHeadERank' style={style2}>
            </div>
            <div className='echartsDiv' id='HOSPITAL_unitHeadERank' style={style2}>
            </div>
            <div className='echartsDiv' id='HOTEL_unitHeadERank' style={style2}>
            </div>

            <div className='echartsDiv' id='MARKET_unitHeadERank' style={style2}>
            </div>
            <div className='echartsDiv' id='OFFICE_unitHeadERank' style={style2}>
            </div>
            <div className='echartsDiv' id='TEACH_unitHeadERank' style={style2}>
            </div>

            <div className='echartsDiv' id='OTHER_unitHeadERank' style={style2}>
            </div>

            {/* 各种类型数据排列 单位面积 20230713*/}
            <div className='echartsDiv' id='SPORT_unitAreaERank' style={style2}>
            </div>
            <div className='echartsDiv' id='CBD_unitAreaERank' style={style2}>
            </div>
            <div className='echartsDiv' id='HOSPITAL_unitAreaERank' style={style2}>
            </div>
            <div className='echartsDiv' id='HOTEL_unitAreaERank' style={style2}>
            </div>

            <div className='echartsDiv' id='MARKET_unitAreaERank' style={style2}>
            </div>
            <div className='echartsDiv' id='OFFICE_unitAreaERank' style={style2}>
            </div>
            <div className='echartsDiv' id='TEACH_unitAreaERank' style={style2}>
            </div>

            <div className='echartsDiv' id='OTHER_unitAreaERank' style={style2}>
            </div>

            {/*历年数据排列*/}
            <div className='echartsDiv' id='SPORT_unitHeadAreaEHistory' style={style2}>
            </div>
            <div className='echartsDiv' id='CBD_unitHeadAreaEHistory' style={style2}>
            </div>
            <div className='echartsDiv' id='HOSPITAL_unitHeadAreaEHistory' style={style2}>
            </div>
            <div className='echartsDiv' id='HOTEL_unitHeadAreaEHistory' style={style2}>
            </div>

            <div className='echartsDiv' id='MARKET_unitHeadAreaEHistory' style={style2}>
            </div>
            <div className='echartsDiv' id='OFFICE_unitHeadAreaEHistory' style={style2}>
            </div>
            <div className='echartsDiv' id='TEACH_unitHeadAreaEHistory' style={style2}>
            </div>
            <div className='echartsDiv' id='OTHER_unitHeadAreaEHistory' style={style2}>
            </div>

            <div>
                <Form className='newReportForm'>
                    <Row>
                        <div>

                            <div>
                                <span>数据时间 ：</span>
                                <RangePicker
                                    defaultValue={[moment().subtract(6,'month').startOf('month'),
                                        moment().subtract(1,'month').endOf('month')]}
                                    onChange={onChange}
                                    disabledDate={disabledDate}
                                />
                            </div>
                            <div>
                                <span>历史数据时间 ：</span>
                                <RangePicker
                                    defaultValue={[moment().subtract(6,'month').startOf('month'),
                                        moment().subtract(1,'month').endOf('month')]}
                                    onChange={onChangeHistory}
                                    disabledDate={disabledDate}
                                />
                            </div>
                            <Form.Item {...formItemLayout}
                                       label="项目名称"
                            >
                                {getFieldDecorator('name', {
                                    initialValue: project.name,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="人数"
                            >
                                {getFieldDecorator('headCount', {
                                    initialValue: project.headCount,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="面积"
                            >
                                {getFieldDecorator('totalArea', {
                                    initialValue: project.totalArea,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="项目类型"
                            >
                                {getFieldDecorator('sector', {
                                    initialValue: project.sector,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="经度"
                            >
                                {getFieldDecorator('longitude2', {
                                    initialValue: project.longitude2,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="纬度"
                            >
                                {getFieldDecorator('latitude2', {
                                    initialValue: project.latitude2,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="区域"
                            >
                                {getFieldDecorator('region', {
                                    initialValue: project.region,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="建筑id"
                            >
                                {getFieldDecorator('buildingId', {
                                    initialValue: project.buildingId,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="地址"
                            >
                                {getFieldDecorator('locate', {
                                    initialValue: project.locate,
                                    rules: [{required: false}],
                                })(
                                    <Input disabled={true}/>
                                )}

                            </Form.Item>
                            <Form.Item {...formItemLayout}
                                       label="最高层数"
                            >
                                {getFieldDecorator('floors', {
                                    initialValue: project.floors,
                                    // rules: [{required: false}],
                                })(
                                    <Input />
                                )}

                            </Form.Item>


                            <Form.Item {...formItemLayout}
                                       label="载入"
                            >
                                {getFieldDecorator('load', {
                                    initialValue: project.load,
                                })(
                                    <Radio.Group>
                                        <Radio value={1}>载入</Radio>
                                        <Radio value={2}>不载入</Radio>
                                    </Radio.Group>
                                )}
                            </Form.Item>
                        </div>

                    </Row>
                </Form>




                <Upload {...uploadProps}>
                    <Button>导入</Button>
                </Upload>
                <Button onClick={()=>loadData()}>导出</Button>
            </div>

		</div>
	);
}

export default UpdateReportForm;