import React from 'react';
import { from } from 'rxjs';
import './buildingStructureAddNode.scss';
import withStates from 'src/context/withStates';
import Api from 'api';
import * as r from 'ramda';
import BuildingStructureAddNodeRight from './buildingStructureAddNodeRight/buildingStructureAddNodeRight';
const ROOT_NODE = '0';

const BuildingStructureAddNode = () => (
  <div className="buildingStructureAddNode">
    <BuildingStructureAddNodeRight />
  </div>
)


const treeViewData = async () => {
  const { data: { result = [] } } = await Api.post('/customer/info', {
    project: Api.projectId,
  });

  const treeFn = v => r.map(({ title, id, count, child, formula }) => {
    return { title, id, key: id, count, information: {title, id, formula}, formula, children: r.isEmpty(r.filter(r.where({ type: r.equals('NODE') }))(child)) && r.where({ type: r.equals('NODE') }) ? NaN : treeFn(r.values(child)) }
  })(r.filter(r.where({ type: r.equals('NODE') }))(v))

  return {
    searchIsShow: false,
    loading: false,
    node: ROOT_NODE,
    tree: [
      {
        title: Api.projectName,
        key: ROOT_NODE,
        id: ROOT_NODE,
        count: result.length,
        children: treeFn(result),
      },
    ],
  }
}

const loadBuildingStructureAddNode = (state) => {
  const input = treeViewData(state)
  return from(input)
}

export const loadTreeViewData = (state) => {
  return from(treeViewData(state)
    .then(res => ({ ...state, ...res })))
}

const initState = {
  loading: true,
  node: ROOT_NODE,
  tree: [],
  dataSource: []
}

export const { context, Component } = withStates(
  BuildingStructureAddNode,
  initState,
  loadBuildingStructureAddNode,
)

export default Component
