
import React from 'react';
import DepartmentStateAddNodeSearch from './departmentStateAddNodeRightTableSearch/departmentStateAddNodeRightTableSearch';
import DepartmentStateAddNodeTable from './departmentStateAddNodeRightTable/departmentStateAddNodeRightTable';
const DepartmentStateAddNodeRight = () => (
    <div className='departmentStateAddNodeRight'>
        <DepartmentStateAddNodeSearch />
        <DepartmentStateAddNodeTable />
    </div>
)
export default DepartmentStateAddNodeRight
