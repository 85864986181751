import React from 'react';
import {from} from 'rxjs';
import './departmentState.scss';
import withStates from 'src/context/withStates';
import Api from 'api';
import * as r from 'ramda';
import DepartmentStateTree from './departmentStateTree/departmentStateTree';
import DepartmentStateRight from './departmentStateRight/departmentStateRight';
import {extractDevices, extractNodes, searchFilter} from "src/context/society";
import {formatTree} from "src/context/treeView";

const ROOT_NODE = '0';

const DepartmentState = () => (
  <div className="departmentState">
    <DepartmentStateTree/>
    <DepartmentStateRight/>
  </div>
)


const departmentState = async ({node = ROOT_NODE, search = '', selectTableType}) => {
  const {data} = await Api.get(`/api/v3/project/${Api.projectId}/department`);
  const dataSource = selectTableType === 'device' ?
    extractDevices(data, node) :
    extractNodes(r.map(r.pickAll(['id', 'formula']))(data), ROOT_NODE)
  return {
    loading: false,
    dataSource: searchFilter(dataSource)([search]),
    devices: extractDevices(data, ROOT_NODE),
    searchIsShow: false,
    node,
    tree: [
      {
        title: Api.projectName,
        key: ROOT_NODE,
        children: formatTree(ROOT_NODE)(data),
      },
    ]
  }
}

export const loadDepartmentState = (state) => {
  return from(departmentState(state)
    .then(res => ({...state, ...res})))
}

const initState = {
  loading: true,
  node: ROOT_NODE,
  tree: [],
  dataSource: [],
  selectTableType: 'device'
}

export const {context, Component} = withStates(
  DepartmentState,
  initState,
  loadDepartmentState,
)

export default Component
