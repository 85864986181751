import React, { useContext } from 'react';
import { Icon, Table } from 'antd'
import { Link } from "react-router-dom";
import Api from 'api';
import './reportAll.scss'

import moment from 'moment'
import { from } from 'rxjs'
import withStates from 'src/context/withStates'
import * as r from 'ramda'
// import IndexHead from './projectHead/projectHead';
import ManagementReportAll from './managementReportAll';
import Delete from './delete';
// import Model from './model'

import Util2  from 'src/util/util2';
const regionJsonConverter = Util2.regionJsonConverter;

const width = 80;
const width2 = 10;
const columns = [{
  title: '序号',
  dataIndex: 'index',
  key: 'index',
    width:30
}, {
  title: '项目名称',
  dataIndex: 'name',
  key: 'name',
    width:120
}, {
  title: '建筑数量',
  dataIndex: 'num',
    ellipsis: true,
    className: 'hide',
  key: 'num',
    width:120
}, {
  title: '人数',
  dataIndex: 'headCount',
  key: 'headCount',
    width:120
}, {
  title: '面积',
  dataIndex: 'totalArea',
  key: 'totalArea',
    width:120
}, {
  title: '项目类型',
  dataIndex: 'sector',
  key: 'sector',
    width:120
}, {
  title: '经度',
  dataIndex: 'longitude2',
  key: 'longitude2',
    width:120
}, {
  title: '纬度',
  dataIndex: 'latitude2',
  key: 'latitude2',
    width:120
}, {
  title: '区域',
  dataIndex: 'region',
  key: 'region',
    width:120
}, {
  title: '建筑id',
  dataIndex: 'buildingId',
  key: 'buildingId',
    width:120
},{
  title: '地址',
  dataIndex: 'locate',
  key: 'locate',
    width:120
}, {
  title: '最高层数',
  dataIndex: 'floors',
  key: 'floors',
    width:120
}, {
  title: '楼层(楼栋)',
  dataIndex: 'funcLocate',
  key: 'funcLocate',
    ellipsis: true,
    className: 'hide',
    width:120
}, {
  title: '公司(功能区)',
  dataIndex: 'funcDomain',
  key: 'funcDomain',
    ellipsis: true,
    className: 'hide',
    width:120
}, {
  title: '操作',
  dataIndex: 'action',
  key: 'action',
    width:width2,
  render: (text, record) => {
    return <div><ManagementReportAll record={record} /></div>
  }
},
    {
  title: 'id',
  dataIndex: 'id',
  key: 'id',
  className: 'hide',
}


//     {
//   title: '创建时间',
//   dataIndex: 'createTime',
//   key: 'createTime',
//     width:width
// }, {
//   title: '截止日期',
//   dataIndex: 'stopTime',
//   key: 'stopTime',
//     width:width
// }, {
//   title: '质保类型',
//   dataIndex: 'type',
//   key: 'type',
//     width:width
// }, {
//   title: '质保状态',
//   dataIndex: 'state',
//   key: 'state',
//     width:width,
//   render: (text) => {
//     let classN = '';
//     switch (text) {
//       case '维保期':
//         classN = 'blue'
//         break;
//       case '逾期':
//         classN = 'red'
//         break;
//       case '质保期':
//         classN = 'green'
//         break;
//       default:
//         classN = 'green'
//         break;
//     }
//     return <span className={classN}>&#183; {text}</span>
//   }
// },
//     {
//   title: '管理器数量',
//   dataIndex: 'gNub',
//   key: 'gNub',
//     width:width
// }, {
//   title: '仪表数量',
//   dataIndex: 'yNub',
//   key: 'yNub',
//     width:width
// }, {
//   title: '项目属性',
//   dataIndex: 'attribute',
//   key: 'attribute',
//     width:width
// }, {
//   title: '项目来源',
//   dataIndex: 'from',
//   key: 'from',
//     width:width
// },  {
//   title: '归属区域',
//   dataIndex: 'region',
//   key: 'region',
//     width:width2,
//     ellipsis: true,
//     className: 'hide',
//     // colSpan:0
// },{
//   title: '地理建筑编号',
//   dataIndex: 'building',
//   key: 'building',
//     width:width2,
//     ellipsis: true,
//     className: 'hide',
//     // colSpan:0
// },{
//   title: '项目经度',
//   dataIndex: 'longitude2',
//   key: 'longitude2',
//     width:width2,
//     ellipsis: true,
//     className: 'hide',
//     // colSpan:0
// },{
//   title: '项目纬度',
//   dataIndex: 'latitude2',
//   key: 'latitude2',
//     width:width2,
//     ellipsis: true,
//     className: 'hide',
//     // colSpan:0
// },{
//   title: '父节点编号',
//   dataIndex: 'parent',
//   key: 'parent',
//     width:width2,
//     ellipsis: true,
//     className: 'hide',
//     // colSpan:0
// },{
//   title: '路径编号',
//   dataIndex: 'path',
//   key: 'path',
//     width:width2,
//     ellipsis: true,
//     className: 'hide',
//     // colSpan:0
// }, {
//   title: '操作',
//   dataIndex: 'action',
//   key: 'action',
//     width:width2,
//   render: (text, record) => {
//     return <div><ManagementReport record={record} /><Delete record={record} /></div>
//   }
// }, {
//   title: '备注',
//   dataIndex: 'description',
//   key: 'description',
// },
// {
//   title: 'id',
//   dataIndex: 'id',
//   key: 'id',
//   className: 'hide',
// }
];

const projectType = type => {
  const typeMap = {
    BASIC: "计量基础版",
    ANALYSIS: "能源分析版"
  }
  return r.propOr(type)(type)(typeMap)
}
const qualityGuaranteeState = state => {
  const stateMap = {
    normal: "质保期",
    extend: "维保期",
    expired: "逾期"
  }
  return r.propOr(state)(state)(stateMap)
}
const projectState = state => {
  const stateMap = {
      SPORT: '体育建筑',
      CBD: '综合建筑',
      HOSPITAL: '医疗卫生建筑',
      HOTEL: '宾馆饭店建筑',
      MARKET: '商场建筑',
      OFFICE: '办公建筑',
      TEACH: '文化教育建筑',
      OTHER: '其它建筑',

  }
  return r.propOr(state)(state)(stateMap)
}
const projectSource = source => {
  const sourceMap = {
    SELF: '自有',
    AGENT: '代理商',
    FACTORY: '厂家',
    INTEG: '集成商',
    RUNOP: '运营方',
    NMKT: '网络销售',

  }
  return r.propOr(source)(source)(sourceMap)
}


const ReportAll = () => {
  const { state: { data, loading } } = useContext(context);
  return (
    <div className="reportAll">
      {/*<IndexHead />*/}
      <p className='addNewReport'>
        <Link to='/NewReport'>
          <Icon type="plus" style={{ fontSize: '16px', color: '#8B93A7', padding: '7px' }} />新建模板
          </Link>
        <span>{data.length}个结果</span>
      </p>
      <Table columns={columns} dataSource={data} className='table' loading={loading} />
    </div>
  )
}


const tableData = async () => {
  // const { data: result } = await Api.get("/api/v3/project");
  const { data: result } = await Api.get("/api/v3/allProjectInfo1");
  let tableData=[],i=0;
  for(var k in result){
      tableData.push({
          id: k,
          key: i,
          index: `${i + 1}`.padStart(2, '0'),
          num: result[k].num,
          headCount: result[k].headCount,
          totalArea: result[k].totalArea,
          sector: projectState(result[k].sector),
          name: result[k].title,
          latitude2: result[k].latitude2,
          longitude2: result[k].longitude2,
          region: (result[k].region)?regionJsonConverter[result[k].region]:'' ,
          buildingId: result[k].building,
          locate: result[k].locate,
          floors: result[k].floors,
          funcLocate: result[k].funcLocate,
          funcDomain: result[k].funcDomain,
      });
      i++;
  }
  // tableData = result.map((k, i) => (
  //   {
  //     key: i,
  //     index: `${i + 1}`.padStart(2, '0'),
  //     name: k.title,
  //     createTime: moment(k.timecreate * 1000).format('YYYY-MM-DD'),
  //     stopTime: moment(k.warranty.expireDate).format('YYYY-MM-DD'),
  //     type: projectType(k.type),
  //     state: qualityGuaranteeState(k.warranty.type),
  //     model: k.status,
  //     gNub: k.statistic.collectors,
  //     yNub: k.statistic.devices,
  //     attribute: projectState(k.sector),
  //     from: projectSource(k.source),
  //     id: k._id,
  //     description: k.description,
  //     modules: k.modules,
  //     originalType: k.type,
  //     //--  'longitude2','latitude2','region','building','parent','path',
  //     region:(k.projectLocate.region)?regionJsonConverter[k.projectLocate.region]:'',
  //     building:k.projectLocate.building ,
  //     longitude2:k.projectLocate.longitude2,
  //     latitude2:k.projectLocate.latitude2,
  //     parent:k.regionTopology.parent,
  //     path:k.regionTopology.path,
  //
  //   }
  // ))

  return {
    loading: false,
    data: tableData,
  }
}


const loadProject = (state) => {
  const input = tableData(state)
    .then(r.mergeAll)
  return from(input)
}

export const loadUpdateData = (state) => {
  return from(tableData(state)
    .then(res => ({ ...state, ...res })))
}
const initState = {
  loading: true,
  data: [],
  updata: false,
  search: ''
}

export const { context, Component } = withStates(
  ReportAll,
  initState,
  loadProject,
)

export default Component