
import React from 'react';
import ShopsRightTop from './shopsRightTop/shopsRightTop';
import ShopsRightBottom from './shopsRightBottom/shopsRightBottom';
const ShopsRight = () => (
    <div className='shopsRight'>
        <ShopsRightTop />
        <ShopsRightBottom />
    </div>
)
export default ShopsRight
