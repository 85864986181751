import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import { Dropdown, Icon, Menu, Select, Spin } from 'antd';
import cookie from 'react-cookies';
import IconFont from 'src/font/font';
import Api from 'api';
import * as r from 'ramda';
import AccountEdit from '../account/accountEdit/accountEdit'
import './nav.scss';
import Img_R from 'img/r.png';
import { isRoot,isRegion, moduleNavigation, setCurrentProject } from "src/context/NavItem";
import { context } from "src/router";

const { Option } = Select


//nax
const loginOut = () => {
  const domain = window.location.hostname.replace(/^\w+\./, '')
  cookie.remove('isLogin', { path: '/' }, domain);
  cookie.remove('isRegion', { path: '/' }, domain);
  cookie.remove('region', { path: '/' }, domain);
  cookie.remove('projectId', { path: '/' }, domain);
  cookie.remove('projectName', { path: '/' }, domain);
  cookie.remove('token', { path: '/' }, domain);
  cookie.remove('uid', { path: '/' }, domain);
  window.sessionStorage.clear();
}

const onSelect = (value, key) => {
  console.log('onSelect', key);
  setCurrentProject(value, key.props.children)
  window.location.reload()
}
//区域管理员后台不能切换，避免删除相关账户数据
const onSelectRegion = (value, key) => {
  console.log('onSelect', key);
  alert('区域管理员仅能查看列表');
}

const menu = (
  <Menu>
    <Menu.Item>
      <a href='https://www.wznhjc.cn/'><Icon type="logout" />前往前台</a>
    </Menu.Item>
    <Menu.Item onClick={loginOut}>
      <Link to='/Login'><Icon type="logout" />退出登录</Link>
    </Menu.Item>
  </Menu>
);

const navItems = {
  information: <li key='information'>
    <NavLink activeClassName='active' to='/Index'>
      <IconFont type='icon-xinxigailan' />
      <span>信息概览</span>
    </NavLink>
  </li>,
  project: <li key='project'>
    <NavLink activeClassName='active' to='/Project'>
      <IconFont type='icon-wenzhanghuoke-' />
      <span>项目管理</span>
    </NavLink>
  </li>,
  building: <li key='building'>
    <NavLink activeClassName='active' to='/Building'>
      <IconFont type='icon-jianzhuguanli' />
      <span>建筑管理</span>
    </NavLink>
  </li>,
  account: <li key='account'>
    <NavLink activeClassName='active' to='/Account'>
      <IconFont type='icon-zhanghaoguanli' />
      <span>账号管理</span>
    </NavLink>
  </li>,
  collector: <li key='collector'>
    <NavLink activeClassName='active' to='/Manager'>
      <IconFont type='icon-ziyuanguanliqi' />
      <span>管理器</span>
    </NavLink>
  </li>,
  billingservice: <li key='billingservice'>
    <NavLink activeClassName='active' to='/BillingService'>
      <IconFont type='icon-accounting_strategy' />
      <span>计费策略</span>
    </NavLink>
  </li>,
  byitem: <li key='byitem'>
    <NavLink activeClassName='active' to='/Byitem'>
      <IconFont type='icon-fenxiangyongneng' />
      <span>分项用能</span>
    </NavLink>
  </li>,
  node: <li key='node'>
    <NavLink activeClassName='active' to='/BuildingStructure'>
      <IconFont type='icon-jianzhujiegou' />
      <span>建筑结构</span>
    </NavLink>
  </li>,
  bybuilding: <li key='bybuilding'>
    <NavLink activeClassName='active' to='/BuildingState'>
      <IconFont type='icon-analysis' />
      <span>建筑分析</span>
    </NavLink>
  </li>,
  byapart: <li key='byapart'>
    <NavLink activeClassName='active' to='/DepartmentState'>
      <IconFont type='icon-zonghefenxi' />
      <span>部门分析</span>
    </NavLink>
  </li>,
  report: <li key='report'>
    <NavLink activeClassName='active' to='/Report'>
      <IconFont type='icon-zonghefenxi' />
      <span>单项报告</span>
    </NavLink>
  </li>,
  reportAll: <li key='reportAll'>
    <NavLink activeClassName='active' to='/ReportAll'>
      <IconFont type='icon-zonghefenxi' />
      <span>总体报告</span>
    </NavLink>
  </li>,
    editMeter: <li key='editMeter'>
    <NavLink activeClassName='active' to='/EditMeter'>
      <IconFont type='icon-zonghefenxi' />
      <span>电表信息</span>
    </NavLink>
  </li>,
}

const Nav = () => {
  const { state: { thisProject, projects,regionProjects } } = useContext(context)
  return (
    <div className='nav'>
      <nav>
        <div className="navHead">
          <img src={Img_R} className='userHead' alt="智慧云能源" />
          <p>{window.sessionStorage.getItem('projectName')} </p>
          <AccountEdit name={'修改密码'} record={{ all: { user: window.sessionStorage.getItem('uid') } }} />
        </div>
        {
          r.isNil(thisProject) ? <Spin />
            : <ul>{isRoot() ? r.values(navItems) : r.values(r.omit(r.difference(r.keys(navItems), thisProject.modules))(navItems))}</ul>
        }
      </nav>
      <div className="user">
        {isRoot() ? <Select
          showSearch
          style={{ minWidth: '200px', float: 'left', marginLeft: '20px', marginTop: '14px' }}
          placeholder="Select a project"
          optionFilterProp="children"
          onSelect={onSelect}
          defaultValue={Api.projectId}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {r.map(r.pick(['_id', 'title']))(r.flatten([projects])).map((k, i) => <Option value={k._id} key={i}>{k.title}</Option>)}
        </Select> : (isRegion()?<Select
            showSearch
            style={{ minWidth: '200px', float: 'left', marginLeft: '20px', marginTop: '14px' }}
            placeholder="Select a project"
            optionFilterProp="children"

            onSelect={onSelectRegion}
            defaultValue={Api.projectId}
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {r.map(r.pick(['projectId', 'projectName']))(r.flatten([regionProjects])).map((k, i) => <Option value={k.projectId} key={i}>{k.projectName}</Option>)}
        </Select>:'')}
        {/*增加对区域用户的判断*/}
        <div className='userDiv'>
          <Dropdown overlay={menu} placement="bottomCenter">
            <div>
              <img src={Img_R} className='userHead' alt="智慧云能源" />
              <span>{window.sessionStorage.getItem('projectName')}</span>
              <Icon type="caret-down" className='imgDown' />
            </div>
          </Dropdown>
        </div>
      </div>
    </div >
  )
}



export default Nav;

