import React from 'react';
import { from } from 'rxjs';
import './buildingStateAddNode.scss';
import withStates from 'src/context/withStates';
import Api from 'api';
import * as r from 'ramda';
import BuildingStateAddNodeRight from './buildingStateAddNodeRight/buildingStateAddNodeRight';
const ROOT_NODE = '0';

const BuildingStateAddNode = () => (
  <div className="buildingStateAddNode">
    <BuildingStateAddNodeRight />
  </div>
)


const treeViewData = async () => {
  const { data: { result = [] } } = await Api.post('/eca/info', {
    projectid: Api.projectId,
    type: "BYBUILDING"
  });

  const treeFn = v => r.map(({ title, id, count, path, child }) => {
    return { title, id, key: id, count, path, children: r.isEmpty(r.filter(r.where({ type: r.equals('BYBUILDING') }))(child)) && r.where({ type: r.equals('BYBUILDING') }) ? NaN : treeFn(r.values(child)) }
  })(r.filter(r.where({ type: r.equals('BYBUILDING') }))(v))

  return {
    searchIsShow: false,
    loading: false,
    node: ROOT_NODE,
    tree: [
      {
        title: Api.projectName,
        key: ROOT_NODE,
        id: ROOT_NODE,
        path: '',
        count: result.length,
        children: treeFn(result),
      },
    ],
  }
}

const loadBuildingStateAddNode = (state) => {
  const input = treeViewData(state)
  return from(input)
}

export const loadTreeViewData = (state) => {
  return from(treeViewData(state)
    .then(res => ({ ...state, ...res })))
}

const initState = {
  loading: true,
  node: ROOT_NODE,
  tree: [],
  dataSource: []
}

export const { context, Component } = withStates(
  BuildingStateAddNode,
  initState,
  loadBuildingStateAddNode,
)

export default Component
