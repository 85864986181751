import React, {useContext, useEffect, useState} from "react";
import * as r from "ramda";
import {from} from 'rxjs'
import {Redirect, Route} from "react-router-dom";

import Index from 'src/modules/index/index';
import Manager from 'src/modules/manager/manager';
import Report from 'src/modules/report/report';
import NewReport from 'src/modules/report/newReport/newReport';
import reportAll from 'src/modules/reportAll/reportAll';
import editMeter from 'src/modules/editMeter/editMeter';
import Project from 'src/modules/project/project';

import Account from 'src/modules/account/account';
import System from 'src/modules/system/system';
import NewProject from 'src/modules/newProject/newProject';
import Shops from 'src/modules/shops/shops';
import Building from 'src/modules/building/building';
import BuildingState from 'src/modules/buildingState/buildingState';
import DepartmentState from 'src/modules/departmentState/departmentState';
import BillingService from 'src/modules/billingService/billingService';
import Byitem from 'src/modules/byitem/byitem';
import BuildingStructure from 'src/modules/buildingStructure/buildingStructure';
import BuildingStructureAddNode from 'src/modules/buildingStructureAddNode/buildingStructureAddNode';
import BuildingStateAddNode from 'src/modules/buildingStateAddNode/buildingStateAddNode';
import DepartmentStateAddNode from 'src/modules/departmentStateAddNode/departmentStateAddNode';
import NewBillingService from 'src/modules/newBillingService/newBillingService';
import NewShops from 'src/modules/newShops/newShops';

import Nav from "src/modules/nav/nav";

import cookie from 'react-cookies';
import {currentProject, isRoot, moduleNavigation} from "src/context/NavItem";
import {Spin} from "antd";
import Home from "src/Home";
import withStates from "src/context/withStates";

const routeNames = {
  information: '/Index',
  account: '/Account',
  collector: '/Manager',
  report: '/Report',
  reportAll: '/reportAll',
    editMeter: '/EditMeter',
  billingservice: '/BillingService',
  byitem: '/Byitem',
  building: '/BuildingStructure',
  byapart: '/DepartmentState',
  bybuilding: '/BuildingState',
  node: '/BuildingStructure',
}

export const firstTopRoute = modules => {
  return isRoot() ? r.head(r.values(routeNames)) : r.head(r.values(r.pick(modules)(routeNames)))
}

export const topRoutes = {
  information: <PrivateRoute key='information' path="/Index" component={Index}/>,
  account: <PrivateRoute key='account' path="/Account" component={Account}/>,
  collector: <PrivateRoute key='collector' path="/Manager" component={Manager}/>,
  billingservice: [<PrivateRoute key='billingservice' path="/BillingService" component={BillingService}/>,
    <PrivateRoute key='billingservice-add' path="/NewBillingService" component={NewBillingService}/>],
  byitem: <PrivateRoute key='byitem' path="/Byitem" component={Byitem}/>,
  building: <PrivateRoute key='building' path="/Building" component={Building}/>,
  node: [<PrivateRoute key='node' path="/BuildingStructure" component={BuildingStructure}/>,
    <PrivateRoute key='node-add' path="/BuildingStructureAddNode" component={BuildingStructureAddNode}/>],
  bybuilding: [<PrivateRoute key='bybuilding' path="/BuildingState" component={BuildingState}/>,
    <PrivateRoute key='bybuilding-add' path="/BuildingStateAddNode" component={BuildingStateAddNode}/>],
  byapart: [<PrivateRoute key='byapart' path="/DepartmentState" component={DepartmentState}/>,
    <PrivateRoute key='byapart-add' path="/DepartmentStateAddNode" component={DepartmentStateAddNode}/>],
  project: [<PrivateRoute key='project-system' path="/System" component={System}/>,
    <PrivateRoute key='project-add' path="/NewProject" component={NewProject}/>,
    <PrivateRoute key='project' path="/Project" component={Project}/>,
    <PrivateRoute key='project-shops' path="/Shops" component={Shops}/>,
    <PrivateRoute key='project-shops-new' path="/NewShops" component={NewShops}/>],
    report: [
        <PrivateRoute key='report' path="/Report" component={Report}/>,
        <PrivateRoute key='newReport' path="/NewReport" component={NewReport}/>,
    ],
    reportAll: <PrivateRoute key='reportAll' path="/reportAll" component={reportAll}/>,
    editMeter: <PrivateRoute key='editMeter' path="/editMeter" component={editMeter}/>,
}


const AppRouter = () => {
  const {state: {thisProject}} = useContext(context)
  return (
    <div style={{height: "100%"}}>
      <Nav/>
      <div className="container">
        {
          r.isNil(thisProject) ? <Spin/>
            : isRoot() ? r.flatten(r.values(topRoutes)) : r.flatten(r.values(r.pickAll(thisProject.modules)(topRoutes)))
        }
        <PrivateRoute exact path="/" component={Home}/>
      </div>
    </div>)
}


function PrivateRoute({component: Component, ...rest}) {
  return (
    <Route
      {...rest}
      render={props =>
        cookie.load('isLogin') === 'sbfui20' && cookie.load('projectId') && cookie.load('projectName') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/Login",
              state: {from: props.location}
            }}
          />
        )
      }
    />
  );
}

export const loadProjects = () => {
    let flag = true;
    if( flag ){
        return from(moduleNavigation().then(data => ({// from 是从这里获取并赋值的方法
            projects:data[0],
            regionProjects:data[1],
            thisProject: currentProject(data[0])
        })))
    }
  return from(moduleNavigation().then(projects => ({// from 是从这里获取并赋值的方法
    projects,
    thisProject: currentProject(projects)
  })))
    // 上面是原先的代码

}
// 对区域数据进行赋值

const initState = {
  thisProject: null,
  projects: [],
    regionProjects:[]//增加对用用户的区域项目列表
}
export const { context, Component } = withStates(
  AppRouter,
  initState,
  loadProjects,
)

export default Component