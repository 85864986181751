import React, { useState, useContext } from 'react';
import { Icon, Input, Select, Modal, Col, Row, Form, message } from 'antd';
import * as r from 'ramda';
import Api from 'api';
import { of } from 'rxjs';
import { loadupdateData, context } from '../account'
import { userPermissionFormat } from 'src/components/typeCast/typeCast.js'

import './addNewAccount.scss';


const Option = Select.Option;
const addNewAccount = () => {
    const { dispatch } = useContext(context);
    const [visible, setVisible] = useState(false);
    const [forRef, setForRef] = useState({ from: '' });

    const showModal = () => {
        setVisible(true)
    };
    const handleCancel = () => {
        setVisible(false)
    };
    const handleCreate = () => {
        const { form } = forRef.props;
        form.validateFields(async (err, values) => {
            const { title, confirm, id, permission, tel } = values
            if (err) {
                return;
            }

            const res = await Api.post('/account/add', {
                user: title,
                passwd: confirm,
                title: id,
                character: permission,
                mobile: tel
            })
            const msg = res.data.message === '' ? '创建成功' : res.data.message;
            message.info(msg);
            if (msg === '创建成功') {
                form.resetFields();
                setVisible(false)
                dispatch(state => of({ ...state, loading: true }))
                return dispatch((state) => loadupdateData({ ...state, loading: true }))
            }

        });
    };

    const saveFormRef = formRef => {
        setForRef(formRef)
    };
    return (
        <div style={{ float: 'left' }}>
            <span onClick={showModal}>
                <Icon type="plus" style={{ fontSize: '16px', color: '#8B93A7', padding: '7px' }} />新建账号
            </span>
            <NewAccountFromContent
                wrappedComponentRef={saveFormRef}
                visible={visible}
                onCancel={handleCancel}
                onCreate={handleCreate}
            />
        </div>
    )
}

class AddNewAccountFrom extends React.Component {

    render() {
        const { visible, onCancel, onCreate, form } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const formItemLayout = {
            labelCol: {
                span: 5,
            },
            wrapperCol: {
                span: 12,
            },
        };
        const userPermission = {
            ROOT: "超级管理员",
            TECHSUPPORT: "技术支持",
            APPID: "用于第三方接口调用",
            ADMIN: "管理员",
            TENEMENTADMIN: "管理员",
            TENEMENTENGINEER: "物业工程",
            TENEMENTFINANCE: "物业财务",
            USER: "用户"
        }
        const handleChange = () => {

        }

        return (
            <Modal
                title="新建账号"
                visible={visible}
                onCancel={onCancel}
                onOk={onCreate}
                destroyOnClose
            >
                <Form className='newProjectForm'>
                    <Form.Item {...formItemLayout}
                        label="用户账号"
                    >
                        {getFieldDecorator('title', {
                            rules: [{
                                required: true, message: '用户账号不能为空',
                            },
                            {
                                min: 6, message: '用户账号不能少于6位',
                            }],
                        })(
                            <Input placeholder="请输入用户账号" />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="用户名称"
                    >
                        {getFieldDecorator('id', {
                            rules: [{
                                required: true, message: '用户名称不能为空',
                            }],
                        })(
                            <Input placeholder="请输入用户名称" />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="用户密码"
                    >
                        {getFieldDecorator('password', {
                            rules: [
                                {
                                    required: true,
                                    message: '用户密码不能为空',
                                },
                                {
                                    min: 6, message: '用户密码不能少于6位',
                                },
                            ],
                        })(<Input.Password />)}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="重复密码"
                    >
                        {getFieldDecorator('confirm', {
                            rules: [{
                                required: true, message: '重复密码不能为空',
                            }, {
                                validator: (rule, value, callback) => {
                                    if (value && value !== getFieldValue('password')) {
                                        callback('两次输入不一致！')
                                    }
                                    callback()
                                },
                            }],
                        })(
                            <Input.Password placeholder="请输入重复密码" type='number' />
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="用户权限"
                    >
                        {getFieldDecorator('permission', {
                            initialValue: 'TENEMENTADMIN'
                        })(
                            <Select onChange={handleChange}>
                                {
                                    r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(userPermission))
                                }
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item {...formItemLayout}
                        label="联系方式"
                    >
                        {getFieldDecorator('tel', {
                            rules: [{ required: true, message: '联系方式不能为空' }, {
                                pattern: /^([1][3,4,5,6,7,8,9])\d{9}$/, message: '请输入正确的手机号'
                            }]
                        })(
                            <Input placeholder="请输入联系方式" />
                        )}
                    </Form.Item>
                    {/* <Form.Item {...formItemLayout}
                        label="信息描述"
                    >
                        {getFieldDecorator('description', {})(
                            <Input.TextArea />
                        )}
                    </Form.Item> */}

                </Form>
            </Modal>
        );
    }
}
const NewAccountFromContent = Form.create({ name: 'register' })(AddNewAccountFrom);


export default addNewAccount;