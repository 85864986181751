import React, {useState, useEffect, useContext} from 'react';

import {Table, Tree, Icon, Popconfirm, Switch, Button, Tooltip, message, Tag, Popover} from 'antd';
import * as r from 'ramda';
import {Link} from 'react-router-dom'
import {of} from 'rxjs';
import Api from 'api';
import moment from 'moment';
import {context, loadManagerStates} from '../manager';
import ManagerDelete from '../managerDelete/managerDelete';
import ManagerEdit from '../managerEdit/managerEdit';

const mapIndex = r.addIndex(r.map);
const {TreeNode} = Tree;
const columns = (dispatch) => [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: '建筑名称',
        dataIndex: 'title',
        key: 'title',
        render: (text) => {
            return text.length > 16 ? <Tooltip title={text} overlayStyle={{whiteSpace: 'nowrap'}}> <span style={{
                width: '200px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip> : <span>{text}</span>
        },
        width: '200px'
    }, {
        title: '建筑标识',
        dataIndex: 'tag',
        key: 'tag',
    },
    {
        title: '设备状态',
        dataIndex: 'status',
        key: 'status',
        width: '200px',
        render: (text, record) => <div><span>{text}</span> <a style={{fontSize: '12px'}}
                                                              onClick={() => switchStatus(record, dispatch)}>切换</a>
        </div>,
    },
    {
        title: '连线状态',
        dataIndex: 'isconnect',
        key: 'isconnect',
        render: (text, record) => text ? <Tag color="#87d068">正常</Tag> : <Tag color="#f50">异常</Tag>
    },
    {
        title: '最后通讯时间',
        dataIndex: 'lastupdate',
        key: 'lastupdate',
    },
    {
        title: '设备IP地址',
        dataIndex: 'ip',
        key: 'ip',
        render: (text, record) => <Popover content={record.software}>
            <span>{text}</span>
        </Popover>,
    },
    {
        title: 'information',
        dataIndex: 'information',
        key: 'information',
        className: 'hide'
    },
    {
        title: '操作',
        key: 'operation',
        dataIndex: 'operation',
        render: (text, record) =>
            <div>
                <ManagerEdit record={record}/>
                <ManagerDelete record={record}/>
            </div>
    },
];
const handleDelete = key => {
    const dataSource = [...this.state.dataSource];
    this.setState({dataSource: dataSource.filter(item => item.key !== key)});
};
// rowSelection objects indicates the need for row selection
const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
        console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
        console.log(selected, selectedRows, changeRows);
    },
};
const switchStatus = (record, dispatch) => {
    const {information: {_id, status}} = record;
    Api.post('/collector/status', {
        id: _id,
        projectid: Api.projectId,
        status: status === "ONLINE" ? "DEBUG" : "ONLINE"
    }).then(() => {
            message.success('修改成功')
            dispatch(state => of({...state, loading: true}))
            return dispatch(state => loadManagerStates({...state, loading: true}))
        },
        () => {
            message.error('修改失败')
        })

}

const Manager = () => {
    const {dispatch, state: {dataSource}} = useContext(context)

    return (
        <div className="manager">
            <Table dataSource={dataSource} columns={columns(dispatch)} pagination={{defaultPageSize: 13}}/>
        </div>
    )
}

export default Manager;