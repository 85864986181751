import React from "react";
import * as r from "ramda";
import cookie from 'react-cookies';
import md5 from 'md5';
import Api from "api";
import {Project} from 'src/context/project';
import cs from './cs'
import sm2 from 'sm-crypto';

import {Redirect} from "react-router-dom";

import './login.scss'
import Img_bg from "src/img/login/bg.png"
import Captcha from 'react-captcha-code';
import Img_loginBg from "src/img/login/login/loginBg.png"
import Img_loginBottom from "src/img/login/login/loginBottom.png"
import Img_loginTop from "src/img/login/login/loginTop.png"


import Img_graphics1 from "src/img/login/graphics/graphics1.png"
import Img_graphics2 from "src/img/login/graphics/graphics2.png"
import Img_graphicsC1 from "src/img/login/graphics/graphicsC1.png"
import Img_graphicsC2 from "src/img/login/graphics/graphicsC2.png"
import Img_anime1 from "src/img/login/anime1.png"
import Img_anime2 from "src/img/login/anime2.png"
import Img_anime3 from "src/img/login/anime3.png"
import Img_anime4 from "src/img/login/anime4.png"
import Img_anime5 from "src/img/login/anime5.png"
import Img_anime6 from "src/img/login/anime6.png"
import Img_anime7 from "src/img/login/anime7.png"

import {Button, Form, Input} from 'antd';
import {setPermission, setProjectName, setUid} from "src/context/NavItem";
import {setRegionPermission} from "../../context/NavItem";

const DEFAULT_PROJECT = '5db0236e74a04b0011cd88d3'
const sm2Cipher=sm2.sm2;
const publicKey = "0409db2be43f63522033164b626aba995f3e9233548590ee774bef832cbaf38983bddef48e502d082d8d7680c7c6e86072c7c432391912b3a4eae42b91b33a1443";
const cipherMode = 1



const pattern = /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?\(\)]).*$/;
function defaultLoggedProject(project) {
  const resource = r.propOr(DEFAULT_PROJECT, '0', project);
  return resource === '*' ? DEFAULT_PROJECT : resource;
}

class LoginForm extends React.Component {

    state = {
        isLogin: false,
        captcha:''
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if(this.state.captcha !== values.captchaInput){
                    // message.info('验证码不正确  ');
                    alert('验证码不正确');
                    // new Error('验证码不正确')
                    // message.info('验证码不正确 : '+this.state.captcha+'|'+ values.captchaInput);
                    return;
                }


                Api.post('/auth/login', {
                    user: values.userName,
                    passwd: sm2Cipher.doEncrypt(md5(values.password).toUpperCase(), publicKey, cipherMode) ,

                }).then((data) => {
                    if (data.data.code === 20000001) {
                        this.props.form.setFields({
                            userName: {
                                value: values.userName,
                                errors: [new Error(data.data.message)],
                            },
                        });
                    }
                    if (data.data.code === 90000006) {
                        this.props.form.setFields({
                            password: {
                                value: values.password,
                                errors: [new Error('密码错误')],
                            },
                        });
                    }
                    if (data.data.code === 0) {
                        Api.post('/account/info', { id: data.data.result.uid })
                            .then(({ data: { result: { title: name, character: { id: permission }, resource: { project } } } }) => {
                                const id = defaultLoggedProject(project)
                                Api.projectId = id;
                                Api.projectName = name;
                                cookie.save(
                                    'projectName',
                                    name,
                                    { path: '/', domain }
                                )
                                cookie.save(
                                    'projectId',
                                    id,
                                    { path: '/', domain }
                                )
                                setPermission(permission)
                                setProjectName(name)
                                this.setState({
                                    isLogin: true
                                })
                            })
                        // 增加对是否区域管理员的角色判断
                        Api.get('/api/v3/accountRegion/'+data.data.result.uid )
                            .then(r.propOr([], 'data')).then(data => {
                            // super admin can access multiple projects
                            if(data && data.length > 0){
                                setRegionPermission('REGION');
                                cookie.save('isRegion', true);
                            }
                            return data
                        })
                        if(!pattern.test(values.password)){
                            alert('密码强度弱，请登录后台修改密码。需8位以上，包含数组，大小写字母，特殊字符！');
                        }
                        const domain = window.location.hostname.replace(/^\w+\./, '')
                        cookie.save(
                            'token',
                            data.data.result.token,
                            { path: '/', domain }
                        )
                        cookie.save(
                            'uid',
                            data.data.result.uid,
                            { path: '/', domain }
                        )
                        cookie.save(
                            'isLogin',
                            'sbfui20',
                            { path: '/', domain }
                        )
                        setUid(data.data.result.uid)

                    }


                })
            }
        });
    }
    handleClick = (code) => {
        // 刷新验证码
        // this.current.refresh();
        this.state.captcha=code;
        // console.log(code);
    };
    render() {
        const { getFieldDecorator } = this.props.form;
        if (this.state.isLogin) {
          return <Redirect to='/' />
        }

        return (
            <Form onSubmit={this.handleSubmit} className="loginFrom">
                <p>能源监测系统</p>
                <Form.Item>
                    {getFieldDecorator('userName', {
                        rules: [{ required: true, message: '请输入有户名' }],
                    })(
                        <Input placeholder="用户名" />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: '请输入密码' }],
                    })(
                        <Input type="password" placeholder="密码" />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('captchaInput', {
                        rules: [{ required: true, message: '请输入验证码' }],
                    })(
                        <Input placeholder="请输入验证码" />
                    )}
                </Form.Item>
                <div style={{ marginLeft:20,height: 50, width: 100 }}>
                    <Captcha charNum={6} onChange={this.handleClick} />
                </div>
                <Form.Item>
                    {/* {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                    })(
                        <Checkbox>Remember me</Checkbox>
                    )}
                    <a className="login-form-forgot" href="">Forgot password</a> */}
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        登录
            </Button>
                    {/* Or <a href="">register now!</a> */}
                </Form.Item>
            </Form>
        );
    }
}

const WrappedNormalLoginForm = Form.create({ name: 'LoginForm' })(LoginForm);


class Login extends React.Component {

    constructor(props) {
        super(props);
        // this.canvas = React.createRef();
        this.state = { user: '', password: '' };

    }

    componentDidMount() {
        cs(this)

    }

    getUserInput = (e) => {
        this.setState({
            user: e.target.value
        })
    }


    getPassWdInput = (e) => {
        this.setState({
            password: e.target.value
        })
    }


    render() {



        return (
            <div className="login">
                <div id="large-header" className="large-header" ref={largeHeader => this.largeHeader = largeHeader}>
                    <canvas id="canvas" ref={canvas => this.canvas = canvas}
                    ></canvas>
                </div>

                <img src={Img_bg} alt='' className='bg' />
                <img src={Img_graphics1} alt='' className='graphics1' />
                <img src={Img_graphics2} alt='' className='graphics2' />
                <img src={Img_graphicsC1} alt='' className='graphicsC1' />
                <img src={Img_graphicsC2} alt='' className='graphicsC2' />
                <img src={Img_anime1} alt='' className='anime1 anime' />
                <img src={Img_anime2} alt='' className='anime2 anime' />
                <img src={Img_anime3} alt='' className='anime3 anime' />
                <img src={Img_anime4} alt='' className='anime4 anime' />
                <img src={Img_anime5} alt='' className='anime5 anime' />
                <img src={Img_anime6} alt='' className='anime6 anime' />
                <img src={Img_anime7} alt='' className='anime7 anime' />
                <div className='loginContainer'>
                    <img src={Img_loginBottom} alt='' className='loginBottom' />
                    <img src={Img_loginTop} alt='' className='loginTop' />
                    <div className="loginFrom" style={{ backgroundImage: `url(${Img_loginBg})` }}>
                        <Project.Consumer>
                            {theme => <WrappedNormalLoginForm Project={theme} />}
                        </Project.Consumer>
                    </div>
                </div>
            </div>

        )

    }
}


export default Login


