import React from 'react';
import * as r from 'ramda';
import {from} from 'rxjs';
import './editMeter.scss';
import withStates from 'src/context/withStates';
import Api from 'api';
import ManagerTable from './managerTable/managerTable';
import moment from 'moment';

const mapIndex = r.addIndex(r.map);

const EditMeter = () => (
    <div className="manager">
        <ManagerTable/>
    </div>
)

const managerStates = async ({}) => {
    const {data = []} = await Api.postV3('/sensors/info', {
        project: Api.projectId,
        pagesize: 9999
    });
    // console.log('data');
    // console.log(data);

    return {
        loading: false,
        dataSource: mapIndex((v, i) => ({
            _id:v._id,
            key: i,
            index: i + 1,
            title: v.title,
            collectswtich: v.collectswtich,
            valuewrongflag: v.valuewrongflag,
            oldvalues: v.oldvalues,
            backvalue: v.backvalue,
            realdata: v.realdata,
            lastvalue: v.lastvalue,
            lasttotal: v.lasttotal,
            // lastupdate: moment(v.lastupdate * 1000).format('YYYY-MM-DD HH:mm:ss'),
            lastupdate: v.lastupdate ,
        }))(data),
    }
}

export const loadManagerStates = (state) => {
    return from(managerStates(state)
        .then(res => ({...state, ...res})))
}

const initState = {
    loading: true,
    dataSource: [],
}

export const {context, Component} = withStates(
    EditMeter,
    initState,
    loadManagerStates,
)

export default Component
