import axios from 'axios';
import cookie from 'react-cookies';

let Api = {
    get: '',
    post: '',
    delete: ''
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
        'X-CustomHeader': 'energy'
    },
    withCredentials: true
});

Api.get = (api, data) => instance.get(api, data)

Api.post = (api, data, config) => instance.post(`/api${api}`, data, config)
Api.postV3 = (api, data) => instance.post(`/api/v3${api}`, data)
Api.delete = (api, data) => instance.delete(`/api/v3${api}`, data)
Api.put = (uri, payload) => instance.put(`/api/v3${uri}`, payload)

//only support this project so far1
Api.projectId = cookie.load('projectId')
Api.projectName = cookie.load('projectName')
export default Api;
