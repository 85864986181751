import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import { of } from 'rxjs';
import { context, loadDepartmentState } from '../departmentState';

import Api from 'src/api';

const confirm = Modal.confirm;

const Delete = (props) => {
    const { dispatch } = useContext(context);
    const { id: removeId } = props.record

    const showConfirm = () => {

        confirm({
            title: '您确定要删除该仪表吗！',
            onOk() {
                return (
                    Api.delete(`/project/${Api.projectId}/node/${removeId}`).then(res => {
                        message.info('删除成功')
                        dispatch(state => of({ ...state, loading: true }))
                        dispatch(state => loadDepartmentState({ ...state, loading: true }))
                    }).catch(() => message.info('删除失败')))
            },
            onCancel() {
                dispatch(state => of({ ...state, loading: true }))
                return dispatch(state => loadDepartmentState({ ...state, loading: true }))
            },
        });
    }
    return (
        <span onClick={showConfirm} style={{ color: '#FF0927', cursor: 'pointer' }}>
            删除
    </span>
    );
}


export default Delete;