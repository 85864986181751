import React, { useState, useContext } from 'react';
import { of } from 'rxjs';
import * as r from 'ramda';
import { context, loadNodeStructure } from '../buildingStructure';
import { categoryFormat, deviceTypeFormat } from 'src/components/typeCast/typeCast.js';
import { Table, Icon, Input, Button, message, Modal, Tooltip } from 'antd';
import Api from 'api';
import './buildingStructureModal.scss';
const mapIndexed = r.addIndex(r.map);
const { Search } = Input;
const modalColumns = [
    {
        title: '设备名称',
        dataIndex: 'title',
        key: 'title',
        width: '160px',
        render: (text) => {
            return text.length > 8 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
                width: '130px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip > : <span>{text}</span>
        }
    },
    {
        title: '设备ID',
        dataIndex: 'id',
        key: 'id',
        width: '200px'
    },
    {
        title: '设备类型',
        dataIndex: 'deviceType',
        key: 'deviceType',
        width: '200px'
    },
    {
        title: '通道名称',
        dataIndex: 'channels',
        key: 'channels',
        width: '200px'
    },
    {
        title: '信息',
        dataIndex: 'information',
        key: 'information',
        className: 'hide'
    }
]

const BuildingStructureModal = () => {
    const { state: { node, dataSource, nodeId, devices }, dispatch } = useContext(context)
    const [visible, setVisible] = useState(false);
    const [loding, setLoding] = useState(false)
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    let oData = r.clone(dataSource);

    const onSearch = value => {
        const seartValue = value;
        const re = new RegExp(seartValue);
        const newData = [];
        data.map(
            (v) => {
                if (re.test(v.did) || re.test(v.title) || re.test(v.channels) || re.test(v.devicetype)) {
                    newData.push(v)
                }
            }
        )
        setNewData(newData)
    };

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows)
        setSelectedRowKeys(selectedRowKeys)
    };

    const showModal = async () => {
        setVisible(true)
        setLoding(true)
        setNewData([])
        setSelectedRows([]);
        setSelectedRowKeys([])
        const existingIds = r.pluck('did')(devices)
        const data = await Api.post('/sensor/info', {
            SHOWALL: true,
            pageindex: 1,
            project: Api.projectId
        })
        const detail = r.pathOr([])(['data', 'result', 'detail'])(data)
        const modalArr = mapIndexed(({title, did, subentry, devicetype, channels}, i) => (
            {
                key: i,
                index: `${i + 1}`.padStart(2, '0'),
                title,
                did: did,
                id: did.slice(-18, -6),
                deviceType: deviceTypeFormat(devicetype),
                channels: r.isEmpty(channels) ? '' : channels[0].name,
                subentry: categoryFormat(channels[0].coding)
            }
        ))(r.reject( r.pipe(r.prop('did'), r.flip(r.includes)(existingIds)), detail))
        setData(modalArr);
        setLoding(false)
    };

    const handleOk = async () => {
        if (!r.isEmpty(selectedRows)) {
            Promise.all(
                r.map(k => {
                    Api.postV3(`/project/${Api.projectId}/node/${nodeId}/device`, {
                        title: k.title,
                        key: k.did,
                        parent: nodeId,
                        type: "DEV",
                        project: Api.projectId,
                    })
                })(selectedRows)
            ).then(() => message.info('添加成功')).catch(() => message.info('添加失败'))
        }

    };

    const handleCancel = () => {
        setNewData([])
        setVisible(false)
        dispatch(state => of({ ...state, loading: true }))
        return dispatch(state => loadNodeStructure({ ...state, loading: true, node }))
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <div className='buildingStructureModal'>
            <Button type="primary" onClick={showModal}><Icon type="plus" />增加</Button>
            <Modal
                title="管理智能仪表"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={960}
                style={{ top: 20 }}
                okText='添加'
                cancelText='关闭'
                destroyOnClose={true}
            >
                <div className="buildingStructureModalHead">
                    <Search
                        placeholder="input search text"
                        style={{ width: 200 }}
                        onSearch={onSearch}
                    />
                    <span style={{ float: "right" }}>共{newData.length > 0 ? newData.length : data.length}个</span>
                </div>
                <Table
                    dataSource={newData.length > 0 ? newData : data}
                    columns={modalColumns}
                    scroll={{ y: 650 }}
                    rowSelection={rowSelection}
                    loading={loding}
                    pagination={false}
                />
            </Modal>

        </div>
    )
}


export default BuildingStructureModal;