import React, { useContext } from 'react';
import { Modal, message } from 'antd';
import * as r from 'ramda';
import { context, loadUpdateData } from './project';
import Api from 'api';
import { of } from 'rxjs'

const confirm = Modal.confirm;

const stateText = (text) => {
    const state = {
        DEBUG: "调试状态",
        ONLINE: "正式运行"
    }
    return r.propOr(text)(text)(state)
}

const Model = (props) => {
    const { dispatch } = useContext(context);
    const { model, id } = props.record;
    const showConfirm = () => {
        console.log(props.record)
        confirm({
            title: '您确定要切换该项目状态吗?',
            content: <div>操作后项目将由调试状态切换为正式运行，自动识别仪表功能将停止，且此操作不可逆转，请慎重操作！是否继续操作？</div>,
            onOk() {
                return Api.post('/project/status', {
                    projectid: id,
                    status: "ONLINE"
                }).then(res => {
                    message.info(res.data.message === '' ? '切换成功' : res.data.message)

                    dispatch(state => of({ ...state, loading: true }))
                    return dispatch(state => loadUpdateData({
                        ...state, loading: true,
                    }))
                })
            },
            onCancel() { },
        });
    }

    return (
        <span>
            {stateText(model)}
            {
                model === "DEBUG" ?
                    <i onClick={showConfirm} style={{ fontSize: '12px', color: 'blue' }}>切换</i> : ''
            }
        </span>
    );
}


export default Model;