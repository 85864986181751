import React, { useContext, useState } from 'react';
import * as r from 'ramda';
import { Link } from 'react-router-dom';
import { context } from '../../buildingStateAddNode'
import { Table, Button, Icon } from 'antd';
import Api from 'api';
import Delete from '../../buildingStateAddNodeDelete/buildingStateAddNodeDelete';
import BuildingStateAddNodeModal from '../../buildingStateAddNodeModal/buildingStateAddNodeModal'
const columns = [

    {
        title: '节点名称',
        dataIndex: 'title',
        key: 'title',
        width: '33%'
    },
    {
        title: '子节点数量',
        dataIndex: 'count',
        key: 'count',
        width: '33%'
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        render: (text, record) => {
            return (<div>
                { r.includes(',', record.path) ? <> </> : <BuildingStateAddNodeModal props={record} size={'small'} /> }
                <Delete record={record} />
            </div>)
        },
        width: '33%'
    }, {
        title: '信息',
        dataIndex: 'information',
        key: 'information',
        className: 'hide'
    }
];

const BuildingStateAddNodeTable = () => {
    const { state: { tree, node, loading } } = useContext(context)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = selectedRowKeys => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        // this.setState({ selectedRowKeys });
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <div className="buildingStateAddNodeTable">
            <div className='buildingStateAddNodeTableHead'>
                <div className="buildingStateAddNodeTableHeadLeft">
                    <BuildingStateAddNodeModal props={{ id: '0', title: Api.projectName }} />
                    <Link to='/BuildingState'>
                        <Button>
                            <Icon type="rollback" />
                            返回
                        </Button>
                    </Link>
                    {/* {selectedRowKeys.length > 0 ? <Button type="danger">删除</Button> : ''} */}
                </div>
                <div className="buildingStateAddNodeTableHeadRight">
                    {tree.length}
                </div>
            </div>
            <Table
                dataSource={tree}
                columns={columns}
                pagination={false}
                scroll={{ y: 600 }}
                rowSelection={rowSelection}
                loading={loading}
                defaultExpandedRowKeys={[node]}
            />
        </div>

    )
}

export default BuildingStateAddNodeTable