import React from 'react';
import { Input, Select, Button, } from 'antd';
import IconFont from 'src/font/font';
import './billingServiceHead.scss'
// import Api from 'api'

const Option = Select.Option;
class BillingServiceHead extends React.Component {


    handleChange(value) {
        console.log(`selected ${value}`);
    }

    render() {
        return (
            <div className="indexHead">
                <ul>
                    <li>
                        <span>项目名称</span>
                        <Input placeholder="请输入" className='input' />
                    </li>
                    <li>
                        <span>系统类型</span>
                        <Select defaultValue="全部" onChange={this.handleChange} className='input'>
                            <Option value="全部">全部</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="disabled" disabled>Disabled</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select></li>
                    <li>
                        <span>项目名称</span>
                        <Select defaultValue="全部" onChange={this.handleChange} className='input'>
                            <Option value="全部">全部</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="disabled" disabled>Disabled</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                    </li>
                    <li><Button type="primary">查询</Button></li>
                </ul>
                <Button className='indexHeadRight'>
                    <IconFont type='icon-download' />
                </Button>
            </div>
        )
    }

};


export default BillingServiceHead;