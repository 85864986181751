import React, { useState, useContext } from 'react';
import { context, loadTreeViewData } from '../buildingStateAddNode';
import { Icon, Input, Button, Modal, Tree, Spin, message } from 'antd';
import * as r from 'ramda';
import Api from 'api';
import { of } from 'rxjs';
import './buildingStateAddNodeModal.scss';
import {ROOT_NODE} from "src/context/treeView";
const { TreeNode } = Tree;



const BuildingStateAddNodeModal = (props) => {
    const { state: { tree, node }, dispatch } = useContext(context);
    const { size = 'default' } = props;
    const [visible, setVisible] = useState(false);
    const [selectNodeModal, setSelectNodeModal] = useState(false);
    const [are, setAre] = useState('');
    const [person, setPerson] = useState('');
    const [inputTitle, setInputTitle] = useState('');
    const [parent, setParent] = useState({ tit: '', id: '' });
    const showModal = async () => {
        const { id: propsId = '0', title: propsTit = '' } = props.props;
        setVisible(true)
        setParent({ tit: propsTit, id: propsId })
    };
    const handleOk = async () => {
        try {
            const {data: addNode} = await Api.postV3(`/project/${Api.projectId}/node/${props.props.id}/subordinate`, {
                title: inputTitle,
                formula: {
                    formula: "",
                    variables: {AREA: are, PERSON: person}
                },
                parent: node === ROOT_NODE ? '' : node,
                type: "BYBUILDING",
                project: Api.projectId
            })

            if (parent.id === "0" || parent.id === "") {
                await Promise.all([Api.postV3(`/project/${Api.projectId}/node/${addNode.id}/subordinate`, {
                    title: '水',
                    parent: addNode.id,
                    type: "BYBUILDING",
                    project: Api.projectId,
                    formula: {
                        formula: "",
                        variables: {AREA: "", PERSON: ""}
                    },
                }), Api.postV3(`/project/${Api.projectId}/node/${addNode.id}/subordinate`, {
                    title: '电',
                    parent: addNode.id,
                    type: "BYBUILDING",
                    project: Api.projectId,
                    formula: {
                        formula: "",
                        variables: {AREA: "", PERSON: ""}
                    },
                })])
            }

            message.info('添加成功')
            setVisible(false)
            dispatch(state => of({...state, loading: true}))
            return dispatch(state => loadTreeViewData({...state, loading: true}))
        } catch (e) {
            message.error('添加失败')
        }
    };
    const handleCancel = () => {
        setVisible(false)
    };

    const showNodeModal = async () => {
        setSelectNodeModal(true)
    };
    const nodeModalOk = () => {
        setSelectNodeModal(false);
        // dispatch(state => of({ ...state, loading: true }))
        // return dispatch(state => loadTreeViewData({ ...state, loading: true, node }))
    };
    const nodeModalCancel = () => {
        setSelectNodeModal(false)
    };
    const renderChildren = r.map(({ key, title, id, children }) =>
        (<TreeNode title={title}
            id={id}
            key={key}>{children ?
                renderChildren(children) :
                ''}</TreeNode>))

    const editAreaHeadCount = r.includes(parent.tit)(['水', '电'])
    return (
        <div className='buildingStateAddNodeModal'>
            <Button type="primary" size={size} onClick={showModal}><Icon type="plus" />增加</Button>
            <Modal
                title="添加节点"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <ul className='buildingStateAddNodeModalUl'>
                    <li onClick={showNodeModal}><Input addonBefore="上级节点" value={parent.tit} disabled /></li>
                    <li><Input addonBefore="节点名称" onChange={e => setInputTitle(e.target.value)} /></li>
                    {!editAreaHeadCount ?
                      <li><Input addonBefore="节点面积" type='number' defaultValue={0} onChange={e => setAre(e.target.value)} /></li>
                      : <> </>}
                    {!editAreaHeadCount ?
                      <li><Input addonBefore="节点人数" type='number' defaultValue={0} onChange={e => setPerson(e.target.value)} /></li>
                      : <> </>}
                </ul>
            </Modal>
            <Modal
                title="选择上级节点"
                visible={selectNodeModal}
                onOk={nodeModalOk}
                onCancel={nodeModalCancel}
                width={260}
                className='buildingStateAddNodeModalSeletNode'
            >
                <div style={{ height: '200px', overflow: 'scroll' }}>
                    {
                        r.isEmpty(tree) ?
                            <Spin />
                            :
                            <Tree
                                showLine
                                defaultExpandedKeys={['0']}
                                onSelect={(selectedKeys, e) => {
                                    setParent({ tit: e.node.props.title, id: e.node.props.eventKey })
                                }}
                            >
                                <TreeNode title={tree[0].title} key={tree[0].key}>
                                    {renderChildren(tree[0].children)}
                                </TreeNode>
                            </Tree>
                    }
                </div>

            </Modal>

        </div>
    )
}


export default BuildingStateAddNodeModal;