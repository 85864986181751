import React from 'react';
import * as r from 'ramda';
import {from} from 'rxjs';
import './buildingState.scss';
import withStates from 'src/context/withStates';
import Api from 'api';
import BuildingStateTree from './buildingStateTree/buildingStateTree';
import BuildingStateRight from './buildingStateRight/buildingStateRight';
import {extractDevices, extractNodes, searchFilter} from "src/context/society";
import {formatTree} from "src/context/treeView";

const ROOT_NODE = '0';

const BuildingState = () => (
  <div className="buildingState">
    <BuildingStateTree />
    <BuildingStateRight />
  </div>
)

const buildStates = async ({ node = ROOT_NODE, search = '', selectTableType }) => {
  const { data } = await Api.get(`/api/v3/project/${Api.projectId}/building`);
  const dataSource = selectTableType === 'device' ?
    extractDevices(data, node) :
    extractNodes(r.map(r.pickAll(['id', 'formula']))(data), ROOT_NODE)
  return {
    loading: false,
    dataSource: searchFilter(dataSource)([search]),
    devices: extractDevices(data, ROOT_NODE),
    searchIsShow: false,
    node,
    tree: [
      {
        title: Api.projectName,
        key: ROOT_NODE,
        children: formatTree(ROOT_NODE)(data),
      },
    ]
  }
}

export const loadBuildingState = (state) => {
  return from(buildStates(state)
    .then(res => ({ ...state, ...res })))
}

const initState = {
  loading: true,
  node: ROOT_NODE,
  tree: [],
  dataSource: [],
  selectTableType: 'device'
}

export const { context, Component } = withStates(
  BuildingState,
  initState,
  loadBuildingState,
)

export default Component
