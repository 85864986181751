import React, { useContext, useState } from 'react';
import { context, loadShopsState } from '../../shops'
import { Table, Button, Icon, message, Modal, Tooltip } from 'antd';
import { of } from 'rxjs';
import Api from 'api';
import { Link } from 'react-router-dom'
// import Delete from '../../buildingStateDelete/buildingStateDelete';
// import ByitemModal from '../../buildingStateModal/buildingStateModal';
import IconFont from 'src/font/font';
import EditableCell, { EditableFormRow } from 'src/components/table/tableEdit/editbleCell';
import { handleAreaChange, handleHeadCountChange, handleTitleChange } from 'src/context/formula';
import { ROOT_NODE } from "src/context/treeView";
import * as r from "ramda";

const components = {
    body: {
        row: EditableFormRow,
        cell: EditableCell,
    },
};

const { confirm } = Modal;
const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: '90px',
        fixed: 'left',
    },
    {
        title: '商铺名称',
        dataIndex: 'title',
        key: 'title',
        width: '170px',
        fixed: 'left',
        render: (text) => {
            return text.length > 16 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
                width: '170px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip > : <span>{text}</span>
        }
    }, {
        title: '商户账号',
        dataIndex: 'account',
        key: 'account',
        width: '170px',
        fixed: 'left',
        render: (text) => {
            return text.length > 16 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
                width: '170px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip > : <span>{text}</span>
        }
    }, {
        title: '商户名称',
        dataIndex: 'usertitle',
        key: 'usertitle',
        width: '170px',
        render: (text) => {
            return text.length > 16 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
                width: '170px', overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap', display: ' inline-block'
            }}>{text}</span></Tooltip > : <span>{text}</span>
        }
    },
    {
        title: '智能仪表',
        dataIndex: 'sensors',
        key: 'sensors',
        render: (text) => {
            return <ul>
                {text.map((v, k) => <li key={k}>
                    <IconFont type='icon-2' className={v.openOrclose ? 'colorG' : 'colorR'} />
                    <span>「{v.type}」</span>
                    <span> {v.title} </span>
                    {/* <span> {v.id} </span> */}
                </li>)}
            </ul>
        },
        width: '240px'
    },
    {
        title: '手机',
        dataIndex: 'mobile',
        key: 'mobile',
        width: '200px'
    },
    {
        title: '余额',
        dataIndex: 'cash',
        key: 'cash',
        width: '120px'
    },
    {
        title: '报警值	',
        dataIndex: 'alerthreshold',
        key: 'alerthreshold',
        width: '120px'
    }, {
        title: '次数	',
        dataIndex: 'remindercount',
        key: 'remindercount',
        width: '120px'
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        render: (text, record) => {
            return <div>
                {/* <Delete record={record.information} /> */}
                1
                2
                3
                4
                5
            </div>
        }
    }, {
        title: '信息',
        dataIndex: 'information',
        key: 'information',
        className: 'hide'
    }
];

const ShopsRightBottom = () => {
    const { state: { dataSource, loading, apiStatus }, dispatch } = useContext(context)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows)
        setSelectedRowKeys(selectedRowKeys)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const allDelete = () => {
        confirm({
            title: '您确定要删除该仪表吗！',
            onOk() {
                return (
                    selectedRows.forEach((k, i) => {
                        Api.delete(`/project/${Api.projectId}/node/${r.path(['information', 'id'])(k)}`).then(() => {
                            message.info('删除成功')
                            setSelectedRows([])
                            setSelectedRowKeys([])
                            dispatch(state => of({ ...state, loading: true }))
                            return dispatch(state => loadShopsState({ ...state, loading: true }))
                        }).catch(e => message.info(`删除失败`, e.toString))
                    })
                )
            },
            onCancel() { },
        });


    }
    const haveRent = () => {
        dispatch(state => of({ ...state, loading: true, apiStatus: 0 }))
        return dispatch(state => loadShopsState({ ...state, loading: true, apiStatus: 0 }))
    }
    const forRent = () => {
        dispatch(state => of({ ...state, loading: true, apiStatus: 1 }))
        return dispatch(state => loadShopsState({ ...state, loading: true, apiStatus: 1 }))
    }

    return (
        <div className="shopsRightBottom">
            <div className='shopsRightBottomHead'>
                <div className="shopsRightBottomHeadLeft">
                    <Link to='/NewShops'>添加新商户</Link>
                    <Button onClick={haveRent}>已租</Button>
                    <Button onClick={forRent}>待租</Button>
                    {selectedRowKeys.length > 0 ? <Button type="danger" onClick={allDelete}><Icon type="delete" />删除</Button> : ''}
                </div>
                <div className="shopsRightBottomHeadRight">
                    {dataSource.length} 个结果
                </div>
            </div>
            <Table
                components={components}
                dataSource={dataSource}
                columns={columns}
                // pagination={false}
                scroll={{ y: 600 }}
                loading={loading}
                childrenColumnName={''}
                scroll={{ x: 1490 }}
            />
        </div>

    )
}

export default ShopsRightBottom