import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import './App.css';

import Login from 'src/modules/login/login';

import AppRouter from './router';


class App extends React.Component {

  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <AppRouter />
          <Route path="/Login" component={Login} />
        </div>
      </BrowserRouter>
    )
  }

};


export default App;
