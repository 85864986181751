import React, { useState } from 'react';
import * as r from 'ramda';
import { deviceTypeFormat } from 'src/components/typeCast/typeCast.js';
import { Table, Icon, Input, Button, Modal } from 'antd';
import Api from 'api';
import './newShops.scss';
const mapIndexed = r.addIndex(r.map);
const { Search } = Input;
const modalColumns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: '90px'
    },
    {
        title: '设备名称',
        dataIndex: 'title',
        key: 'title',
        width: '300px'
    },
    {
        title: '设备ID',
        dataIndex: 'id',
        key: 'id',
        width: '210px'
    },
    {
        title: '设备类型',
        dataIndex: 'deviceType',
        key: 'deviceType',
        width: '230px'
    },
    {
        title: '通道名称',
        dataIndex: 'channelname',
        key: 'channelname',
        width: '210px'
    },
    {
        title: 'allData',
        dataIndex: 'allData',
        key: 'allData',
        className: 'hide'
    }
]

const ByitemModal = (props) => {
    const { getFixpriceArr, defaultId } = props;
    const [visible, setVisible] = useState(false);
    const [loding, setLoding] = useState(false)
    const [data, setData] = useState([]);
    const [metadata, setMetadata] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectData, setSelectData] = useState([])
    const onSearch = (value = '') => {
        const seartValue = value;

        const newData = r.innerJoin(
            (x, y) => {
                const re = new RegExp(y);
                return re.test(x.title) || re.test(x.channelname) || re.test(x.deviceType) || re.test(x.id);
            }
        )(metadata)([seartValue])
        setData(newData)
    };

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        setSelectedRows(selectedRows)
        setSelectedRowKeys(selectedRowKeys)
    };

    const showModal = async () => {
        setVisible(true)
        setLoding(true)
        const { data: { result } } = await Api.post('/sensorchannel/info', {
            project: Api.projectId,
        })
        const modalArr = mapIndexed((item, i) => (
            {
                key: i,
                index: i + 1 < 10 ? "0" + (i + 1) : (i + 1),
                title: item.title,
                id: item.addrid,
                deviceType: deviceTypeFormat(item.devicetype),
                channelname: item.channelname,
                allData: item
            }
        ))(result);

        const selectedData = r.filter(r.where({ allData: r.where({ selected: r.equals(true) }) }))(modalArr)

        setSelectedRowKeys(r.map(v => (v.key))(selectedData))
        setSelectData(selectedData)
        setMetadata(modalArr)
        setData(modalArr)
        setLoding(false)
    };

    const handleOk = () => {
        const addId = r.map(({ allData: { id } }) => (id))(r.difference(selectedRows)(selectData));
        const removeId = r.map(({ allData: { id } }) => (id))(r.difference(selectData)(selectedRows));
        getFixpriceArr({ addId, removeId })
        setVisible(false)

    };

    const handleCancel = () => {

        setVisible(false)
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <div className='byitemModal'>
            <Button type="primary" onClick={showModal}><Icon type="plus" />管理</Button>
            <Modal
                title="管理智能仪表"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={960}
                style={{ top: 20 }}
                okText='保存'
                cancelText='关闭'
            >
                <div className="byitemModalHead">
                    <Search
                        placeholder="input search text"
                        onSearch={onSearch}
                        style={{ width: 200 }}
                    />
                    <span style={{ float: "right" }}>共{data.length}个</span>
                </div>
                <Table
                    dataSource={data}
                    columns={modalColumns}
                    scroll={{ y: 650 }}
                    rowSelection={rowSelection}
                    loading={loding}
                    pagination={false}
                />
            </Modal>

        </div>
    )
}


export default ByitemModal;