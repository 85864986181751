import React, { useContext } from 'react';
import { of } from 'rxjs';
import { Input,Button,Upload } from 'antd';
import { context, loadNodeUsage } from '../../byitem'
import {message, Modal} from "antd/lib/index";
import {loadNodeStructure} from "../../../buildingStructure/buildingStructure";
import * as r from "ramda";
import Api from 'api';
const { Search } = Input;

const { confirm } = Modal;


const ByitemRightTop = () => {
    const { dispatch } = useContext(context);
    const onSearch = (value) => {
        dispatch(state => of({ ...state, loading: true }))
        return dispatch(state => loadNodeUsage({
            ...state, loading: true,
            search: value
        }))
    }

    const uploadProps = {
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        showUploadList: false,
        customRequest: (info, a, b) => {
            let fileList = info.file;
            let formdata = new FormData()
            formdata.append('project', Api.projectId)
            // formdata.append('projectid', Api.projectId)
            // formdata.append('projectId', Api.projectId)
            formdata.append('buildingid', '123')
            formdata.append('sensors', fileList)

            Api.post('/import/byItem',
            // Api.postV3('/import/byItem',
                formdata
            ).then(({ data }) => {
                console.log(data)
                if (data.code === 0) {
                    message.success(`文件上传成功.`);
                } else {
                    message.error(`文件上传失败.`);
                    message.error(data.message);
                }
            })
        },
    }

    ///api/v3/project/:project/deviceAutoInit
    const deviceAutoInit = () => {
        confirm({
            title: '您确定要删除现有的结构，使用采集器上传类型重新分配吗？',
            onOk() {
                return (
                        Api.postV3(`/project/${Api.projectId}/deviceAutoInit`,{project:Api.projectId}).then(() => {
                            message.info('删除成功');
                            dispatch(state => of({ ...state, loading: true }));
                            return dispatch(state => loadNodeStructure({ ...state, loading: true }))
                        }).catch(e => message.info(`删除失败`))

                )
            },
            onCancel() { },
        });
    }
    return (<div className="byitemRightTop">
        <div className="byitemRightTopContainer">
            <Search
                placeholder="input search text"
                onSearch={onSearch}
                style={{ width: 200 }}
            // onChange={onSearch}
            />
            <Button type="primary" shape="round" onClick={deviceAutoInit}>使用默认分类</Button>
            <span style={{width:'10px'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <Upload style={{left:'30px'}} {...uploadProps}>
                <Button>导入</Button>
            </Upload>
        </div>

    </div>)
}

export default ByitemRightTop
