import React, { useContext } from 'react';
import { of } from 'rxjs';
import { Tree, Spin } from 'antd';
import * as r from 'ramda';
import { context, loadNodeUsage } from '../byitem'

const { TreeNode } = Tree;

const ByitemTree = () => {
    const { state: { tree, node, }, dispatch } = useContext(context)
    const onSelect = (node) => (event) => (nodeId) => {
        return (r.isEmpty(event) || r.head(event) === node) ? (state) => of(state) :
            (state) => loadNodeUsage({ ...state, loading: true, node: r.head(event), nodeId })
    }

    const toChild = r.map(
        ({ title, key, children, id }) => (<TreeNode title={title} key={key} id={id}>{r.isEmpty(children) ? '' : toChild(children)}</TreeNode>)
    );
    return (
        <div className='byitemLeft'>
            <div className='byitemLeftTreeHead'>
                <span>分项属性</span>
            </div>
            {
                r.isEmpty(tree) ?
                    <Spin />
                    :
                    <Tree
                        showLine
                        defaultExpandedKeys={['0']}
                        defaultSelectedKeys={['0']}
                        onSelect={(selectedKeys, e) => {
                            dispatch(state => of(r.assoc('loading', true)(state)))
                            dispatch(onSelect(node)(selectedKeys)(e.node.props.id))
                        }}
                    >
                        <TreeNode title={tree[0].title} key={tree[0].key}>
                            {toChild(tree[0].children)}
                        </TreeNode>
                    </Tree>
            }
        </div>
    )
}


export default ByitemTree
