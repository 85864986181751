
import React from 'react';
import BuildingStateRightTop from './buildingStateRightTop/buildingStateRightTop';
import ByitemRightBottom from './buildingStateRightBottom/buildingStateRightBottom';
const BuildingStateRight = () => (
    <div className='buildingStateRight'>
        <BuildingStateRightTop />
        <ByitemRightBottom />
    </div>
)
export default BuildingStateRight
