import * as r from "ramda";
import {of} from "rxjs";
import {message} from "antd";
import Api from 'api';

export const variablesOf = content => {
  const defaultVar = {AREA: 0, PERSON: 0}
  try {
    return { ...defaultVar, ...r.propOr(defaultVar)('variables')(JSON.parse(content))}
  } catch (e) {
    return defaultVar
  }
}

export const setVariables = formula => ({AREA, PERSON}) => {
  const defaultVariables = {formula: {}, variables: {AREA: 0, PERSON: 0}}
  try {
    return r.reduce(r.mergeDeepRight)(defaultVariables)([JSON.parse(formula), {variables: r.reject(r.isNil)({AREA, PERSON})}])
  } catch (e) {
    return defaultVariables
  }
}


export const handleAreaChange = (dispatch, loading) => row => {
  const { information: {id, formula}, area } = row;
  const oldValue = variablesOf(formula)
  if(!r.is(Number, Number(area))) {
    message.error('只能是数字')
    return;
  }
  if(oldValue.AREA === area) {
    return;
  }
  dispatch(r.pipe(r.assoc('loading', true), of))
  Api.put(`/project/${Api.projectId}/node/${id}`, {
    formula: setVariables(formula)({AREA: area})
  }).then(
    () => {
      message.success('修改成功')
      return dispatch(loading)
    },
    () => {
      message.error('修改失败')
      dispatch(r.pipe(r.assoc('loading', false), of))
    }
  )

}
export const handleHeadCountChange = (dispatch, loading) => row => {
  const { information: {id, formula}, person } = row;
  const oldValue = variablesOf(formula)
  if(!r.is(Number, Number(person))) {
    message.error('只能是数字')
    return;
  }
  if(oldValue.PERSON === person) {
    return;
  }
  dispatch(r.pipe(r.assoc('loading', true), of))
  Api.put(`/project/${Api.projectId}/node/${id}`, {
    formula: setVariables(formula)({PERSON: person})
  }).then(
    () => {
      message.success('修改成功')
      return dispatch(loading)
    },
    () => {
      message.error('修改失败')
      dispatch(r.pipe(r.assoc('loading', false), of))
    }
  )
}

export const handleTitleChange = (dispatch, loadFn) => row => {
  const { information: {id, title: oldTitle}, title } = row;
  if(oldTitle === title) {
    return;
  }
  dispatch(r.pipe(r.assoc('loading', true), of))
  Api.put(`/project/${Api.projectId}/node/${id}`, {
    title
  }).then(
    () => {
      message.success('修改成功')
      return dispatch(loadFn)
    },
    () => {
      message.error('修改失败')
      dispatch(r.pipe(r.assoc('loading', false), of))
    }
  )
}