import React, {useContext} from 'react';
import * as r from 'ramda';
import moment from 'moment'
import {Form, message, Modal} from 'antd';
import {context, loadUpdateData} from './reportAll';
import {of} from 'rxjs';
import Api from 'src/api'
import UpdateReportForm from "./newReport/updateReportAll";//这个是2020年的，暂时不用
// import UpdateReportForm from "./newReport/updateReportAll2022";// 这个是2022年的，
import {joinAllModules} from "src/context/project";

const ManagementReportAll = (props) => {
  const {state, dispatch} = useContext(context)
  const {record} = props
  console.log('Management state', state);
  console.log('Management record', record);
  const showModal = () => {
    dispatch(s => of({...r.omit(['project'], s), visible: record.id, project: record}))
  }

  const handleUpdate = () => {

    console.log('state.project---');
    console.log(state.project);
    dispatch(state => of({...r.omit(['project'], state), visible: false, loading: true}))
    const {id, funcLocate,funcDomain} = state.project;
    // return;
    Api.put(`/report/${id}`, {
      ...state.project,
      // type: state.project.category,
      // effectiveDays: Math.floor(moment.duration(moment(stopTime).diff(moment(createTime))).asDays())
    })
      .then(() => {
        dispatch(state => of({...r.omit(['project'], state), visible: false, loading: true}))
        return dispatch(state => loadUpdateData({
          ...state, loading: true,
        }))
      }).catch(() => message.info('请重试'))

  }

  const handleCancel = () => {
    dispatch(s => of({...r.omit(['project'], s), visible: false}))
  }

  const onValuesChange = (props, changedValues, allValues) => {
    console.log('onValuesChange', props, changedValues, allValues);
    //因为输入框会不停触发，这里返回
    // if(changedValues.building || changedValues.longitude2  || changedValues.latitude2  || changedValues.parent  || changedValues.path ){
    //     return;
    // }
    //   console.log('allValues');
    //   console.log(allValues);

      if(allValues.load != '1'){
          console.log('not to load all params ,for the load cmd is  ---- ' + changedValues.load);
          return;
      }

    //  收集建筑功能分区
    //   const  testFunc = /^(funcLocate_)|(funcDomain_)\d$/;
      const  testFunc = /^(funcLocate_)\d$/;
      var funcArr=[];
      var funcLocate={};
      var funcDomain={};
      for(var key in allValues){
          if(testFunc.test(key)){
              //检查funcLocate和funcDomain是否存在空的情况
              var index = key.lastIndexOf('_');
              var num = key.substr(index+1,key.length);
              if(num && allValues[key] && allValues['funcDomain_'+num]){
                  funcLocate[key]=allValues[key];
                  funcDomain['funcDomain_'+num]=allValues['funcDomain_'+num];
                // funcArr.push({[key]:allValues[key], ['funcDomain_'+num]:allValues['funcDomain_'+num]});
              }
          }
      }
      // for(var key in allValues){
      //     if(testFunc.test(key)){
      //         //检查funcLocate和funcDomain是否存在空的情况
      //         var index = key.lastIndexOf('_');
      //         var num = key.substr(index+1,key.length);
      //         informWay.push({funcLocate:allValues[key], funcDomain:allValues['funcDomain_'+num]});
      //
      //     }
      // }
      console.log('informWay2 ------');
      console.log(funcLocate);
      console.log(funcDomain);
      // return;
    const { floors,load} = allValues;

    console.log('merge project ---');
    console.log(record);
    console.log(r.mergeDeepRight(record, {floors,load,funcLocate,funcDomain}));
    record.funcLocate=funcLocate;
    record.funcDomain=funcDomain;
    dispatch(s => of({
      ...s,
      project: r.mergeDeepRight(record, {floors,load,funcLocate,funcDomain})
    }))
  }

  const UpdateProject = Form.create({name: 'updateReport', onValuesChange})(UpdateReportForm)

  return (
    <div className='managementReport' style={{float: 'left',
        marginRight: '10px'
    }}>
            <span onClick={showModal} style={{color: '#3E8FF8'}}>
                管理
            </span>

      <Modal
        title="编辑报告"
        visible={state.visible === record.id}
        onOk={handleUpdate}
        onCancel={handleCancel}
        destroyOnClose={true}
        className='edit-project'
      >
        <UpdateProject project={state.project || record}/>


      </Modal>
    </div>
  );
}


export default ManagementReportAll;